import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { ButtonBase, CircularProgress, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ValidIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import CardIcon from '@mui/icons-material/CreditCard';
import TransferIcon from '@mui/icons-material/AccountBalance';
import CodIcon from '@mui/icons-material/LocalShipping';
import GoogleAutocomplete from '../components/GoogleAutocomplete';
import { Screen, Sections, Input, Checkbox, Select } from '../components';
import {
    __,
    request,
    toNumber,
    replaceDia,
    isEmptyString,
    getImageUrl,
    formatAmount,
    getPrice,
    getText,
    getProductPrice,
    toFixed,
    getCheckoutErrors,
    formatPhone,
    isValidEmail,
    getUrlPath,
} from '../functions';
import { fetchEshop, fetchSettings, fetchCart, cleanCart, changeUserData, fetchFavorites, fetchCompares, fetchLists } from '../actions';
import { COOKIE_CART_NAME, COOKIE_SETTINGS, COOKIE_TOKEN_NAME } from '../config';
import '../assets/styles/checkout.css';

/**
 * Pokladna.
 */
class CheckoutScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Pokladňa');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        loading: false,
        data: {
            payment_id: 0,
            delivery_id: 0,
            company: false,
            company_name: '',
            ico: '',
            dic: '',
            ic_dph: '',
            name: '',
            address: '',
            address_additional: '',
            city: '',
            zip: '',
            country: 'sk',
            phone_prefix: 'sk',
            phone: '',
            email: '',
            comment: '',
            delivery: false,
            delivery_company_name: '',
            delivery_name: '',
            delivery_address: '',
            delivery_address_additional: '',
            delivery_city: '',
            delivery_zip: '',
            delivery_country: 'sk',
            delivery_phone_prefix: 'sk',
            delivery_phone: '',
            coupon: '',
            pickup_point: '',
            vies_date: '',
            vies_token: '',
        },
        coupon_text: '',
        coupon_loading: false,
        ico_loading: false,
        dic_loading: false,
        phone_loading: false,
        phone_valid: false,
        delivery_phone_loading: false,
        delivery_phone_valid: false,
        email_valid: false,
        payments: {},
        deliveries: {},
        errors: {},
        showLogin: false,
        email: '',
        password: '',
        rememberMe: false,
        loginLoading: false,
        showCreatePassword: false,
        createPassword: '',
        showAddress: false,
        address: '',
        deliveryAddress: '',
        showDeliveryAddress: false,
        showCoupon: false,
        discount: {},
        contact: false,
        onBlacklist: false,
        tax: 20,
        showDownButton: true,
        lightbox: {
            popup: false,
        },
    };

    /**
     * Checkout button ref.
     *
     * @type {null}
     */
    checkoutButtonRef = null;

    /**
     * Celkova suma objednavky.
     *
     * @type {number}
     */
    totalAmount = 0;

    /**
     * Suma DPH.
     *
     * @type {number}
     */
    totalVat = 0;

    /**
     * Suma dodania.
     *
     * @type {number}
     */
    totalDelivery = 0;

    /**
     * Konstruktor.
     *
     * @param {Object} props
     */
    constructor(props) {
        super(props);

        this.checkoutButtonRef = React.createRef();
    }

    /**
     * Komponenta bola pripojena.
     */
    async componentDidMount() {
        await super.componentDidMount();

        const { eshop, cart } = this.props;

        if (_.isEmpty(cart.items)) {
            this.redirect('/obchod');
            return;
        }

        let settings = _.has(eshop.countries, eshop.country) ? {
            country: eshop.country,
            phone_prefix: eshop.country,
            delivery_country: eshop.country,
            delivery_phone_prefix: eshop.country,
        } : {};
        let additional = {};

        if (!_.isEmpty(eshop.user)) {
            settings = {
                ...settings,
                ...eshop.user.settings,
                coupon: '',
            };

            if (settings.phone !== '') {
                settings = { ...settings, phone: formatPhone(settings.phone) };
            }

            if (settings.delivery_phone !== '') {
                settings = { ...settings, delivery_phone: formatPhone(settings.delivery_phone) };
            }

            if (settings.address !== '') {
                // Mame vyplneny adresu
                additional = { ...additional, address: `${settings.address} ${settings.city} ${settings.zip}, ${eshop.countries[settings.country]}` };
            }

            if (settings.delivery_address !== '') {
                // Mame vyplneny adresu
                additional = { ...additional, deliveryAddress: `${settings.delivery_address} ${settings.delivery_city} ${settings.delivery_zip}, ${eshop.countries[settings.delivery_country]}` };
            }

            if (settings.email !== '') {
                // Je zadany email, pozrieme blacklist
                setTimeout(() => this.loadBlacklist(settings.email), 500);
            }
        }

        this.setPaymentAndDelivery({ ...this.state.data, ...settings }, true, additional);

        // Pridame listener
        window.addEventListener('scroll', this.handleScroll);
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        // Odstranime listener
        window.removeEventListener('scroll', this.handleScroll);
    }

    /**
     * Handlujeme scrolovanie.
     *
     * @param {Object} event
     */
    handleScroll = event => {
        const { showDownButton } = this.state;

        const limitTop = window.scrollY + window.innerHeight - 208;

        if (showDownButton && limitTop >= this.checkoutButtonRef.current.offsetTop) {
            this.setState({ showDownButton: false });
        } else if (!showDownButton && limitTop < this.checkoutButtonRef.current.offsetTop) {
            this.setState({ showDownButton: true });
        }
    }

    /**
     * Nastavime platbu a dodanie.
     *
     * @param {Object} data
     * @param {boolean} init
     * @param {Object} additional
     */
    setPaymentAndDelivery(data, init = false, additional = {}) {
        const { phone_valid, delivery_phone_valid, email_valid } = this.state;
        const { eshop, cart } = this.props;

        // Vytiahneme zoznam platieb
        const payments = this.getPayments(data, _.has(additional, 'onBlacklist') ? additional.onBlacklist : false);

        // Vytiahneme zoznam dodani
        const deliveries = this.getDeliveries(data);

        const payment_id = toNumber(_.keys(payments)[0]);
        let delivery_id = toNumber(_.keys(deliveries)[0]);

        if (_.values(cart.items).length === 1) {
            _.each(cart.items, (item) => {
                const name = replaceDia(getText(eshop.products[item.id].name)).toLowerCase();

                if (name.indexOf('kupon') !== -1 || name.indexOf('coupon') !== -1) {
                    // Pri kupone nie je dodanie
                    delivery_id = 0;
                }
            });
        }

        if (_.has(deliveries, delivery_id) && deliveries[delivery_id].setting !== 'packeta') {
            // Rusime pickup point
            data = { ...data, pickup_point: '' };
        }

        this.setState({
            data: { ...data, payment_id, delivery_id },
            tax: this.getTax(data),
            payments,
            deliveries,
            phone_valid: !_.isEmpty(data.phone) && init ? true : phone_valid,
            delivery_phone_valid: !_.isEmpty(data.delivery_phone) && init ? true : delivery_phone_valid,
            email_valid: !_.isEmpty(data.email) && init ? true : email_valid,
            dic_loading: false,
            ...additional
        });
    }

    /**
     * Vratime DPH sadzbu.
     *
     * @param {Object} data
     *
     * @return {number}
     */
    getTax(data) {
        const { eshop } = this.props;

        if (data.dic === '') {
            // Neplatca
            if (_.has(eshop.vat_rates, data.country)) {
                // EU, pouzijeme sadzbu
                return eshop.vat_rates[data.country];
            }

            // Mimo EU bez DPH
            return 0;
        }

        // Platca DPH
        if (!_.has(eshop.vat_rates, data.country)) {
            // Mimo EU
            return 0;
        }

        if (data.country === eshop.settings.country) {
            // CZ
            return eshop.settings.tax;
        }

        if (data.vies_token !== '') {
            // Overene cez VIES, bez DPH
            return 0;
        }

        // Neoverene VIES, sadzba krajiny
        return eshop.vat_rates[data.country];
    }

    /**
     * Vratime zoznam platieb.
     *
     * @param {Object} data
     * @param {boolean} onBlacklist
     *
     * @return {Object}
     */
    getPayments(data, onBlacklist = false) {
        const { eshop, cart } = this.props;

        return _.reduce(super.getPayments(data.delivery ? data.delivery_country : data.country), (result, item, id) => {
            if (item.setting === 'cod' && onBlacklist) {
                // Je na blackliste, nezobrazujeme dobierku
                return result;
            }

            if (item.setting === 'cod' && !eshop.user.potisk) {
                // Ked je dobierka, skontrolujeme potisk
                let valid = true;

                if (!valid) {
                    return result;
                }
            }

            return { ...result, [id]: item };
        }, {});
    }

    /**
     * Vratime zoznam dodani.
     *
     * @param {Object} data
     *
     * @return {Object}
     */
    getDeliveries(data) {
        return this.getDelivers(data.delivery ? data.delivery_country : data.country);
    }

    /**
     * Event po zmene dat.
     *
     * @param {string} type
     * @param {string|number|boolean} value
     */
    onChangeData(type, value) {
        const { eshop } = this.props;
        const { deliveries } = this.state;

        let data = { ...this.state.data, [type]: value };

        switch (type) {
            case 'country':
            case 'delivery_country':
            case 'delivery':
            case 'vies_token':
                // Krajina
                // Krajina dodania
                // Nastavime platbu a dodanie
                // Vies token
                if (type === 'country' && data.dic !== '') {
                    // Menime krajinu je zadane dic, overime
                    this.loadVies(data.dic, value);
                }

                if (type === 'vies_token') {
                    // Nastavime datum
                    data = { ...data, vies_date: eshop.today };
                }

                this.setPaymentAndDelivery(data);
                return;

            case 'delivery_id':
                // Menime dodanie
                if (deliveries[value].setting !== 'packeta') {
                    // Rusime pickup point
                    data = { ...data, pickup_point: '' };
                }
                break;

            case 'phone_prefix':
                if (!isEmptyString(data.phone)) {
                    this.checkPhone('phone', value);
                }
                break;

            case 'phone':
                // Tel cislo
                data = { ...data, phone: formatPhone(value) };
                break;

            case 'delivery_phone':
                // Tel cislo
                data = { ...data, delivery_phone: formatPhone(value) };
                break;

            case 'delivery_phone_prefix':
                if (!isEmptyString(data.delivery_phone)) {
                    this.checkPhone('delivery_phone', value);
                }
                break;
        }

        this.setState({ data });
    }

    /**
     * Event po zmene dodania.
     *
     * @param {number} id
     */
    changeDelivery(id) {
        const { cart } = this.props;
        const { deliveries } = this.state;

        this.onChangeData('delivery_id', id);

        if (deliveries[id].setting === 'packeta') {
            this.showPacketa();
        }
    }

    /**
     * Zmenime platbu.
     *
     * @param {number} id
     */
    changePayment(id) {
        const { cart } = this.props;
        const { payments } = this.state;

        this.onChangeData('payment_id', id);
    }

    /**
     * Zobrazime packetu.
     */
    showPacketa() {
        const { eshop } = this.props;

        // Zobrazime widget
        window.Packeta.Widget.pick(eshop.settings.packeta_token, this.onSelectPickupPoint.bind(this));
    }

    /**
     * Event po zmene odberneho miesta.
     *
     * @param {Object} point
     */
    onSelectPickupPoint(point) {
        if (point !== null) {
            const { data } = this.state;

            this.setState({ data: {
                ...data,
                pickup_point: point.name,
                delivery_address: point.street,
                delivery_city: point.city,
                delivery_zip: point.zip.replace(' ', ''),
                delivery_country: point.country,
                delivery: true,
            } });
        }
    }

    /**
     * Event po zmene kuponu.
     *
     * @param {string} value
     */
    onChangeCoupon(value) {
        this.setState({ coupon_text: value });
    }

    /**
     * Pridame kupon.
     */
    addCoupon() {
        const { cart } = this.props;
        const { data, coupon_text } = this.state;

        if (isEmptyString(coupon_text)) {
            // Nie je zadany
            return;
        }

        this.setState({ coupon_loading: true, coupon_text: '' });

        request(`/coupons/get/${coupon_text}/${_.keys(cart.items).join(',')}`).then(response => {
            const { status } = response.data;

            if (status !== 'success') {
                this.showSnackbar('error', __('Kupón neexistuje'));
                this.setState({ coupon_loading: false });
                return;
            }

            this.setState({
                coupon_loading: false,
                data: { ...data, coupon: coupon_text },
                discount: response.data.data,
            });
        });
    }

    /**
     * Zrusime kupon.
     */
    cancelCoupon() {
        const { data } = this.state;

        this.setState({
            coupon_loading: false,
            coupon_text: '',
            data: { ...data, coupon: '' },
            discount: {},
        });
    }

    /**
     * Event po zmene miesta.
     *
     * @param {Object} place
     * @param {string} prefix
     */
    onChangePlace(place, prefix = '') {
        const { eshop } = this.props;
        let { data } = this.state;

        let additional = {};

        const field = prefix === 'delivery_' ? 'deliveryAddress' : 'address';

        if (_.has(place, 'address_components')) {
            let address = '';
            let address_number = '';
            let city = '';
            let zip = '';
            let country = '';

            _.each(place.address_components, ({ long_name, short_name, types }) => {
                if (!_.isEmpty(types)) {
                    switch (types[0]) {
                        case 'street_number':
                            address_number = long_name;
                            break;

                        case 'route':
                            address = long_name;
                            break;

                        case 'locality':
                            city = long_name;
                            break;

                        case 'postal_code':
                            zip = long_name.replace(' ', '');
                            break;

                        case 'country':
                            country = short_name.toLowerCase();
                            break;

                        default:
                            break;
                    }
                }
            });

            if (address_number === '') {
                // Nemame cislo pokusime sa vytiahnut z textu
                const number = /([0-9\/]+[a-zA-z]{0,1})/.exec(this.state[field]);

                if (number.length === 2) {
                    address_number = number[1];
                }
            }

            address = address_number !== '' ? `${address} ${address_number}` : address;

            data = { ...data,
                [`${prefix}address`]: address,
                [`${prefix}city`]: city,
                [`${prefix}zip`]: zip,
                [`${prefix}country`]: country,
            };

            // Cela adresa
            const fullAddress = `${address} ${city} ${zip}, ${eshop.countries[country]}`;

            additional = { [field]: fullAddress };
        }

        this.setState({ data: data, ...additional });
    }

    /**
     * Nacitame data z Aresu.
     */
    loadAres() {
        const { eshop } = this.props;
        const { data } = this.state;

        if (data.ico === '') {
            return;
        }

        this.setState({ ico_loading: true });

        request(`/eshops/ares/${data.ico}/${data.country}`).then(response => {
            const { status } = response.data;

            if (status !== 'success') {
                this.setState({ ico_loading: false });
                this.showSnackbar('error', __('Nepodarilo sa načítať údaje z registra'));
                return;
            }

            if (!_.isEmpty(response.data.data.dic)) {
                // Mame dic, overime cez vies
                this.loadVies(response.data.data.dic);
            }

            // Cela adresa
            const fullAddress = !_.isEmpty(response.data.data.address)
                ? `${response.data.data.address} ${response.data.data.city} ${response.data.data.zip}, ${eshop.countries[data.country]}`
                : '';

            this.setState({ ico_loading: false, address: fullAddress, data: {
                ...data,
                company_name: response.data.data.name,
                delivery_company_name: response.data.data.name,
                dic: response.data.data.dic,
                address: response.data.data.address,
                city: response.data.data.city,
                zip: response.data.data.zip,
            } });
        });
    }

    /**
     * Nacitame data z Vies.
     *
     * @param {string|null} dic
     * @param {string|null} country
     */
    loadVies(dic = null, country = null) {
        const { data } = this.state;

        dic = dic !== null ? dic : data.dic;
        country = country !== null ? country : data.country;

        if (dic === '') {
            return;
        }

        this.setState({ dic_loading: true });

        request(`/eshops/vies/${dic}/${country}`).then(response => {
            const { data } = response.data;

            this.onChangeData('vies_token', data.token);
        });
    }

    /**
     * Nacitame data z blacklistu.
     *
     * @param {string|null} email
     */
    loadBlacklist(email = null) {
        const { data } = this.state;

        request(`/eshops/blacklist/${email !== null ? email : data.email}`).then(response => {
            const { data } = response.data;

            // Nastavime
            this.setPaymentAndDelivery(this.state.data, false, { onBlacklist: toNumber(data.valid) === 0 });
        });
    }

    /**
     * Skontrolujeme telefon.
     *
     * @param {string} field
     * @param {string|null} prefix
     */
    checkPhone(field = 'phone', prefix = null) {
        const { data, errors } = this.state;

        prefix = prefix !== null ? prefix : data[`${field}_prefix`];

        this.setState({ [`${field}_loading`]: true });

        request(`/eshops/validPhone/${prefix}/${data[field]}`).then(response => {
            const { status } = response.data;

            this.setState({
                [`${field}_loading`]: false,
                [`${field}_valid`]: status === 'success',
                errors: status === 'error' ? { ...errors, [field]: __('Neplatné tel. číslo') } : _.without(errors, field),
            });
        });
    }

    /**
     * Checkujeme email.
     */
    checkEmail() {
        const { data, errors } = this.state;

        const valid = isValidEmail(data.email);

        this.setState({
            email_valid: valid,
            errors: !valid ? { ...errors, email: __('Neplatný email') } : _.without(errors, 'email'),
        });

        // Nacitame blacklist
        this.loadBlacklist();
    }

    /**
     * Objednat.
     */
    buy() {
        const { data, phone_valid, delivery_phone_valid, createPassword, tax } = this.state;

        let additional = {};

        // Zvalidujeme
        let errors = getCheckoutErrors(data);

        if (_.has(errors, 'address') || _.has(errors, 'city') || _.has(errors, 'zip')) {
            additional = { ...additional, showAddress: true };
        }

        if (_.has(errors, 'delivery_address') || _.has(errors, 'delivery_city') || _.has(errors, 'delivery_zip')) {
            additional = { ...additional, showDeliveryAddress: true };
        }

        if (!phone_valid) {
            errors = { ...errors, phone: __('Neplatné tel. číslo') };
        }

        if (data.delivery && !delivery_phone_valid) {
            errors = { ...errors, delivery_phone: __('Neplatné tel. číslo') };
        }

        if (global.token === '' && createPassword !== '' && createPassword.length < 8) {
            // Heslo nema 8 znakov
            errors = { ...errors, password: __('Heslo musí mať minimálne 8 znakov') };
        }

        if (!_.isEmpty(errors)) {
            // Mame errory
            this.setState({ errors, ...additional });
            return;
        }

        const { eshop, settings, cart, cookies, cleanCart, changeUserData } = this.props;

        // Objedname
        this.setState({ loading: true });

        const requestData = {
            ...data,
            currency: settings.currency > 0 ? settings.currency : this.getDefaultCurrency().id,
            products: cart.items,
            tax,
            password: createPassword,
        };

        // Ulozime
        request('/orders/create', requestData, 'POST').then(response => {
            const { status, data } = response.data;

            if (status !== 'success') {
                this.setState({ loading: false });
                this.showSnackbar('error', createPassword !== '' ? __('Email už niekto používa') : __('Nastala chyba'));
                return;
            }

            if (!_.isEmpty(data.user)) {
                // Nove user data
                changeUserData(data.user);
            }

            // Vycistime kosik
            cleanCart();

            // Zmazeme cookie kosik
            cookies.remove(COOKIE_CART_NAME, COOKIE_SETTINGS);

            if (!_.isEmpty(data.user_token)) {
                // Mame zadany user token po registracii
                cookies.set(COOKIE_TOKEN_NAME, data.user_token, COOKIE_SETTINGS);
            }

            // Zobrazime objednavku
            window.location = getUrlPath(`/dakujeme/${data.id}/${data.token}`);
        });
    }

    /**
     * Zobrazime login.
     */
    showLogin() {
        this.setState({ showLogin: !this.state.showLogin });
    }

    /**
     * Zobrazime vytvorenie uctu.
     */
    showCreatePassword() {
        this.setState({ showCreatePassword: !this.state.showCreatePassword });
    }

    /**
     * Zobrazime kupon.
     */
    showCoupon() {
        this.setState({ showCoupon: true });
    }

    /**
     * Event po zmene emailu.
     *
     * @param {string} email
     */
    onChangeEmail(email) {
        this.setState({ email });
    }

    /**
     * Event po zmene kontaktu.
     *
     * @param {bool} contact
     */
    onChangeContact(contact) {
        this.setState({ contact });
    }

    /**
     * Event po zmene hesla.
     *
     * @param {string} password
     */
    onChangePassword(password) {
        this.setState({ password });
    }

    /**
     * Event po zmene hesla.
     *
     * @param {string} createPassword
     */
    onChangeCreatePassword(createPassword) {
        this.setState({ createPassword });
    }

    /**
     * Event po zmene zapamatania sa.
     *
     * @param {boolean} rememberMe
     */
    onChangeRememberMe(rememberMe) {
        this.setState({ rememberMe });
    }

    /**
     * Event po zmene adresy.
     *
     * @param {string} address
     */
    onChangeAddress(address) {
        this.setState({ address });
    }

    /**
     * Event po zmene dodacej adresy.
     *
     * @param {string} deliveryAddress
     */
    onChangeDeliveryAddress(deliveryAddress) {
        this.setState({ deliveryAddress });
    }

    /**
     * Zobrazime zadanie adresy.
     */
    showAddress() {
        this.setState({ showAddress: !this.state.showAddress });
    }

    /**
     * Zobrazime dodaciu adresu.
     */
    showDeliveryAddress() {
        this.setState({ showDeliveryAddress: !this.state.showDeliveryAddress });
    }

    /**
     * Prihlasenie.
     */
    login() {
        const { email, password } = this.state;

        this.setState({ loginLoading: true });

        request('/users/login', { email, password }, 'POST').then(response => {
            const { status, data } = response.data;

            this.setState({ loginLoading: false });

            if (status === 'error') {
                // Prihlasenie sa nepodarilo
                this.showSnackbar('error', __('Nesprávne prihlasovacie údaje'));
                return;
            }

            const { cookies } = this.props;

            // Ulozime token do cookie
            cookies.set(COOKIE_TOKEN_NAME, data.token, COOKIE_SETTINGS);

            window.location = this.getPath();
        });
    }

    /**
     * Odhlasenie.
     */
    logout() {
        const { cookies } = this.props;

        cookies.remove(COOKIE_TOKEN_NAME, COOKIE_SETTINGS);

        window.location = this.getPath();
    }

    /**
     * Vratime hodnotu zlavy.
     *
     * @param {number} credit
     * @param {number} total
     *
     * @return {number}
     */
    getDiscountValue(total, credit = 0) {
        const { discount } = this.state;

        if (credit > 0) {
            // Mame kredit
            return Math.min(total, credit);
        }

        let result = 0;

        if (_.has(discount, 'type') && discount.type !== '') {
            // Mame zadanu zlavu
            switch (discount.type) {
                case 'percent':
                default:
                    result = toFixed(total * (discount.value / 100));
                    break;

                case 'nominal':
                    // Nominalna
                    result = Math.min(total, discount.value);
                    break;
            }
        }

        return result;
    }

    /**
     * Zobrazime popup.
     *
     * @param {string} name
     */
    showPopup(name) {
        const { eshop } = this.props;

        this.showLightbox('popup', { data: getText(eshop.popups[name].text) });
    }

    /**
     * Rendrujeme delivery price.
     *
     * @param {number} deliveryPrice
     *
     * @return {JSX.Element|null}
     */
    renderDeliveryPrice(deliveryPrice) {
        this.totalDelivery = deliveryPrice;

        return deliveryPrice > 0 ? <div className="checkout__summary__content__amounts__item">
            <div className="checkout__summary__content__amounts__item__name">{__('Doprava')}</div>
            <div className="checkout__summary__content__amounts__item__value">{formatAmount(getPrice(deliveryPrice))}</div>
        </div> : null;
    }

    /**
     * Rendrujeme total price.
     *
     * @param {number} total
     *
     * @return {JSX.Element}
     */
    renderTotal(total) {
        this.totalAmount = total;

        return <div className="checkout__summary__content__amount">
            <div className="checkout__summary__content__amount__name">{__('Celkom s DPH')}</div>
            <div className="checkout__summary__content__amount__value">
                {formatAmount(getPrice(total))}
            </div>
        </div>;
    }

    /**
     * Rendrujeme total vat.
     *
     * @param {number} tax
     * @param {number} totalVat
     *
     * @return {JSX.Element}
     */
    renderTotalVat(tax, totalVat) {
        this.totalVat = totalVat;

        return <div className="checkout__summary__content__vat__item">
            <div className="checkout__summary__content__vat__item__name">{__('DPH')} {tax}%</div>
            <div className="checkout__summary__content__vat__item__value">
                {formatAmount(getPrice(totalVat))}
            </div>
        </div>;
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    renderScreen() {
        const { eshop, cart } = this.props;
        const {
            showLogin,
            email,
            password,
            rememberMe,
            loginLoading,
            showCreatePassword,
            createPassword,
            data,
            errors,
            phone_loading,
            phone_valid,
            ico_loading,
            dic_loading,
            address,
            showAddress,
            delivery_phone_loading,
            delivery_phone_valid,
            deliveryAddress,
            showDeliveryAddress,
            deliveries,
            payments,
            showCoupon,
            coupon_text,
            coupon_loading,
            contact,
            loading,
            email_valid,
            lightbox,
            tax,
            showDownButton,
        } = this.state;

        // Je prihlaseny?
        const isLogged = global.token !== '';

        // Zobrazujeme odberne miesto?
        const showPickupPoint = data.pickup_point !== '';

        // Celkova suma
        let totalProducts = 0;
        let deliveryPrice = 0;
        let paymentPrice = 0;

        if (data.delivery_id > 0) {
            // Dodanie
            deliveryPrice = deliveries[data.delivery_id].price;
        }

        if (data.payment_id > 0) {
            // Platba
            paymentPrice = payments[data.payment_id].price;
        }

        // Vytiahneme credit
        const credit = !_.isEmpty(eshop.user) ? toNumber(eshop.user.credit) : 0;

        const downContent = (
            <div className="checkout__summary__content__down">
                <ButtonBase
                    onClick={() => this.buy()}
                    className="checkout__summary__content__down__button"
                >{loading ? this.renderButtonLoading() : __('Dokončiť objednávku')}</ButtonBase>
                <div className="checkout__summary__content__down__text">
                    <span>{__('Kliknutím na Dokončiť objednávku vyjadrujete svoj súhlas s')}</span>
                    <ButtonBase onClick={() => this.showPopup('vop')}>{__('obchodnými podmienkami')}</ButtonBase>
                    <span>{__('a')}</span>
                    <ButtonBase onClick={() => this.showPopup('privacy')}>{__('spracovaním osobních údajov')}</ButtonBase>
                    <span>{__('a záväzne objednávate tovar podľa vyplnenej objednávky.')}</span>
                </div>
            </div>
        );

        return (
            <div>
                <Sections />
                <div className="checkout">
                    <div className="checkout__settings">
                        <h1 className="checkout__settings__title">{__('Pokladňa')}</h1>
                        <div className="checkout__settings__account">
                            {!isLogged ? <div className="checkout__settings__account__header">
                                <div className="checkout__settings__account__header__text">{__('Máte u nás už účet?')}</div>
                                <ButtonBase
                                    onClick={() => this.showLogin()}
                                    className="checkout__settings__account__header__button"
                                >{showLogin ? __('Už som prihlásený') : __('Prihlásiť sa')}</ButtonBase>
                            </div> : <div className="checkout__settings__account__header">
                                <div className="checkout__settings__account__header__text">{__('Prihlásený ako')} <b>{eshop.user.name}</b></div>
                                <ButtonBase
                                    onClick={() => this.logout()}
                                    className="checkout__settings__account__header__button"
                                >{__('Odhlásiť sa')}</ButtonBase>
                            </div>}
                            {showLogin && !isLogged ? <div className="checkout__settings__account__content">
                                <div className="checkout__settings__account__content__inputs">
                                    <Input
                                        label={__('Prihlasovací email')}
                                        value={email}
                                        onChange={value => this.onChangeEmail(value)}
                                        type="email"
                                    />
                                    <Input
                                        label={__('Heslo')}
                                        value={password}
                                        onChange={value => this.onChangePassword(value)}
                                        type="password"
                                    />
                                </div>
                                <div className="checkout__settings__account__content__lost-password">
                                    <ButtonBase
                                        onClick={() => this.redirect('/moj-ucet/zabudnute-heslo')}
                                        className="checkout__settings__account__content__lost-password__button"
                                    >{__('Zabudnuté heslo')}</ButtonBase>
                                </div>
                                <div className="checkout__settings__account__content__buttons">
                                    <Checkbox
                                        label={__('Zapamätať si ma')}
                                        value={rememberMe}
                                        onChange={value => this.onChangeRememberMe(value)}
                                    />
                                    <ButtonBase
                                        onClick={() => this.login()}
                                        className="checkout__settings__account__content__buttons__button"
                                    >{loginLoading ? this.renderButtonLoading() : __('Prihlásiť sa')}</ButtonBase>
                                </div>
                            </div> : null}
                        </div>
                        <div className="checkout__settings__contact">
                            <h2 className="checkout__settings__contact__title">{__('Kontaktné údaje')}</h2>
                            <Select
                                label={__('Krajina')}
                                options={eshop.countries}
                                value={data.country}
                                onChange={value => this.onChangeData('country', value)}
                                allowEmpty={false}
                                countries
                            />
                            <div className="checkout__settings__contact__inputs">
                                <Input
                                    label={<div>{__('Email')} <span>{__('(pre informácie k objednávke)')}</span></div>}
                                    value={data.email}
                                    onChange={value => this.onChangeData('email', value)}
                                    onBlur={() => this.checkEmail()}
                                    error={_.has(errors, 'email') ? errors.email : ''}
                                    right={email_valid ? <div className="input__right valid-icon"><ValidIcon /></div> : null}
                                    type="email"
                                />
                                <div className="phone">
                                    <Select
                                        label={__('Predvoľba')}
                                        options={eshop.phone_prefixes}
                                        value={data.phone_prefix.toUpperCase()}
                                        onChange={value => this.onChangeData('phone_prefix', value.toLowerCase())}
                                        allowEmpty={false}
                                        phones
                                    />
                                    <Input
                                        label={<div>{__('Telefón')} <span>{__('(pre doručenie a prípadné otázky)')}</span></div>}
                                        value={data.phone}
                                        onChange={value => this.onChangeData('phone', value)}
                                        onBlur={() => this.checkPhone()}
                                        error={_.has(errors, 'phone') ? errors.phone : ''}
                                        right={phone_loading
                                            ? <div className="input__right"><CircularProgress className="loading" color="inherit" size={20} /></div>
                                            : (phone_valid ? <div className="input__right valid-icon"><ValidIcon /></div> : null)}
                                    />
                                </div>
                            </div>
                            {showCreatePassword ? <Input
                                label={__('Heslo k účtu')}
                                value={createPassword}
                                onChange={value => this.onChangeCreatePassword(value)}
                                error={_.has(errors, 'password') ? errors.password : ''}
                                type="password"
                            /> : null}
                            {global.token === '' ? <ButtonBase
                                onClick={() => this.showCreatePassword()}
                                className="checkout__settings__contact__create-account"
                            >{showCreatePassword ? __('Nechcem si založiť účet') : __('Chcem si založiť účet')}</ButtonBase> : null}
                        </div>
                        <div className="checkout__settings__address">
                            <h2 className="checkout__settings__address__title">{__('Fakturačné a doručovacie údaje')}</h2>
                            <Checkbox
                                label={__('Nakupujem na firmu')}
                                value={data.company}
                                onChange={value => this.onChangeData('company', value)}
                            />
                            {data.company ? <div className="checkout__settings__contact__inputs">
                                <Input
                                    label={<div>{__('IČO')} <span>{__('(automatické načítanie údajov z ARES)')}</span></div>}
                                    value={data.ico}
                                    onChange={value => this.onChangeData('ico', value)}
                                    onBlur={() => this.loadAres()}
                                    error={_.has(errors, 'ico') ? errors.ico : ''}
                                    right={ico_loading
                                        ? <div className="input__right"><CircularProgress className="loading" color="inherit" size={20} /></div>
                                        : null}
                                />
                                <Input
                                    label={<div>{__('DIČ')} {data.vies_token !== ''
                                        ? <span className="c-green">{__('(Overené v systéme VIES)')}</span>
                                        : null}</div>}
                                    value={data.dic}
                                    onChange={value => this.onChangeData('dic', value)}
                                    onBlur={() => this.loadVies()}
                                    error={_.has(errors, 'dic') ? errors.dic : ''}
                                    right={dic_loading
                                        ? <div className="input__right"><CircularProgress className="loading" color="inherit" size={20} /></div>
                                        : (data.vies_token !== ''
                                            ? <div className="input__right valid-icon"><ValidIcon /></div>
                                            : null)}
                                />
                            </div> : null}
                            {data.company ? <Input
                                label={__('Názov firmy')}
                                value={data.company_name}
                                onChange={value => this.onChangeData('company_name', value)}
                                error={_.has(errors, 'company_name') ? errors.company_name : ''}
                            /> : null}
                            {!data.company ? <Input
                                label={__('Celé meno')}
                                value={data.name}
                                onChange={value => this.onChangeData('name', value)}
                                error={_.has(errors, 'name') ? errors.name : ''}
                            /> : null}
                            {!showAddress ? <GoogleAutocomplete
                                label={<div>{__('Adresa')} <span>{__('(automatické vyhľadávanie)')}</span></div>}
                                value={address}
                                onChange={value => this.onChangeAddress(value)}
                                onPlaceSelected={place => this.onChangePlace(place)}
                                placeApiKey={eshop.settings.gmaps_apikey}
                                placeholder={__('Začnite písať adresu a vyberte spolu s popisním číslom')}
                                left={<SearchIcon strokeWidth={1} />}
                            /> : null}
                            {showAddress ? <Input
                                label={__('Ulica a popisné číslo')}
                                value={data.address}
                                onChange={value => this.onChangeData('address', value)}
                                error={_.has(errors, 'address') ? errors.address : ''}
                            /> : null}
                            {showAddress ? <Input
                                label={<div>{__('Číslo hotelovej izby, apartmánu alebo ďalšie upresnenie adresy')} <span>{__('(nepovinné)')}</span></div>}
                                value={data.address_additional}
                                onChange={value => this.onChangeData('address_additional', value)}
                                error={_.has(errors, 'address_additional') ? errors.address_additional : ''}
                            /> : null}
                            {showAddress ? <div className="checkout__settings__contact__inputs">
                                <Input
                                    label={__('PSČ')}
                                    value={data.zip}
                                    onChange={value => this.onChangeData('zip', value)}
                                    error={_.has(errors, 'zip') ? errors.zip : ''}
                                />
                                <Input
                                    label={__('Mesto')}
                                    value={data.city}
                                    onChange={value => this.onChangeData('city', value)}
                                    error={_.has(errors, 'city') ? errors.city : ''}
                                />
                            </div> : null}
                            <ButtonBase
                                onClick={() => this.showAddress()}
                                className="checkout__settings__address__manual"
                            >{showAddress ? __('Nebudem zadávať adresu') : __('Adresu zadám ručne')}</ButtonBase>
                            <h2 className="checkout__settings__address__subtitle">{__('Doručovacia adresa')}</h2>
                            <Checkbox
                                label={__('Doručiť na inú adresu')}
                                value={data.delivery}
                                onChange={value => this.onChangeData('delivery', value)}
                                disabled={showPickupPoint}
                            />
                            {data.delivery ? <div className="checkout__settings__address__delivery">
                                {data.company ? <Input
                                    label={__('Názov firmy')}
                                    value={data.delivery_company_name}
                                    onChange={value => this.onChangeData('delivery_company_name', value)}
                                    error={_.has(errors, 'delivery_company_name') ? errors.delivery_company_name : ''}
                                /> : null}
                                {!data.company ? <Input
                                    label={__('Celé meno')}
                                    value={data.delivery_name}
                                    onChange={value => this.onChangeData('delivery_name', value)}
                                    error={_.has(errors, 'delivery_name') ? errors.delivery_name : ''}
                                /> : null}
                                <div className="checkout__settings__contact__inputs">
                                    <Select
                                        label={__('Krajina')}
                                        options={eshop.countries}
                                        value={data.delivery_country}
                                        onChange={value => this.onChangeData('delivery_country', value)}
                                        allowEmpty={false}
                                        disabled={showPickupPoint}
                                        countries
                                    />
                                    <div className="phone">
                                        <Select
                                            label={__('Predvoľba')}
                                            options={eshop.phone_prefixes}
                                            value={data.delivery_phone_prefix.toUpperCase()}
                                            onChange={value => this.onChangeData('delivery_phone_prefix', value.toLowerCase())}
                                            allowEmpty={false}
                                            phones
                                        />
                                        <Input
                                            label={<div>{__('Telefón')} <span>{__('(pre doručenie a prípadné otázky)')}</span></div>}
                                            value={data.delivery_phone}
                                            onChange={value => this.onChangeData('delivery_phone', value)}
                                            onBlur={() => this.checkPhone('delivery_phone')}
                                            error={_.has(errors, 'delivery_phone') ? errors.delivery_phone : ''}
                                            right={delivery_phone_loading
                                                ? <div className="input__right"><CircularProgress className="loading" color="inherit" size={20} /></div>
                                                : (delivery_phone_valid ? <div className="input__right valid-icon"><ValidIcon /></div> : null)}
                                        />
                                    </div>
                                </div>
                                {showPickupPoint ? <Input
                                    label={__('Odberné miesto')}
                                    value={data.pickup_point}
                                    onChange={value => this.onChangeData('pickup_point', value)}
                                    onFocus={() => this.showPacketa()}
                                /> : null}
                                {!showDeliveryAddress && !showPickupPoint ? <GoogleAutocomplete
                                    label={<div>{__('Adresa')} <span>{__('(automatické vyhľadávanie)')}</span></div>}
                                    value={deliveryAddress}
                                    onChange={value => this.onChangeDeliveryAddress(value)}
                                    onPlaceSelected={place => this.onChangePlace(place, 'delivery_')}
                                    placeApiKey={eshop.settings.gmaps_apikey}
                                    placeholder={__('Začnite písať adresu a vyberte spolu s popisním číslom')}
                                    left={<SearchIcon strokeWidth={1} />}
                                /> : null}
                                {showDeliveryAddress && !showPickupPoint ? <Input
                                    label={__('Ulica a popisné číslo')}
                                    value={data.delivery_address}
                                    onChange={value => this.onChangeData('delivery_address', value)}
                                    error={_.has(errors, 'delivery_address') ? errors.delivery_address : ''}
                                /> : null}
                                {showDeliveryAddress && !showPickupPoint ? <Input
                                    label={<div>{__('Číslo hotelovej izby, apartmánu alebo ďalšie upresnenie adresy')} <span>{__('(nepovinné)')}</span></div>}
                                    value={data.delivery_address_additional}
                                    onChange={value => this.onChangeData('delivery_address_additional', value)}
                                    error={_.has(errors, 'delivery_address_additional') ? errors.delivery_address_additional : ''}
                                /> : null}
                                {showDeliveryAddress && !showPickupPoint ? <div className="checkout__settings__contact__inputs">
                                    <Input
                                        label={__('PSČ')}
                                        value={data.delivery_zip}
                                        onChange={value => this.onChangeData('delivery_zip', value)}
                                        error={_.has(errors, 'delivery_zip') ? errors.delivery_zip : ''}
                                    />
                                    <Input
                                        label={__('Mesto')}
                                        value={data.delivery_city}
                                        onChange={value => this.onChangeData('delivery_city', value)}
                                        error={_.has(errors, 'delivery_city') ? errors.delivery_city : ''}
                                    />
                                </div> : null}
                                {!showPickupPoint ? <ButtonBase
                                    onClick={() => this.showDeliveryAddress()}
                                    className="checkout__settings__address__manual"
                                >{showDeliveryAddress ? __('Nebudem zadávať adresu') : __('Adresu zadám ručne')}</ButtonBase> : null}
                            </div> : null}
                        </div>
                        {data.delivery_id > 0 ? <div className="checkout__settings__items">
                            <h2 className="checkout__settings__items__title">{__('Možnosti doručenia')}</h2>
                            <div className="checkout__settings__items__buttons">
                                {_.map(deliveries, ({ id, image, name, price }) => {
                                    const active = data.delivery_id === id;
                                    name = getText(name);

                                    return (
                                        <ButtonBase
                                            onClick={() => this.changeDelivery(id)}
                                            className={`checkout__settings__items__buttons__button ${active ? 'active' : ''}`}
                                            key={id}
                                        >
                                            {image !== ''
                                                ? <img src={getImageUrl(image)} alt={name} />
                                                : <div className="checkout__settings__items__buttons__button__icon"><CodIcon strokeWidth={1} /><span>{name}</span></div>}
                                            <div className="checkout__settings__items__buttons__button__price">
                                                {toNumber(price) === 0 ? __('zadarmo') : formatAmount(getPrice(price))}
                                            </div>
                                            {active ? <ValidIcon /> : null}
                                        </ButtonBase>
                                    );
                                })}
                            </div>
                        </div> : null}
                        <div className="checkout__settings__items">
                            <h2 className="checkout__settings__items__title">{__('Možnosti platby')}</h2>
                            <div className="checkout__settings__items__buttons">
                                {_.map(payments, ({ id, image, name, price, setting }) => {
                                    const active = data.payment_id === id;
                                    name = getText(name);

                                    let svgIcon = null;

                                    if (image === '') {
                                        if (setting === 'cod') {
                                            svgIcon = <CodIcon strokeWidth={1} />;
                                        } else if (setting === 'transfer') {
                                            svgIcon = <TransferIcon strokeWidth={1} />;
                                        } else {
                                            svgIcon = <CardIcon strokeWidth={1} />;
                                        }
                                    }

                                    return (
                                        <ButtonBase
                                            onClick={() => this.changePayment(id)}
                                            className={`checkout__settings__items__buttons__button ${active ? 'active' : ''}`}
                                            key={id}
                                        >
                                            {image !== ''
                                                ? <img src={getImageUrl(image)} alt={name} />
                                                : <div className="checkout__settings__items__buttons__button__icon">{svgIcon}<span>{name}</span></div>}
                                            <div className="checkout__settings__items__buttons__button__price">
                                                {toNumber(price) === 0 ? __('zadarmo') : formatAmount(getPrice(price))}
                                            </div>
                                            {active ? <ValidIcon /> : null}
                                        </ButtonBase>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="checkout__summary">
                        <div className="checkout__summary__content">
                            <h2 className="checkout__summary__content__title">{__('Vaša objednávka')}</h2>
                            <div className="checkout__summary__content__products">
                                {_.map(cart.items, ({ id, count, settings }) => {
                                    if (!_.has(eshop.products, id)) {
                                        return null;
                                    }

                                    // Vytiahneme produkt
                                    const product = eshop.products[id];
                                    const name = getText(product.name);

                                    let extensionsPrice = 0;

                                    // Prejdeme rozsirenia
                                    _.each(settings.extensions, id => {
                                        if (_.has(eshop.extensions, id)) {
                                            extensionsPrice += eshop.extensions[id].price;
                                        }
                                    });

                                    // Ma extensions?
                                    const hasExtensions = !_.isEmpty(settings.extensions);
                                    let price = (getProductPrice(product.price, count, product.prices) + extensionsPrice);

                                    price *= count;
                                    totalProducts += price;

                                    return (
                                        <div className="checkout__summary__content__products__product" key={id}>
                                            <div className={`checkout__summary__content__products__product__up ${hasExtensions ? 'with-extensions' : ''}`}>
                                                <div className="checkout__summary__content__products__product__up__left">
                                                    <div className="checkout__summary__content__products__product__up__left__photo">
                                                        {product.image !== '' ? <img src={getImageUrl(product.image)} alt={getText(product.name)} /> : null}
                                                    </div>
                                                </div>
                                                <div className="checkout__summary__content__products__product__up__info">
                                                    <div className="checkout__summary__content__products__product__up__info__name">
                                                        {`${count > 1 ? `${count}x ` : ''}${name}${_.has(settings, 'variant') && settings.variant > 0 && _.has(eshop.variants, settings.variant) ? ` - ${getText(eshop.variants[settings.variant].name)}` : ''}`}
                                                    </div>
                                                </div>
                                                <div className="checkout__summary__content__products__product__up__price">{formatAmount(getPrice(price))}</div>
                                            </div>
                                            {hasExtensions ? <div className="checkout__summary__content__products__product__extensions">
                                                {_.map(settings.extensions, id => {
                                                    if (!_.has(eshop.extensions, id)) {
                                                        return null;
                                                    }

                                                    let title = '';

                                                    return (
                                                        <div className="checkout__summary__content__products__product__extensions__extension" key={id}>
                                                            <div className="checkout__summary__content__products__product__extensions__extension__name">
                                                                {getText(eshop.extensions[id].name)}
                                                                {title !== ''
                                                                    ? <Tooltip title={title}><InfoIcon /></Tooltip>
                                                                    : null}
                                                            </div>
                                                            <div className="checkout__summary__content__products__product__extensions__extension__price">
                                                                {formatAmount(getPrice(eshop.extensions[id].price))}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div> : null}
                                        </div>
                                    );
                                })}
                            </div>
                            {_.isEmpty(data.coupon) && toNumber(credit) === 0 ? <div className="checkout__summary__content__coupon">
                                {!showCoupon ? <ButtonBase
                                    onClick={() => this.showCoupon()}
                                    className="checkout__summary__content__coupon__button"
                                >{__('Zadať kupón')}</ButtonBase> : null}
                                {showCoupon ? <div className="checkout__summary__content__coupon__content">
                                    <Input
                                        value={coupon_text}
                                        onChange={value => this.onChangeCoupon(value)}
                                    />
                                    <ButtonBase
                                        onClick={coupon_loading ? () => {} : () => this.addCoupon()}
                                        className="checkout__summary__content__coupon__content__button"
                                    >{coupon_loading ? this.renderButtonLoading() : __('Aplikovať kupón')}</ButtonBase>
                                </div> : null}
                            </div> : null}
                            <div className="checkout__summary__content__amounts">
                                <div className="checkout__summary__content__amounts__item">
                                    <div className="checkout__summary__content__amounts__item__name">{__('Celkom za tovar')}</div>
                                    <div className="checkout__summary__content__amounts__item__value">{formatAmount(getPrice(totalProducts))}</div>
                                </div>
                                {(!_.isEmpty(data.coupon) || credit > 0) && this.getDiscountValue(totalProducts, credit) > 0 ? <div className="checkout__summary__content__amounts__item">
                                    <div className="checkout__summary__content__amounts__item__name">
                                        {credit > 0 ? __('Kredit') : `${__('Kupón')} (${data.coupon})`}
                                    </div>
                                    <div className="checkout__summary__content__amounts__item__value">-{formatAmount(getPrice(this.getDiscountValue(totalProducts, credit)))}</div>
                                </div> : null}
                                {this.renderDeliveryPrice(deliveryPrice)}
                                {paymentPrice > 0 ? <div className="checkout__summary__content__amounts__item">
                                    <div className="checkout__summary__content__amounts__item__name">{__('Poplatok za dobierku')}</div>
                                    <div className="checkout__summary__content__amounts__item__value">{formatAmount(getPrice(paymentPrice))}</div>
                                </div> : null}
                            </div>
                            {this.renderTotal(totalProducts + deliveryPrice + paymentPrice - this.getDiscountValue(totalProducts, credit))}
                            <div className="checkout__summary__content__vat">
                                {this.renderTotalVat(tax, (totalProducts + deliveryPrice + paymentPrice - this.getDiscountValue(totalProducts, credit)) - toFixed((totalProducts + deliveryPrice + paymentPrice - this.getDiscountValue(totalProducts, credit)) * (100 / (100 + toNumber(tax)))))}
                                <div className="checkout__summary__content__vat__item">
                                    <div className="checkout__summary__content__vat__item__name">{__('Cena bez DPH')}</div>
                                    <div className="checkout__summary__content__vat__item__value">
                                        {formatAmount(getPrice(toFixed((totalProducts + deliveryPrice + paymentPrice - this.getDiscountValue(totalProducts, credit)) * (100 / (100 + toNumber(tax))))))}
                                    </div>
                                </div>
                            </div>
                            <Input
                                label={__('Poznámka k objednávke')}
                                value={data.comment}
                                onChange={value => this.onChangeData('comment', value)}
                                multiline
                                minRows={3}
                            />
                            <div ref={this.checkoutButtonRef}>{downContent}</div>
                            {showDownButton ? <div className="checkout__summary__content__down-fixed">
                                {downContent}
                            </div> : null}
                        </div>
                    </div>
                </div>
                {this.renderLightbox('popup', '', !_.isEmpty(lightbox.popup)
                    ? <div dangerouslySetInnerHTML={{ __html: lightbox.popup.data }} />
                    : null, '', __('Zrušiť'))}
                {this.renderSnackbar()}
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        return super.render();
    }
}

const stateToProps = ({ eshop, settings, cart }) => ({ eshop, settings, cart });

export default withCookies(connect(stateToProps, { fetchEshop, fetchSettings, fetchCart, cleanCart, changeUserData, fetchFavorites, fetchCompares, fetchLists })(CheckoutScreen));
