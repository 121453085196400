import { toNumber } from './toNumber';

/**
 * Sklonovanie.
 *
 * @param {number} count
 * @param {Array} texts
 *
 * @return {string}
 */
export const plural = (count, texts) => {
    switch (toNumber(count)) {
        case 1:
            return texts[0];

        case 2:
        case 3:
        case 4:
            return texts[1];

        default:
            return texts[2];
    }
};
