import React, { Component } from 'react';
import _ from 'lodash';
import { Snackbar, CircularProgress } from '@mui/material';
import { Drawer } from './Drawer';
import { Lightbox } from './Lightbox';
import { Message } from './Message';
import { getUrlPath } from '../functions';

/**
 * Navigate komponenta.
 */
class Navigate extends Component {

    /**
     * Presmerujeme na path.
     *
     * @param {string} path
     */
    redirect(path) {
        this.props.navigate(getUrlPath(path));
    }

    /**
     * Mobilna verzia.
     *
     * @return {boolean}
     */
    isMobile() {
        return window.innerWidth < 1200;
    }

    /**
     * Vratime aktualnu path.
     *
     * @return {string}
     */
    getPath() {
        return this.props.location.pathname;
    }

    /**
     * Vratime default currency.
     *
     * @return {Object}
     */
    getDefaultCurrency() {
        const { eshop } = this.props;

        let currency = null;

        // Hladame podla krajiny
        _.each(eshop.currencies, item => {
            if (_.includes(item.countries, eshop.country)) {
                // Nasli sme podla krajiny
                currency = item;
            }
        });

        if (currency === null) {
            // Nenasli sme podla krajiny
            // Hladame default s prazdnymi krajinami
            _.each(eshop.currencies, item => {
                if (_.isEmpty(item.countries)) {
                    // Nasli sme podla krajiny
                    currency = item;
                }
            });
        }

        if (currency !== null) {
            // Nasli sme podla krajiny
            return currency;
        }

        return _.values(eshop.currencies)[0];
    }

    /**
     * Vratime default preklad.
     *
     * @return {Object}
     */
    getDefaultLanguage() {
        const { eshop } = this.props;

        let language = null;

        // Hladame podla krajiny
        _.each(eshop.languages, item => {
            if (_.includes(item.countries, eshop.country)) {
                // Nasli sme podla krajiny
                language = item;
            }
        });

        if (language === null) {
            // Nenasli sme podla krajiny
            // Hladame default s prazdnymi krajinami
            _.each(eshop.languages, item => {
                if (_.isEmpty(item.countries)) {
                    // Nasli sme podla krajiny
                    language = item;
                }
            });
        }

        if (language !== null) {
            // Nasli sme podla krajiny
            return language;
        }

        return _.values(eshop.languages)[0];
    }

    /**
     * Vratime zoznam dodani.
     *
     * @param {string} country
     *
     * @return {Object}
     */
    getDelivers(country) {
        const { eshop } = this.props;

        // Vytiahneme dodania podla krajiny
        let items = _.reduce(eshop.delivers, (result, item, id) => {
            if (_.includes(item.countries, country)) {
                // Nezobrazujeme
                return { ...result, [id]: item };
            }

            return result;
        }, {});

        if (_.isEmpty(items)) {
            // Nemame dodania podla krajiny, najdeme tie bez krajiny
            items = _.reduce(eshop.delivers, (result, item, id) => {
                if (_.isEmpty(item.countries)) {
                    // Nezobrazujeme
                    return { ...result, [id]: item };
                }

                return result;
            }, {});
        }

        return items;
    }

    /**
     * Vratime zoznam platieb.
     *
     * @param {string} country
     *
     * @return {Object}
     */
    getPayments(country) {
        const { eshop } = this.props;

        // Vytiahneme platby podla krajiny
        let items = _.reduce(eshop.payments, (result, item, id) => {
            if (_.includes(item.countries, country)) {
                // Nezobrazujeme
                return { ...result, [id]: item };
            }

            return result;
        }, {});

        if (_.isEmpty(items)) {
            // Nemame platby podla krajiny, najdeme tie bez krajiny
            items = _.reduce(eshop.payments, (result, item, id) => {
                if (_.isEmpty(item.countries)) {
                    // Nezobrazujeme
                    return { ...result, [id]: item };
                }

                return result;
            }, {});
        }

        return items;
    }

    /**
     * Zobrazime snackbar.
     *
     * @param {string} type
     * @param {string} message
     */
    showSnackbar(type, message) {
        this.setState({ snackbar: { ...this.state.snackbar, ...{ type, message } } });
    }

    /**
     * Event na schovanie snackbaru.
     */
    onCloseSnackbar() {
        this.setState({ snackbar: { ...this.state.snackbar, type: null } });
    }

    /**
     * Zobrazime drawer.
     *
     * @param {string} name
     * @param {Object} value
     */
    showDrawer(name, value = {}) {
        if (this.isDrawerOpened(name)) {
            // Je otvoreny zatvarame
            this.closeDrawer(name);
            return;
        }

        this.setState({ drawers: { [name]: value } });
    }

    /**
     * Zavrieme drawer.
     *
     * @param {string} name
     */
    closeDrawer(name) {
        let drawers = _.has(this.state, 'drawers') ? this.state.drawers : {};

        drawers = _.omit(drawers, [name]);

        this.setState({ drawers });
    }

    /**
     * Je otvoreny drawer?
     *
     * @param {string} name
     *
     * @return {boolean}
     */
    isDrawerOpened(name) {
        return _.has(this.state, 'drawers') && _.has(this.state.drawers, name);
    }

    /**
     * Zobrazime lightbox.
     *
     * @param {string} type
     * @param {object,null} data
     */
    showLightbox(type, data = null) {
        this.setState({ lightbox: { ...this.state.lightbox, [type]: data !== null ? data : true } });
    }

    /**
     * Zatvorime lightbox.
     *
     * @param {string} type
     */
    closeLightbox(type) {
        this.onCloseLightbox(type);
    }

    /**
     * Event po zatvoreny lightboxu.
     *
     * @param {string} type
     */
    onCloseLightbox(type) {
        this.setState({ lightbox: { ...this.state.lightbox, [type]: false } });
    }

    /**
     * Rendrujeme lightbox.
     *
     * @param {string} type
     * @param {string} title
     * @param {JSX.Element} content
     * @param {string} agreeText
     * @param {string} closeText
     * @param {function|null} onAgree
     * @param {boolean} disableClose
     * @param {boolean} closeOnAgree
     *
     * @return {JSX.Element}
     */
    renderLightbox(
        type,
        title,
        content,
        agreeText = '',
        closeText = '',
        onAgree = null,
        disableClose = false,
        closeOnAgree = true
    ) {
        return <Lightbox
            title={title}
            content={content}
            open={_.isBoolean(this.state.lightbox[type]) ? this.state.lightbox[type] : true}
            agreeText={agreeText}
            closeText={closeText}
            onAgree={onAgree}
            onClose={this.onCloseLightbox.bind(this, type)}
            disableClose={disableClose}
            closeOnAgree={closeOnAgree}
        />;
    }

    /**
     * Rendrujeme drawer.
     *
     * @param {string} name
     * @param {Object} props
     *
     * @return {JSX.Element|null}
     */
    renderDrawer(name, props = {}) {
        if (this.isDrawerOpened(name)) {
            return <Drawer
                name={name}
                onClose={() => this.closeDrawer(name)}
                contentProps={this.state.drawers[name]}
                {...props}
            />;
        }

        return null;
    }

    /**
     * Rendrovanie snackbaru.
     *
     * @returns {JSX.Element}
     */
    renderSnackbar() {
        if (!_.has(this.state, 'snackbar')) {
            return null;
        }

        const { type, message } = this.state.snackbar;

        return (
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={!_.isEmpty(type)}
                autoHideDuration={6000}
                onClose={this.onCloseSnackbar.bind(this)}
                style={{ zIndex: 5000000 }}
                message={<Message type={type}>{message}</Message>}
            />
        );
    }

    /**
     * Rendrujeme button loading.
     *
     * @return {JSX.Element}
     */
    renderButtonLoading() {
        return <CircularProgress color="inherit" size={20} />;
    }
}

export { Navigate };
