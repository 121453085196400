import {
    ADD_FAVORITES_PRODUCT,
    DELETE_FAVORITES_PRODUCT,
    CHANGE_FAVORITES_PRODUCT_COUNT,
    FETCH_FAVORITES,
    CLEAN_FAVORITES,
} from '../actions/types';
import _ from 'lodash';
import { toNumber } from '../functions';
import { COOKIE_FAVORITES_NAME, COOKIE_SETTINGS } from '../config';

const FAVORITES_DEFAULT_STATE = {
    items: {},
    id: Math.floor(Math.random() * (10000000 - 100000 + 1) + 100000)
};

/**
 * Reducer pre oblubene.
 */
const FavoritesReducer = (state = FAVORITES_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_FAVORITES:
            // Nacitame oblubene
            return { ...FAVORITES_DEFAULT_STATE, ...payload };

        case CLEAN_FAVORITES:
            // Vycistime oblubene
            return FAVORITES_DEFAULT_STATE;

        case ADD_FAVORITES_PRODUCT:
            // Pridame produkt do oblubene
            state = { ...state, items: { ...state.items, [Math.random()]: payload } };

            // Ulozime do cookie
            global.screen.props.cookies.set(COOKIE_FAVORITES_NAME, JSON.stringify(state), COOKIE_SETTINGS);

            return state;

        case DELETE_FAVORITES_PRODUCT:
            // Zmazeme produkt z oblubene
            state = { ...state, items: _.reduce(state.items, (result, item, id) => (toNumber(id) === toNumber(payload)
                    ? result
                    : { ...result, [id]: item  }), {}) };

            // Ulozime do cookie
            global.screen.props.cookies.set(COOKIE_FAVORITES_NAME, JSON.stringify(state), COOKIE_SETTINGS);

            return state;

        case CHANGE_FAVORITES_PRODUCT_COUNT:
            // Zmenime pocet produktu v oblubene
            state = { ...state, items: { ...state.items, [payload.id]: {
                        ...state.items[payload.id],
                        count: payload.count,
                    } } };

            // Ulozime do cookie
            global.screen.props.cookies.set(COOKIE_FAVORITES_NAME, JSON.stringify(state), COOKIE_SETTINGS);

            return state;

        default:
            return state;
    }
};

export default FavoritesReducer;
