import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { ButtonBase } from '@mui/material';
import Map from '../components/Map';
import { Screen, Sections } from '../components';
import { __ } from '../functions';
import { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists } from '../actions';
import '../assets/styles/shops.css';

/**
 * Predajne.
 */
class ShopsScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Predajne');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        selectedShop: null,
    };

    /**
     * Pobocky.
     *
     * @type {Array}
     */
    points = [
        { label: 'Lorem ipsum s.r.o.', address: 'Ulica 16A,<br />746 01 Budapest', country: 'hu', lat: 49.94126005609579, lng: 17.904039185129783 },
        { label: 'Lorem ipsum s.r.o.', address: 'Ulica 3232/42,<br />934 01 Levice', country: 'sk', lat: 48.217857800560225, lng: 18.610392598574688 },
        { label: 'Lorem ipsum s.r.o.', address: 'Ulica 5,<br />831 04 Bratislava', country: 'sk', lat: 48.16549580998672, lng: 17.13736421206556 },
        { label: 'Lorem ipsum s.r.o.', address: 'Ulica 255,<br />027 40 Nitra', country: 'sk', lat: 52.1702151451054, lng: 21.019491098685418 },
    ];

    /**
     * Vyberieme pobocku.
     *
     * @param {number} lat
     * @param {number} lng
     * @param {boolean} scroll
     */
    selectShop(lat, lng, scroll = false) {
        this.setState({ selectedShop: { lat, lng } });

        if (scroll) {
            this.scrollToTop();
        }
    }

    /**
     * Rendrujeme pobocky.
     *
     * @param {string} showCountry
     *
     * @return {JSX.Element[]}
     */
    renderShops(showCountry) {
        const { selectedShop } = this.state;

        return _.map(this.points, ({ label, address, country, lat, lng }, key) => {
            if (country !== showCountry) {
                return null;
            }

            const active = selectedShop !== null && selectedShop.lat === lat && selectedShop.lng === lng;

            return (
                <ButtonBase
                    onClick={() => this.selectShop(lat, lng, true)}
                    className={`shops__panel__items__item ${active ? 'active' : ''}`}
                    key={key}
                >
                    <div className="shops__panel__items__item__title">{label}</div>
                    <div className="shops__panel__items__item__text"
                         dangerouslySetInnerHTML={{ __html: address }}
                    />
                </ButtonBase>
            );
        });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    renderScreen() {
        const { eshop } = this.props;
        const { selectedShop } = this.state;

        return (
            <div>
                <Sections links={{
                    '/': __('Domáca stránka'),
                    '': __('Predajne'),
                }} />
                <div className="shops">
                    <h1 className="shops__title">{__('Predajne')}</h1>
                    <div className="shops__map">
                        <Map
                            apiKey={eshop.settings.gmaps_apikey}
                            selected={selectedShop}
                            onSelect={(lat, lng) => this.selectShop(lat, lng)}
                            points={this.points}
                        />
                    </div>
                    <div className="shops__panel">
                        <h2 className="shops__panel__title">{__('Maďarsko')}</h2>
                        <div className="shops__panel__items">
                            {this.renderShops('hu')}
                        </div>
                    </div>
                    <div className="shops__panel">
                        <h2 className="shops__panel__title">{__('Slovenská republika')}</h2>
                        <div className="shops__panel__items">
                            {this.renderShops('sk')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        return super.render();
    }
}

const stateToProps = ({ eshop, settings }) => ({ eshop, settings });

export default withCookies(connect(stateToProps, { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists })(ShopsScreen));
