// Eshop
export const FETCH_ESHOP = 'fetch_eshop';
export const CHANGE_USER_DATA = 'change_user_data';
// Nastavenia
export const FETCH_SETTINGS = 'fetch_settings';
export const CHANGE_SETTING = 'change_setting';
// Kosik
export const FETCH_CART = 'fetch_cart';
export const CLEAN_CART = 'clean_cart';
export const ADD_CART_PRODUCT = 'add_cart_product';
export const DELETE_CART_PRODUCT = 'delete_cart_product';
export const CHANGE_CART_PRODUCT_COUNT = 'change_cart_product_count';
export const CHANGE_CART_DELIVERY_ID = 'change_cart_delivery_id';
export const CHANGE_CART_PAYMENT_ID = 'change_cart_payment_id';
export const SET_CART_DISCOUNT = 'set_cart_discount';
export const DELETE_CART_DISCOUNT = 'delete_cart_discount';
export const CHANGE_CART_VAT = 'change_cart_vat';
// Blog
export const FETCH_BLOG = 'fetch_blog';
// Oblubene
export const FETCH_FAVORITES = 'fetch_favorites';
export const CLEAN_FAVORITES = 'clean_favorites';
export const ADD_FAVORITES_PRODUCT = 'add_favorites_product';
export const DELETE_FAVORITES_PRODUCT = 'delete_favorites_product';
export const CHANGE_FAVORITES_PRODUCT_COUNT = 'change_favorites_product_count';
// Zoznamy
export const FETCH_LISTS = 'fetch_lists';
export const CLEAN_LISTS = 'clean_lists';
export const ADD_LISTS_PRODUCT = 'add_lists_product';
export const DELETE_LISTS_PRODUCT = 'delete_lists_product';
export const CHANGE_LISTS_PRODUCT_COUNT = 'change_lists_product_count';
export const ADD_LISTS_NAME = 'add_lists_name';
export const DELETE_LISTS_NAME = 'delete_lists_name';
export const RENAME_LISTS_NAME = 'rename_lists_name';
// Porovnavania
export const FETCH_COMPARES = 'fetch_compares';
export const CLEAN_COMPARES = 'clean_compares';
export const ADD_COMPARES_PRODUCT = 'add_compares_product';
export const DELETE_COMPARES_PRODUCT = 'delete_compares_product';
export const CHANGE_COMPARES_PRODUCT_COUNT = 'change_compares_product_count';
