import EshopReducer from './EshopReducer';
import SettingsReducer from './SettingsReducer';
import CartReducer from './CartReducer';
import BlogReducer from './BlogReducer';
import FavoritesReducer from './FavoritesReducer';
import ComparesReducer from './ComparesReducer';
import ListsReducer from './ListsReducer';

/**
 * Zoznam reducerov.
 *
 * @type {Object}
 */
export const reducers = {
    eshop: EshopReducer,
    settings: SettingsReducer,
    cart: CartReducer,
    blog: BlogReducer,
    favorites: FavoritesReducer,
    compares: ComparesReducer,
    lists: ListsReducer,
};
