import React from 'react';
import _ from 'lodash';
import { ButtonBase } from '@mui/material';
import ArrowBorderIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { Navigate } from './Navigate';
import { __, getText, getImageUrl } from '../functions';
import '../assets/styles/blog.css';

/**
 * Blog.
 */
class Blog extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        screen: null,
        eshop: {},
        categories: {},
        items: [],
        page: 0,
        perPage: 0,
        onNextPage: null,
        showLoad: false,
        loadText: '',
        loadCallback: null,
    };

    /**
     * Event po zmene na dalsiu stranu.
     */
    onNextPage() {
        const { onNextPage } = this.props;

        // Zavolame callback
        onNextPage();
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { categories, items, page, perPage, onNextPage, loadCallback, loadText, showLoad } = this.props;

        // Rozdelime produkty na strankovanie
        const chunks = _.chunk(items, perPage);

        return (
            <div className="blog">
                <div className="blog__content">
                    {_.map(chunks, (chunk, key) => {
                        if ((key + 1) > page) {
                            // Dalsia stranka
                            return null;
                        }

                        return _.map(chunk, article => {
                            let { id, url, name, image, short_text } = article;

                            name = getText(name);

                            return (
                                <ButtonBase
                                    onClick={() => this.redirect(`/${getText(url)}`)}
                                    className="blog__content__item"
                                    key={id}
                                >
                                    <div className="blog__content__item__photo">
                                        {image !== '' ? <img src={getImageUrl(image)} alt={name} /> : null}
                                    </div>
                                    <div className="blog__content__item__content">
                                        <div className="blog__content__item__content__up">
                                            <div className="blog__content__item__content__up__categories">
                                                {_.map(article.categories, category => {
                                                    if (!_.has(categories, category)) {
                                                        return '';
                                                    }

                                                    return getText(categories[category].name);
                                                }).join(' / ')}
                                            </div>
                                            <div className="blog__content__item__content__up__name">{name}</div>
                                            <div className="blog__content__item__content__up__text">{getText(short_text)}...</div>
                                        </div>
                                        <div className="blog__content__item__content__button">
                                            <ArrowBorderIcon strokeWidth={1} />
                                            <span>{__('Prečítať článok')}</span>
                                        </div>
                                    </div>
                                </ButtonBase>
                            );
                        });
                    })}
                </div>
                {(page < chunks.length && onNextPage !== null) || showLoad ? <ButtonBase
                    onClick={loadCallback !== null ? () => loadCallback() : () => this.onNextPage()}
                    className="blog__button"
                >{loadText ? loadText : __('Viac článkov')}</ButtonBase> : null}
            </div>
        );
    }
}

export { Blog };
