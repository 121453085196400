import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import InfoIcon from '@mui/icons-material/Info';
import MinusIcon from '@mui/icons-material/Remove';
import PlusIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { ButtonBase, Tooltip } from '@mui/material';
import { Navigate } from './Navigate';
import { __, getText, getImageUrl, getProductPrice, formatAmount, getPrice } from '../functions';
import { changeFavoritesProductCount, deleteFavoritesProduct, addCartProduct } from '../actions';
import '../assets/styles/favorites.css';

/**
 * Favorites.
 */
class Favorites extends Navigate {

    /**
     * Zmenime pocet kusov produktu.
     *
     * @param {number} id
     * @param {number} count
     */
    changeProductCount(id, count) {
        const { changeFavoritesProductCount } = this.props;

        changeFavoritesProductCount(this, id, Math.max(count, 1));
    }

    /**
     * Zmazeme produkt.
     *
     * @param {number} id
     */
    deleteProduct(id) {
        const { deleteFavoritesProduct } = this.props;

        deleteFavoritesProduct(this, id);
    }

    /**
     * Presun do kosika.
     */
    toCart() {
        const { favorites, addCartProduct, closeFavorites, showCart } = this.props;

        _.forEach(favorites.items, ({ id, count, settings }, key) => {
            // Najprv zmazeme
            this.deleteProduct(key);

            // Pridame do kosika
            addCartProduct(this, id, count, settings);
        });

        // Zavrieme oblubene
        closeFavorites();

        // Zobrazime kosik
        showCart();
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { eshop, favorites } = this.props;

        // Celkova suma
        let total = 0;

        return (
            <div className="favorites-content">
                <div className="favorites-content__products">
                    {_.map(favorites.items, ({ id, count, settings }, key) => {
                        if (!_.has(eshop.products, id)) {
                            return null;
                        }

                        // Vytiahneme produkt
                        const product = eshop.products[id];
                        const name = getText(product.name);

                        let extensionsPrice = 0;

                        // Prejdeme rozsirenia
                        _.each(settings.extensions, id => {
                            if (_.has(eshop.extensions, id)) {
                                extensionsPrice += eshop.extensions[id].price;
                            }
                        });

                        // Ma extensions?
                        const hasExtensions = !_.isEmpty(settings.extensions);

                        let price = (getProductPrice(product.price, count, product.prices) + extensionsPrice);
                        price *= count;
                        total += price;

                        return (
                            <div className="favorites-content__products__product" key={key}>
                                <div className={`favorites-content__products__product__up ${hasExtensions ? 'with-extensions' : ''}`}>
                                    <div className="favorites-content__products__product__up__left">
                                        <ButtonBase
                                            onClick={() => this.deleteProduct(key)}
                                        >
                                            <DeleteIcon />
                                        </ButtonBase>
                                        <div className="favorites-content__products__product__up__left__photo">
                                            {product.image !== '' ? <img src={getImageUrl(product.image)} alt={getText(product.name)} /> : null}
                                        </div>
                                    </div>
                                    <div className="favorites-content__products__product__up__right">
                                        <div className="favorites-content__products__product__up__right__info">
                                            <div className="favorites-content__products__product__up__right__info__name">
                                                {`${name}${_.has(settings, 'variant') && settings.variant > 0 && _.has(eshop.variants, settings.variant) ? ` - ${getText(eshop.variants[settings.variant].name)}` : ''}`}
                                            </div>
                                        </div>
                                        <div className="favorites-content__products__product__up__right__counter">
                                            <ButtonBase
                                                onClick={() => this.changeProductCount(key, count - 1)}
                                                className="favorites-content__products__product__up__right__counter__button"
                                            >
                                                <MinusIcon />
                                            </ButtonBase>
                                            <div className="favorites-content__products__product__up__right__counter__value">{count}</div>
                                            <ButtonBase
                                                onClick={() => this.changeProductCount(key, count + 1)}
                                                className="favorites-content__products__product__up__right__counter__button"
                                            >
                                                <PlusIcon />
                                            </ButtonBase>
                                        </div>
                                        <div className="favorites-content__products__product__up__right__price">{formatAmount(getPrice(price))}</div>
                                    </div>
                                </div>
                                {hasExtensions ? <div className="favorites-content__products__product__extensions">
                                    {_.map(settings.extensions, id => {
                                        if (!_.has(eshop.extensions, id)) {
                                            return null;
                                        }

                                        let title = '';

                                        return (
                                            <div className="favorites-content__products__product__extensions__extension" key={id}>
                                                <div className="favorites-content__products__product__extensions__extension__name">
                                                    {getText(eshop.extensions[id].name)}
                                                    {title !== ''
                                                        ? <Tooltip title={title}><InfoIcon /></Tooltip>
                                                        : null}
                                                </div>
                                                <div className="favorites-content__products__product__extensions__extension__price">
                                                    {formatAmount(getPrice(eshop.extensions[id].price))}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div> : null}
                            </div>
                        );
                    })}
                </div>
                <div className="favorites-content__down">
                    <div className="favorites-content__down__price">
                        <div className="favorites-content__down__price__text">{__('Celkom za tovar')}</div>
                        <div className="favorites-content__down__price__amount">{formatAmount(getPrice(total))}</div>
                    </div>
                    <ButtonBase
                        onClick={() => this.toCart()}
                        className="favorites-content__down__button"
                        disabled={_.values(favorites.items).length === 0}
                    >{__('Presunúť do košíka')}</ButtonBase>
                </div>
            </div>
        );
    }
}

const stateToProps = ({ eshop, settings, favorites }) => ({ eshop, settings, favorites });

export default withCookies(connect(stateToProps, { changeFavoritesProductCount, deleteFavoritesProduct, addCartProduct })(Favorites));
