import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { ButtonBase } from '@mui/material';
import { Screen, Sections, PopularProducts, Comments } from '../components';
import { __, getText, getImageUrl, formatDate } from '../functions';
import { fetchEshop, fetchSettings, fetchCart, fetchBlog, fetchLists, fetchCompares, fetchFavorites } from '../actions';
import '../assets/styles/article.css';

/**
 * Clanok.
 */
class ArticleScreen extends Screen {
    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        article: {},
    };

    article = null;

    /**
     * Komponenta bola pripojena.
     */
    async componentDidMount() {
        await super.componentDidMount();

        const { fetchBlog } = this.props;

        if (_.isEmpty(this.props.blog)) {
            // Nacitame blog
            await fetchBlog(this);
        }

        const { blog, params } = this.props;

        // Najdeme clanok
        const article = _.find(blog.items, ({ url }) => getText(url) === params.url);

        if (_.isEmpty(article)) {
            // Nenasli sme clanok
            this.redirect('/blog');
            return;
        }

        this.article = article;

        // Nasetujeme title
        this.setTitle(getText(article.name));

        // Nastavime meta
        this.setMeta();

        this.setState({ article });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    renderScreen() {
        const { eshop, blog, navigate } = this.props;
        const { article } = this.state;

        if (_.isEmpty(article)) {
            return this.renderLoading();
        }

        const name = getText(article.name);
        const userName = _.has(eshop.users, article.user_id) ? eshop.users[article.user_id].name : '';

        // Vytiahneme posledne 4 clanky
        const lastArticles = _.slice(_.toArray(blog.items), 0, 4);

        const authorContent = (
            <div className="article__left__content__author">
                <div className="article__left__content__author__photo">
                    {_.has(eshop.users, article.user_id)
                        ? <img src={getImageUrl(eshop.users[article.user_id].image)} alt={userName} />
                        : null}
                </div>
                <div className="article__left__content__author__name">
                    <div>{__('Napísal')}:</div>
                    <div>{userName}:</div>
                    <div>{formatDate(article.created)}</div>
                </div>
            </div>
        );

        return (
            <div>
                <Sections links={{
                    '/': __('Domáca stránka'),
                    '/blog': __('Magazín'),
                    '': name,
                }} />
                <div className="article">
                    <div className="article__left">
                        <h1 className="article__left__title">{name}</h1>
                        <div className="article__left__photo">
                            {article.image !== '' ? <img src={getImageUrl(article.image)} alt={getText(article.name)} /> : null}
                        </div>
                        <div className="article__left__content">
                            {!this.isMobile() ? authorContent : null}
                            <div className="article__left__content__data">
                                <div
                                    className="article__left__content__data__text"
                                    dangerouslySetInnerHTML={{ __html: getText(article.text) }}
                                />
                                {this.isMobile() ? authorContent : null}
                                <Comments
                                    title={name}
                                    eshop={eshop}
                                    parentType="blog"
                                    parentId={article.id}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="article__right">
                        <div className="article__right__title">{__('Posledné články')}</div>
                        {_.map(lastArticles, ({ id, url, categories, name, created }) => {
                            name = getText(name);

                            return (
                                <ButtonBase
                                    onClick={() => this.redirect(`/${getText(url)}`)}
                                    className="article__right__article"
                                    key={id}
                                >
                                    <div className="article__right__article__categories">
                                        {_.map(categories, category => {
                                            if (!_.has(blog.categories, category)) {
                                                return '';
                                            }

                                            return getText(blog.categories[category].name);
                                        }).join(' / ')}
                                    </div>
                                    <div className="article__right__article__name">{name}</div>
                                    <div className="article__right__article__date">{formatDate(created)}</div>
                                </ButtonBase>
                            );
                        })}
                        <PopularProducts eshop={eshop} navigate={navigate} />
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        return super.render();
    }
}

const stateToProps = ({ eshop, settings, blog }) => ({ eshop, settings, blog });

export default withCookies(connect(stateToProps, {
    fetchEshop,
    fetchSettings,
    fetchCart,
    fetchBlog,
    fetchFavorites,
    fetchLists,
    fetchCompares,
})(ArticleScreen));
