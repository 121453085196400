import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { ButtonBase } from '@mui/material';
import { Screen, Input, Checkbox } from '../components';
import { __, getUrlPath, request } from '../functions';
import { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists } from '../actions';
import { COOKIE_SETTINGS, COOKIE_TOKEN_NAME, IMAGES } from '../config';
import '../assets/styles/login.css';

/**
 * Prihlasenie.
 */
class LoginScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Prihlásenie');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        loading: false,
        email: '',
        password: '',
        rememberMe: false,
    };

    /**
     * Komponenta bola pripojena.
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            if (global.token !== '') {
                window.location = getUrlPath('/moj-ucet');
            }
        }
    }

    /**
     * Event po zmene emailu.
     *
     * @param {string} email
     */
    onChangeEmail(email) {
        this.setState({ email });
    }

    /**
     * Event po zmene hesla.
     *
     * @param {string} password
     */
    onChangePassword(password) {
        this.setState({ password });
    }

    /**
     * Event po zmene zapamatat si ma.
     *
     * @param {boolean} rememberMe
     */
    onChangeRememberMe(rememberMe) {
        this.setState({ rememberMe });
    }

    /**
     * Prihlasenie.
     */
    login() {
        const { email, password } = this.state;

        this.setState({ loading: true });

        request('/users/login', { email, password }, 'POST').then(response => {
            const { status, data } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                // Prihlasenie sa nepodarilo
                this.showSnackbar('error', __('Nesprávné prihlasovacie údaje'));
                return;
            }

            const { cookies } = this.props;

            // Ulozime token do cookie
            cookies.set(COOKIE_TOKEN_NAME, data.token, COOKIE_SETTINGS);

            window.location = window.location.search.indexOf('admin=1') !== -1 ? '/admin' : getUrlPath('/moj-ucet');
        });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    renderScreen() {
        const { loading, email, password, rememberMe } = this.state;

        return (
            <div className="login">
                <div className="login__panel">
                    <div className="login__panel__up">
                        <h1 className="login__panel__up__title">{__('Prihlásenie')}</h1>
                        <div className="login__panel__up__text">{__('Prihláste sa k svojmu účtu')}.</div>
                        <Input
                            label={__('Prihlasovací email')}
                            value={email}
                            onChange={value => this.onChangeEmail(value)}
                            type="email"
                        />
                        <Input
                            label={__('Heslo')}
                            value={password}
                            onChange={value => this.onChangePassword(value)}
                            type="password"
                        />
                        <div className="login__panel__up__lost-password">
                            <ButtonBase
                                onClick={() => this.redirect('/moj-ucet/zabudnute-heslo')}
                                className="login__panel__up__lost-password__button"
                            >{__('Zabudnuté heslo')}</ButtonBase>
                        </div>
                        <div className="login__panel__up__buttons">
                            <Checkbox
                                label={__('Zapamätať si ma')}
                                value={rememberMe}
                                onChange={value => this.onChangeRememberMe(value)}
                            />
                            <ButtonBase
                                onClick={() => this.login()}
                                className="login__panel__up__buttons__button"
                            >{loading ? this.renderButtonLoading() : __('Prihlásiť sa')}</ButtonBase>
                        </div>
                    </div>
                    <div className="login__panel__down">
                        {__('Nemáte účet?')}
                        <ButtonBase onClick={() => this.redirect('/registracia')}>{__('Zaregistrujte sa')}</ButtonBase>
                    </div>
                </div>
                <div className="login__photo">
                    <img src={IMAGES['login.png']} alt="prihlaseni" />
                </div>
                {this.renderSnackbar()}
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        return super.render();
    }
}

const stateToProps = ({ eshop, settings }) => ({ eshop, settings });

export default withCookies(connect(stateToProps, { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists })(LoginScreen));
