import React from 'react';
import _ from 'lodash';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ButtonBase, Tooltip } from '@mui/material';
import { Navigate } from './Navigate';
import {
    __,
    getImageUrl,
    getText,
    formatAmount,
    getPrice,
    toNumber,
    toFixed,
    getProductStockStatus,
    getProductStockTitle,
} from '../functions';
import '../assets/styles/products-list.css';
import 'swiper/css';

/**
 * Produkty.
 */
class ProductsList extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        screen: null,
        eshop: {},
        items: [],
        page: 0,
        perPage: 0,
        onNextPage: null,
        showLoad: false,
        loadText: '',
        loadCallback: null,
        swiper: false,
    };

    /**
     * Event po zmene na dalsiu stranu.
     */
    onNextPage() {
        const { onNextPage } = this.props;

        // Zavolame callback
        onNextPage();
    }

    /**
     * Rendrujeme item.
     *
     * @param {Object} item
     *
     * @return {JSX.Element}
     */
    renderItem({ id, image, url, name, price, variables, variants }) {
        const { eshop } = this.props;

        name = getText(name);

        const allvariants = _.reduce(variants, (result, { id }) => { return result + id; }, 0);

        return (
            <ButtonBase
                onClick={() => this.redirect(`/obchod/${getText(url)}`)}
                className="products-list__content__item"
                key={id}
            >
                {image !== '' ? <img src={getImageUrl(image)} alt={name} /> : null}
                <div className="products-list__content__item__info">
                    <div className="products-list__content__item__info__left">
                        <div className="products-list__content__item__info__left__name">{name}</div>
                        <div className="products-list__content__item__info__left__variables">
                            {_.map(_.slice(variables, 0, 3), variable_id => {
                                if (!_.has(eshop.variables, variable_id)) {
                                    return '';
                                }

                                return getText(eshop.variables[variable_id].name);
                            }).join(' / ')}
                        </div>
                    </div>
                    <div className="products-list__content__item__info__right">
                        <div className="products-list__content__item__info__right__price">
                            {formatAmount(getPrice(price))}
                        </div>
                        <div className="products-list__content__item__info__right__price-vat">
                            {`${formatAmount(getPrice(toFixed(price * (100 / (100 + toNumber(20))))))} ${__('bez DPH')}`}
                        </div>
                    </div>
                </div>
                <div className="products-list__content__item__flags">
                    <div className="products-list__content__item__flags__flag" style={{ backgroundColor: '#c24646' }}>-20%</div>
                    <div className="products-list__content__item__flags__flag" style={{ backgroundColor: '#f39a33' }}>Výpredaj</div>
                </div>
                {allvariants > 0 ? <div className="products-list__content__item__variants">
                    {_.map(variants, ({ id, stock, storage, minus, minus_text }) => {
                        if (!_.has(eshop.variants, id)) {
                            return null;
                        }

                        const name = getText(eshop.variants[id].name);

                        // Vytiahneme status
                        const status = getProductStockStatus(stock, storage, minus);
                        const title = `${__('Veľkosť')} ${name} - ${getProductStockTitle(status, minus, minus_text).toLowerCase()}`;

                        return (
                            <Tooltip
                                title={title}
                                key={id}
                            >
                                <span>
                                    <ButtonBase
                                        onClick={() => {}}
                                        className={`product__right__variants__items__variant ${status}`}
                                        disabled={status === 'unavailable'}
                                    >{name}</ButtonBase>
                                </span>
                            </Tooltip>
                        );
                    })}
                </div> : null}
            </ButtonBase>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { items, page, perPage, onNextPage, showLoad, loadText, loadCallback, swiper } = this.props;

        if (swiper) {
            return (
                <div className="products-list">
                    <div className="products-list__content">
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={2.2/360*window.innerWidth}
                        >
                            {_.map(items, (item, key) => {
                                return (
                                    <SwiperSlide key={key}>{this.renderItem(item)}</SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                </div>
            );
        }

        // Rozdelime produkty na strankovanie
        const chunks = _.chunk(items, perPage);

        return (
            <div className="products-list">
                <div className="products-list__content">
                    {_.map(chunks, (chunk, key) => {
                        if ((key + 1) > page) {
                            // Dalsia stranka
                            return null;
                        }

                        return _.map(chunk, (item => this.renderItem(item)));
                    })}
                </div>
                {(page < chunks.length && onNextPage !== null) || showLoad ? <ButtonBase
                    onClick={loadCallback !== null ? () => loadCallback() : () => this.onNextPage()}
                    className="products-list__load"
                >{loadText ? loadText : __('Viac')}</ButtonBase> : null}
            </div>
        );
    }
}

export { ProductsList };
