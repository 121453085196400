import { FETCH_BLOG } from '../actions/types';

const BLOG_DEFAULT_STATE = [];

/**
 * Reducer pre blog.
 */
const BlogReducer = (state = BLOG_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_BLOG:
            // Nacitanie blogu
            return payload;

        default:
            return state;
    }
};

export default BlogReducer;
