import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import '../assets/styles/lightbox.css';

/**
 * Lightbox komponenta.
 */
class Lightbox extends Component {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        open: false,
        title: '',
        text: '',
        content: null,
        agreeText: '',
        closeText: '',
        disableClose: false,
        onAgree: () => {},
        onClose: () => {},
        onExit: () => {},
        closeOnAgree: true,
    };

    /**
     * Default props.
     *
     * @type {{
     *     open: boolean,
     *     title: string,
     * }}
     */
    state = {
        open: false,
        title: '',
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { open, title } = this.props;

        this.setState({ open, title });
    }

    /**
     * Komponenta bude zmenena.
     *
     * @param {object} prevProps
     * @param {object} prevState
     * @param {object} snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open !== this.state.open) {
            // Zmenil sa open v props chceme ho zmenit aj v state
            this.setState({ open: this.props.open });
        } else if (this.props.title !== this.state.title) {
            // Zmenila sa title v props chceme ju zmenit aj v state
            this.setState({ title: this.props.title });
        }
    }

    /**
     * Prijatie lightboxu.
     */
    onAgree() {
        const { closeOnAgree, onAgree } = this.props;

        if (closeOnAgree) {
            this.setState({ open: false });
        }

        // Zavolame callback
        onAgree();
    }

    /**
     * Zatvorenie lightboxu.
     */
    onClose() {
        this.setState({ open: false });

        // Zavolame callback
        this.props.onClose();
    }

    /**
     * Exit lightboxu.
     */
    onExit() {
        this.props.onExit();
    }

    /**
     * Rendrovanie textu.
     *
     * @param {string} text
     *
     * @returns {array}
     */
    renderText(text) {
        return _.map(_.isArray(text) ? text : [text], value => <DialogContentText key={value}>{value}</DialogContentText>);
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { open, title } = this.state;
        const { content, text, agreeText, closeText, disableClose } = this.props;

        return (
            <Dialog
                open={open}
                onClose={this.onClose.bind(this)}
                onBackdropClick={!disableClose ? this.onClose.bind(this) : () => {}}
                PaperProps={{ className: 'lightbox' }}
            >
                {!_.isEmpty(title) ? <DialogTitle style={{ textAlign: 'center' }}>{title}</DialogTitle> : null}
                <DialogContent>{content !== null ? content : this.renderText(text)}</DialogContent>
                <DialogActions>
                    {agreeText ? <Button onClick={this.onAgree.bind(this)} color="primary">{agreeText}</Button> : null}
                    {closeText ? <Button onClick={this.onClose.bind(this)} color="primary">{closeText}</Button> : null}
                </DialogActions>
            </Dialog>
        );
    }
}

export { Lightbox };
