import { FETCH_SETTINGS, CHANGE_SETTING } from '../actions/types';

const SETTINGS_DEFAULT_STATE = {
    showCart: false,
    showFavorites: false,
    showLists: false,
    showCompares: false,
    currency: 0,
    language: 0,
};

/**
 * Reducer pre nastavenia.
 */
const SettingsReducer = (state = SETTINGS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_SETTINGS:
            // Nacitanie settingov
            return { ...state, ...payload };

        case CHANGE_SETTING:
            // Zmena nastavenia
            return { ...state, [payload.type]: payload.value };

        default:
            return state;
    }
};

export default SettingsReducer;
