import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { ButtonBase } from '@mui/material';
import ArrowIcon from '@mui/icons-material/KeyboardArrowRight';
import { Navigate } from './Navigate';
import {__, getText, getUrlPath} from '../functions';
import { IMAGES } from '../config';
import '../assets/styles/footer.css';

/**
 * Footer.
 */
class Footer extends Navigate {
    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        showMain: false,
        showInfo: false,
        lightbox: {
            popup: false,
        },
    };

    /**
     * Zobrazime popup.
     *
     * @param {string} name
     */
    showPopup(name) {
        const { eshop } = this.props;

        this.showLightbox('popup', { data: getText(eshop.popups[name].text) });
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { eshop } = this.props;
        const { showMain, showInfo, lightbox } = this.state;

        if (_.isEmpty(eshop)) {
            // Nie su nacitane data
            return null;
        }

        return (
            <div className={`footer ${this.getPath() === '/pokladna' ? 'footer-checkout' : ''}`}>
                <div className="footer__content">
                    <div className="footer__content__main">
                        <div className="footer__content__main__panel">
                            <img
                                onClick={() => window.location = '/'}
                                className="footer__content__main__panel__logo"
                                src={IMAGES['logo-white.png']}
                                alt="logo"
                            />
                            <div className="footer__content__main__panel__logo-text">Lorem ipsum lorem ipsum</div>
                        </div>
                        <div className={`footer__content__main__panel ${showMain ? 'show' : ''}`}>
                            <div
                                onClick={() => this.setState({ showMain: !showMain })}
                                className="footer__content__main__panel__section"
                            >
                                {__('O Primework')}
                                {this.isMobile() ? <ArrowIcon /> : null}
                            </div>
                            <Link to={getUrlPath('/')}>{__('Podstránka 1')}</Link>
                            <Link to={getUrlPath('/')}>{__('Podstránka 2')}</Link>
                            <Link to={getUrlPath('/')}>{__('Podstránka 3')}</Link>
                            <Link to={getUrlPath('/')}>{__('Podstránka 4')}</Link>
                            <Link to={getUrlPath('/')}>{__('Podstránka 5')}</Link>
                            <Link to={getUrlPath('/predajne')}>{__('Predajne')}</Link>
                        </div>
                        <div className={`footer__content__main__panel ${showInfo ? 'show' : ''}`}>
                            <div
                                onClick={() => this.setState({ showInfo: !showInfo })}
                                className="footer__content__main__panel__section"
                            >
                                {__('Informácie k nákupu')}
                                {this.isMobile() ? <ArrowIcon /> : null}
                            </div>
                            <Link to={getUrlPath('/doprava-platba')}>{__('Doprava a platba')}</Link>
                            <ButtonBase onClick={() => this.showPopup('vop')}>{__('Obchodné podmienky')}</ButtonBase>
                            <ButtonBase onClick={() => this.showPopup('reklamace')}>{__('Reklamačné podmienky')}</ButtonBase>
                            <ButtonBase onClick={() => this.showPopup('privacy')}>{__('Spracovanie osobnych udajov')}</ButtonBase>
                        </div>
                        <div className="footer__content__main__panel">
                            <div className="footer__content__main__panel__section">{__('Kontakt')}</div>
                            <Link to="/">{eshop.settings.email}</Link>
                            <Link to="/">{eshop.settings.phone}</Link>
                        </div>
                        <div className="footer__content__main__panel">
                            <a href="https://www.tiktok.com/" target="_blank">
                                <img src={IMAGES['tiktok.png']} alt="tiktok" />
                            </a>
                            <a href="https://www.instagram.com/" target="_blank">
                                <img src={IMAGES['instagram.png']} alt="instagram" />
                            </a>
                            <a href="https://www.facebook.com/" target="_blank">
                                <img src={IMAGES['facebook.png']} alt="facebook" />
                            </a>
                            <a href="https://twitter.com/" target="_blank">
                                <img src={IMAGES['twitter.png']} alt="twitter" />
                            </a>
                            <a href="https://sk.pinterest.com/" target="_blank">
                                <img src={IMAGES['pinterest.png']} alt="pinterest" />
                            </a>
                        </div>
                    </div>
                    <div className="footer__content__down">
                        <div className="footer__content__down__left">
                            <img src={IMAGES['visa.png']} alt="visa" />
                            <img src={IMAGES['mastercard.png']} alt="mastercard" />
                            <img src={IMAGES['gls.png']} alt="gls" />
                        </div>
                        <div className="footer__content__down__right">© 2022 Primework. All rights reserved</div>
                    </div>
                </div>
                {this.renderLightbox('popup', '', !_.isEmpty(lightbox.popup)
                    ? <div dangerouslySetInnerHTML={{ __html: lightbox.popup.data }} />
                    : null, '', __('Zrušit'))}
            </div>
        );
    }
}

const stateToProps = ({ eshop, settings }) => ({ eshop, settings });

export default withCookies(connect(stateToProps)(Footer));
