/**
 * Vratime zoznam assetov.
 *
 * @param {string} path
 */
export const getAssets = path => {
    let images = {};
    path.keys().forEach((item, index) => { images[item.replace('./', '')] = path(item) });
    return images;
};
