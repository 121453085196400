import _ from 'lodash';
import {
    FETCH_CART,
    CLEAN_CART,
    ADD_CART_PRODUCT,
    DELETE_CART_PRODUCT,
    CHANGE_CART_PRODUCT_COUNT,
    CHANGE_CART_DELIVERY_ID,
    CHANGE_CART_PAYMENT_ID,
    SET_CART_DISCOUNT,
    DELETE_CART_DISCOUNT,
    CHANGE_CART_VAT,
} from './types';
import { COOKIE_CART_NAME, COOKIE_SETTINGS } from '../config';

/**
 * Nacitanie kosika.
 *
 * @param {Screen} screen
 */
export const fetchCart = (screen) => {
    let cart = screen.props.cookies.get(COOKIE_CART_NAME, COOKIE_SETTINGS);
    cart = !_.isUndefined(cart) ? cart : {};

    return dispatch => dispatch({ type: FETCH_CART, payload: cart });
};

/**
 * Vycistenie kosika.
 */
export const cleanCart = () => dispatch => dispatch({ type: CLEAN_CART });

/**
 * Pridame produkt do kosika.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {number} count
 * @param {Object} settings
 */
export const addCartProduct = (screen, id, count, settings = {}) => {
    global.screen = screen;

    return dispatch => dispatch({ type: ADD_CART_PRODUCT, payload: { id, count, settings } });
};

/**
 * Odstranime produkt z kosika.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteCartProduct = (screen, id) => {
    global.screen = screen;

    return dispatch => dispatch({ type: DELETE_CART_PRODUCT, payload: id });
};

/**
 * Upravime pocet produktu v kosiku.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {number} count
 */
export const changeCartProductCount = (screen, id, count) => {
    global.screen = screen;

    return dispatch => dispatch({ type: CHANGE_CART_PRODUCT_COUNT, payload: { id, count } });
};

/**
 * Upravime dodanie v kosiku.
 *
 * @param {number} id
 */
export const changeCartDeliveryId = id => {
    return dispatch => dispatch({ type: CHANGE_CART_DELIVERY_ID, payload: id });
};

/**
 * Upravime platbu v kosiku.
 *
 * @param {number} id
 */
export const changeCartPaymentId = id => {
    return dispatch => dispatch({ type: CHANGE_CART_PAYMENT_ID, payload: id });
};

/**
 * Upravime DPH v kosiku.
 *
 * @param {boolean} vat
 */
export const changeCartVat = vat => {
    return dispatch => dispatch({ type: CHANGE_CART_VAT, payload: vat });
};

/**
 * Nastavime zlavu v kosiku.
 *
 * @param {string} type
 * @param {int} value
 */
export const setCartDiscount = (type, value) => {
    return dispatch => dispatch({ type: SET_CART_DISCOUNT, payload: { type, value } });
};

/**
 * Zrusime zlavu v kosiku.
 */
export const deleteCartDiscount = () => dispatch => dispatch({ type: DELETE_CART_DISCOUNT });
