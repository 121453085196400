import axios from 'axios';
import _ from 'lodash';
import { getUrl } from './getUrl';

/**
 * GET/POST request.
 *
 * @param {string} uri
 * @param {Object} data
 * @param {string} method
 * @param {Object} params
 */
export const request = (uri, data = {}, method = 'GET', params = {}) => {
    let headers = {
        'Accept': 'application/json',
        'Content-Type': method === 'FILE'
            ? 'multipart/form-data'
            : 'application/json; charset=UTF-8',
    };

    if (global.token !== '') {
        headers = { ...headers, Authorization: global.token };
    }

    params = { ...params, language: global.language };

    // Nasetujeme config pre axios
    const config = {
        method: method === 'FILE' ? 'POST' : method,
        url: `${getUrl()}${uri}`,
        headers,
    };

    switch (method) {
        case 'GET':
            // Pribalime GET parametre
            params = { ...params, ...data };
            break;

        case 'POST':
            // Mame form data ide o klasicky request
            config.data = JSON.stringify(data);
            break;

        case 'FILE':
            const fileData = new FormData();
            fileData.append('file', data, data.name);

            config.data = fileData;
            break;

        default:
            break;
    }

    params = _.join(_.map(_.keys(params), key => `${key}=${encodeURIComponent(params[key])}`), '&');

    config.url = `${config.url}?${params}`;

    return axios(config);
};
