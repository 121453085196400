import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import PhoneIcon from '@mui/icons-material/PhoneOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import { Screen, Sections } from '../components';
import { __ } from '../functions';
import { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists } from '../actions';
import '../assets/styles/contact.css';

/**
 * Contact.
 */
class ContactScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Kontakt');

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    renderScreen() {
        const { eshop } = this.props;

        return (
            <div>
                <Sections />
                <div className="contact">
                    <div className="contact__header">
                        <div className="contact__header__map">
                            <iframe
                                loading="lazy"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1329.2512606179016!2d18.60966839672722!3d48.21619741869347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476adc50ddc8ffed%3A0x6b963af24a5bd00a!2s%C4%8Cervenej%20arm%C3%A1dy%204%2C%20934%2005%20Levice!5e0!3m2!1ssk!2ssk!4v1609862170177!5m2!1ssk!2ssk"
                                height="300"
                                frameBorder="0"
                                allowFullScreen=""
                                data-rocket-lazyload="fitvidscompatible"
                                className="lazyloaded"
                                data-ll-status="loaded"
                            />
                        </div>
                        <div className="contact__header__content">
                            <div className="contact__header__content__up">
                                <h1 className="contact__header__content__up__title">{__('Kontakt')}</h1>
                                <div className="contact__header__content__up__text"
                                     dangerouslySetInnerHTML={{ __html: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ac convallis felis. Vestibulum ut dui efficitur, vehicula diam a, posuere ligula. Donec auctor euismod aliquam.' }}
                                />
                                <div className="contact__header__content__up__phone">
                                    <PhoneIcon />
                                    <span>{eshop.settings.phone}</span>
                                </div>
                                <div className="contact__header__content__up__email">
                                    <EmailIcon />
                                    <span>{eshop.settings.email}</span>
                                </div>
                            </div>
                            <div className="contact__header__content__down">
                                <div className="contact__header__content__down__panel">
                                    <h2 className="contact__header__content__down__panel__title">{__('Kancelária')}</h2>
                                    <div className="contact__header__content__down__panel__text">
                                        <span>{eshop.settings.company_name}</span>
                                        <span>{eshop.settings.address}</span>
                                        <span>{eshop.settings.zip} {eshop.settings.city}</span>
                                        <span className="mini">{eshop.settings.spis_znacka}</span>
                                    </div>
                                </div>
                                <div className="contact__header__content__down__panel">
                                    <h2 className="contact__header__content__down__panel__title">{__('Fakturačné údaje')}</h2>
                                    <div className="contact__header__content__down__panel__text">
                                        <span>{eshop.settings.company_name}</span>
                                        <span>{eshop.settings.invoice_address}</span>
                                        <span>{eshop.settings.zip} {eshop.settings.city}</span>
                                        <span>{__('IČO')}: {eshop.settings.ico}</span>
                                        <span>{__('DIČ')}: {eshop.settings.dic}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contact__persons">
                        <div className="contact__persons__person">
                            <div className="contact__persons__person__text">
                                {__('Všetky otázky, pripomienky a pochvaly môžete písať na')}
                                <span>{eshop.settings.email}</span>
                            </div>
                        </div>
                        <div className="contact__persons__person">
                            <div className="contact__persons__person__photo">
                            </div>
                            <div className="contact__persons__person__title">{__('Majiteľ')}</div>
                            <div className="contact__persons__person__name">Lorem ipsum</div>
                            <div className="contact__persons__person__email">
                                <EmailIcon />
                                <span>{eshop.settings.email}</span>
                            </div>
                            <div className="contact__persons__person__phone">
                                <PhoneIcon />
                                <span>{eshop.settings.phone}</span>
                            </div>
                        </div>
                        <div className="contact__persons__person">
                            <div className="contact__persons__person__photo">
                            </div>
                            <div className="contact__persons__person__title">{__('Majiteľ')}</div>
                            <div className="contact__persons__person__name">Lorem ipsum</div>
                            <div className="contact__persons__person__email">
                                <EmailIcon />
                                <span>{eshop.settings.email}</span>
                            </div>
                            <div className="contact__persons__person__phone">
                                <PhoneIcon />
                                <span>{eshop.settings.phone}</span>
                            </div>
                        </div>
                        <div className="contact__persons__person">
                            <div className="contact__persons__person__photo">
                            </div>
                            <div className="contact__persons__person__title">{__('Majiteľ')}</div>
                            <div className="contact__persons__person__name">Lorem ipsum</div>
                            <div className="contact__persons__person__email">
                                <EmailIcon />
                                <span>{eshop.settings.email}</span>
                            </div>
                            <div className="contact__persons__person__phone">
                                <PhoneIcon />
                                <span>{eshop.settings.phone}</span>
                            </div>
                        </div>
                        <div className="contact__persons__person">
                            <div className="contact__persons__person__photo">
                            </div>
                            <div className="contact__persons__person__title">{__('Majiteľ')}</div>
                            <div className="contact__persons__person__name">Lorem ipsum</div>
                            <div className="contact__persons__person__email">
                                <EmailIcon />
                                <span>{eshop.settings.email}</span>
                            </div>
                            <div className="contact__persons__person__phone">
                                <PhoneIcon />
                                <span>{eshop.settings.phone}</span>
                            </div>
                        </div>
                        <div className="contact__persons__person">
                            <div className="contact__persons__person__photo">
                            </div>
                            <div className="contact__persons__person__title">{__('Majiteľ')}</div>
                            <div className="contact__persons__person__name">Lorem ipsum</div>
                            <div className="contact__persons__person__email">
                                <EmailIcon />
                                <span>{eshop.settings.email}</span>
                            </div>
                            <div className="contact__persons__person__phone">
                                <PhoneIcon />
                                <span>{eshop.settings.phone}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        return super.render();
    }
}

const stateToProps = ({ eshop, settings }) => ({ eshop, settings });

export default withCookies(connect(stateToProps, { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists })(ContactScreen));
