import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import PrivacyIcon from '@mui/icons-material/Security';
import OrdersIcon from '@mui/icons-material/ViewList';
import LogoutIcon from '@mui/icons-material/Logout';
import CreditLineIcon from '@mui/icons-material/AttachMoney';
import DebetLineIcon from '@mui/icons-material/AttachMoney';
import ReviewsIcon from '@mui/icons-material/Star';
import CreditIcon from '@mui/icons-material/Paid';
import AccountIcon from '@mui/icons-material/PersonOutline';
import CopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import ErrorIcon from '@mui/icons-material/Close';
import PlusIcon from '@mui/icons-material/Add';
import ArrowIcon from '@mui/icons-material/KeyboardArrowRight';
import ValidIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/PriorityHigh';
import ExtensionsInfoIcon from '@mui/icons-material/InfoOutlined';
import { ButtonBase, CircularProgress, Rating, Tooltip } from '@mui/material';
import GoogleAutocomplete from '../components/GoogleAutocomplete';
import { Checkbox, Input, Screen, Select, ChangeDelivery } from '../components';
import {
    __,
    formatPhone,
    getText,
    isEmptyString,
    isValidEmail,
    request,
    getCheckoutErrors,
    formatDate,
    formatAmount,
    toNumber,
    getImageUrl,
    getProductStockStatus,
    getProductStockTitle,
    toFixed,
    round,
    getPrice,
    getUrlPath,
} from '../functions';
import { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchLists, fetchCompares } from '../actions';
import '../assets/styles/account.css';

/**
 * Moj ucet.
 */
class AccountScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Môj účet');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        loading: false,
        section: 'settings',
        orderId: null,
        data: {},
        settings: {
            company: false,
            company_name: '',
            ico: '',
            dic: '',
            ic_dph: '',
            name: '',
            address: '',
            address_additional: '',
            city: '',
            zip: '',
            country: 'sk',
            phone_prefix: 'sk',
            phone: '',
            email: '',
            comment: '',
            delivery: false,
            delivery_company_name: '',
            delivery_name: '',
            delivery_address: '',
            delivery_address_additional: '',
            delivery_city: '',
            delivery_zip: '',
            delivery_country: 'sk',
            delivery_phone_prefix: 'sk',
            delivery_phone: '',
            vies_date: '',
            vies_token: '',
        },
        showReviews: {},
        ico_loading: false,
        dic_loading: false,
        phone_loading: false,
        phone_valid: false,
        delivery_phone_loading: false,
        delivery_phone_valid: false,
        email_valid: false,
        errors: {},
        showAddress: false,
        address: '',
        deliveryAddress: '',
        showDeliveryAddress: false,
        showPassword: false,
        oldPassword: '',
        newPassword: '',
        newPasswordRepeat: '',
        lightbox: {
            popup: false,
            delivery: false,
        },
    };

    /**
     * Komponenta bola pripojena.
     */
    async componentDidMount() {
        if (global.token === '') {
            window.location = getUrlPath('/prihlasenie');
            return;
        }

        await super.componentDidMount();

        let data = {};

        // Nacitame data
        await request('/users/settings').then(response => {
            const { status } = response.data;

            data = response.data.data;
        });

        const { eshop } = this.props;

        let settings = _.has(eshop.countries, eshop.country) ? {
            country: eshop.country,
            phone_prefix: eshop.country,
            delivery_country: eshop.country,
            delivery_phone_prefix: eshop.country,
        } : {};
        let additional = {};

        if (!_.isEmpty(eshop.user)) {
            settings = {
                ...settings,
                ...eshop.user.settings,
            };

            additional = {
                ...additional,
                email_valid: settings.email !== '',
                phone_valid: settings.phone !== '',
                delivery_phone_valid: settings.delivery_phone !== '',
            };

            if (settings.phone !== '') {
                settings = { ...settings, phone: formatPhone(settings.phone) };
            }

            if (settings.delivery_phone !== '') {
                settings = { ...settings, delivery_phone: formatPhone(settings.delivery_phone) };
            }

            if (settings.address !== '') {
                // Mame vyplneny adresu
                additional = { ...additional, address: `${settings.address} ${settings.city} ${settings.zip}, ${eshop.countries[settings.country]}` };
            }

            if (settings.delivery_address !== '') {
                // Mame vyplneny adresu
                additional = { ...additional, deliveryAddress: `${settings.delivery_address} ${settings.delivery_city} ${settings.delivery_zip}, ${eshop.countries[settings.delivery_country]}` };
            }
        }

        this.setState({
            section: this.isMobile() ? null : 'settings',
            data,
            settings: { ...this.state.settings, ...settings },
            ...additional
        });
    }

    /**
     * Zobrazime popup.
     *
     * @param {string} name
     */
    showPopup(name) {
        const { eshop } = this.props;

        this.showLightbox('popup', { data: getText(eshop.popups[name].text) });
    }

    /**
     * Zmena sekcie.
     *
     * @param {string} section
     */
    showSection(section) {
        this.scrollToTop();

        this.setState({ section, orderId: null });
    }

    /**
     * Skontrolujeme telefon.
     *
     * @param {string} field
     * @param {string|null} prefix
     */
    checkPhone(field = 'phone', prefix = null) {
        const { settings, errors } = this.state;

        prefix = prefix !== null ? prefix : settings[`${field}_prefix`];

        this.setState({ [`${field}_loading`]: true });

        request(`/eshops/validPhone/${prefix}/${settings[field]}`).then(response => {
            const { status } = response.data;

            this.setState({
                [`${field}_loading`]: false,
                [`${field}_valid`]: status === 'success',
                errors: status === 'error' ? { ...errors, [field]: __('Neplatné tel. číslo') } : _.without(errors, field),
            });
        });
    }

    /**
     * Checkujeme email.
     */
    checkEmail() {
        const { settings, errors } = this.state;

        const valid = isValidEmail(settings.email);

        this.setState({
            email_valid: valid,
            errors: !valid ? { ...errors, email: __('Neplatný email') } : _.without(errors, 'email'),
        });
    }

    /**
     * Nacitame data z Aresu.
     */
    loadAres() {
        const { eshop } = this.props;
        const { settings } = this.state;

        if (settings.ico === '') {
            return;
        }

        this.setState({ ico_loading: true });

        request(`/eshops/ares/${settings.ico}/${settings.country}`).then(response => {
            const { status } = response.data;

            if (status !== 'success') {
                this.setState({ ico_loading: false });
                this.showSnackbar('error', __('Nepodarilo sa načítať údaje z registra'));
                return;
            }

            if (!_.isEmpty(response.data.data.dic)) {
                // Mame dic, overime cez vies
                this.loadVies(response.data.data.dic);
            }

            // Cela adresa
            const fullAddress = !_.isEmpty(response.data.data.address)
                ? `${response.data.data.address} ${response.data.data.city} ${response.data.data.zip}, ${eshop.countries[settings.country]}`
                : '';

            this.setState({ ico_loading: false, address: fullAddress, settings: {
                ...settings,
                company_name: response.data.data.name,
                delivery_company_name: response.data.data.name,
                dic: response.data.data.dic,
                address: response.data.data.address,
                city: response.data.data.city,
                zip: response.data.data.zip,
            } });
        });
    }

    /**
     * Nacitame data z Vies.
     *
     * @param {string|null} dic
     * @param {string|null} country
     */
    loadVies(dic = null, country = null) {
        const { settings } = this.state;

        dic = dic !== null ? dic : settings.dic;
        country = country !== null ? country : settings.country;

        if (dic === '') {
            return;
        }

        this.setState({ dic_loading: true });

        request(`/eshops/vies/${dic}/${country}`).then(response => {
            const { data } = response.data;

            this.onChangeSetting('vies_token', data.token);
        });
    }

    /**
     * Event po zmene dat.
     *
     * @param {string} type
     * @param {string|number|boolean} value
     */
    onChangeSetting(type, value) {
        const { eshop } = this.props;

        let settings = { ...this.state.settings, [type]: value };

        switch (type) {
            case 'country':
            case 'delivery_country':
            case 'delivery':
            case 'vies_token':
                // Krajina
                // Krajina dodania
                // Nastavime platbu a dodanie
                // Vies token
                if (type === 'country' && settings.dic !== '') {
                    // Menime krajinu je zadane dic, overime
                    this.loadVies(settings.dic, value);
                }

                if (type === 'vies_token') {
                    // Nastavime datum
                    settings = { ...settings, vies_date: eshop.today };
                }

                break;

            case 'phone_prefix':
                if (!isEmptyString(settings.phone)) {
                    this.checkPhone('phone', value);
                }
                break;

            case 'phone':
                // Tel cislo
                settings = { ...settings, phone: formatPhone(value) };
                break;

            case 'delivery_phone':
                // Tel cislo
                settings = { ...settings, delivery_phone: formatPhone(value) };
                break;

            case 'delivery_phone_prefix':
                if (!isEmptyString(settings.delivery_phone)) {
                    this.checkPhone('delivery_phone', value);
                }
                break;
        }

        this.setState({ settings, dic_loading: false });
    }

    /**
     * Event po zmene adresy.
     *
     * @param {string} address
     */
    onChangeAddress(address) {
        this.setState({ address });
    }

    /**
     * Event po zmene dodacej adresy.
     *
     * @param {string} deliveryAddress
     */
    onChangeDeliveryAddress(deliveryAddress) {
        this.setState({ deliveryAddress });
    }

    /**
     * Zobrazime zadanie adresy.
     */
    showAddress() {
        this.setState({ showAddress: true });
    }

    /**
     * Zobrazime dodaciu adresu.
     */
    showDeliveryAddress() {
        this.setState({ showDeliveryAddress: true });
    }

    /**
     * Zobrazime heslo.
     */
    showPassword() {
        this.setState({ showPassword: !this.state.showPassword });
    }

    /**
     * Event po zmene stareho hesla.
     *
     * @param {string} oldPassword
     */
    onChangeOldPassword(oldPassword) {
        this.setState({ oldPassword });
    }

    /**
     * Event po zmene noveho hesla.
     *
     * @param {string} newPassword
     */
    onChangeNewPassword(newPassword) {
        this.setState({ newPassword });
    }

    /**
     * Event po zmene noveho hesla znovu.
     *
     * @param {string} newPasswordRepeat
     */
    onChangeNewPasswordRepeat(newPasswordRepeat) {
        this.setState({ newPasswordRepeat });
    }

    /**
     * Event po zmene miesta.
     *
     * @param {Object} place
     * @param {string} prefix
     */
    onChangePlace(place, prefix = '') {
        const { eshop } = this.props;
        let { settings } = this.state;

        let additional = {};

        const field = prefix === 'delivery_' ? 'deliveryAddress' : 'address';

        if (_.has(place, 'address_components')) {
            let address = '';
            let address_number = '';
            let city = '';
            let zip = '';
            let country = '';

            _.each(place.address_components, ({ long_name, short_name, types }) => {
                if (!_.isEmpty(types)) {
                    switch (types[0]) {
                        case 'street_number':
                            address_number = long_name;
                            break;

                        case 'route':
                            address = long_name;
                            break;

                        case 'locality':
                            city = long_name;
                            break;

                        case 'postal_code':
                            zip = long_name.replace(' ', '');
                            break;

                        case 'country':
                            country = short_name.toLowerCase();
                            break;

                        default:
                            break;
                    }
                }
            });

            if (address_number === '') {
                // Nemame cislo pokusime sa vytiahnut z textu
                const number = /([0-9\/]+[a-zA-z]{0,1})/.exec(this.state[field]);

                if (number.length === 2) {
                    address_number = number[1];
                }
            }

            address = address_number !== '' ? `${address} ${address_number}` : address;

            settings = { ...settings,
                [`${prefix}address`]: address,
                [`${prefix}city`]: city,
                [`${prefix}zip`]: zip,
                [`${prefix}country`]: country,
            };

            // Cela adresa
            const fullAddress = `${address} ${city} ${zip}, ${eshop.countries[country]}`;

            additional = { [field]: fullAddress };
        }

        this.setState({ settings, ...additional });
    }

    /**
     * Ulozime nastavenia.
     */
    saveSettings() {
        const { settings, phone_valid, delivery_phone_valid, oldPassword, newPassword, newPasswordRepeat } = this.state;

        // Zvalidujeme
        let errors = getCheckoutErrors(settings);
        let additional = {};

        if (_.has(errors, 'address') || _.has(errors, 'city') || _.has(errors, 'zip')) {
            additional = { ...additional, showAddress: true };
        }

        if (_.has(errors, 'delivery_address') || _.has(errors, 'delivery_city') || _.has(errors, 'delivery_zip')) {
            additional = { ...additional, showDeliveryAddress: true };
        }

        if (!phone_valid) {
            errors = { ...errors, phone: __('Neplatné tel. číslo') };
        }

        if (settings.delivery && !delivery_phone_valid) {
            errors = { ...errors, delivery_phone: __('Neplatné tel. číslo') };
        }

        if (newPassword !== '' && newPassword.length < 8) {
            // Slabe heslo
            errors = { ...errors, new_password: __('Heslo musí mať minimálne 8 znakov') };
        }

        if (newPassword !== '' && newPassword !== newPasswordRepeat) {
            // Hesla sa nezhoduju
            errors = { ...errors, new_password_repeat: __('Heslá nie sú rovnaké') };
        }

        this.setState({ errors, ...additional });

        if (!_.isEmpty(errors)) {
            // Mame errory
            return;
        }

        // Objedname
        this.setState({ loading: true });

        // Ulozime
        request('/users/saveSettings', {
            settings,
            old_password: oldPassword,
            new_password: newPassword,
            new_password_repeat: newPasswordRepeat,
        }, 'POST').then(response => {
            const { status } = response.data;

            if (status !== 'success') {
                this.showSnackbar('error', __('Nesprávné heslo'));
                return;
            }

            window.location.reload();
        });
    }

    /**
     * Skopirovanie do schranky.
     *
     * @param {string} text
     */
    copy(text) {
        // Nastavime cislo do clipboardu
        navigator.clipboard.writeText(text);

        this.showSnackbar('success', __('Skopírované do schránky'));
    }

    /**
     * Zobrazime objednavku.
     *
     * @param {number} id
     */
    showOrder(id) {
        this.scrollToTop();

        this.setState({ section: 'orders', orderId: id });
    }

    /**
     * Zobrazime recenziu.
     *
     * @param {number} id
     */
    showReview(id) {
        const { showReviews } = this.state;

        if (!_.has(showReviews, id)) {
            this.setState({ showReviews: { ...showReviews, [id]: id } });
            return;
        }

        this.setState({ showReviews: _.omit(showReviews, [ id ]) });
    }

    /**
     * Upload file.
     *
     * @param {number} id
     * @param {Object} file
     */
    uploadPhoto(file) {
        // Ulozime
        request('/users/upload', file, 'FILE').then(response => {
            const { status } = response.data;

            if (status !== 'success') {
                this.showSnackbar('error', __('Nastala chyba'));
                return;
            }

            window.location.reload();
        });
    }

    /**
     * Zmena platobnej metody.
     *
     * @param {string} status
     * @param {Object} order
     */
    changePayment(status, order) {
        this.closeLightbox('delivery');

        if (status === 'error') {
            this.showSnackbar('error', __('Nastala chyba'));
            return;
        }

        const { data } = this.state;

        // Prepiseme objednavku v datach
        const orders = _.map(data.orders, item => {
            if (toNumber(item.id) === toNumber(order.id)) {
                // Prepiseme
                item = order;
            }

            return item;
        });

        this.setState({ data: { ...data, orders } });
    }

    /**
     * Vratime sekciu.
     *
     * @param {JSX.Element} accountPanel
     *
     * @return {JSX.Element}
     */
    renderSection(accountPanel) {
        const { eshop } = this.props;
        const { data, section, orderId } = this.state;

        const backButton = orderId !== null ? (
            <ButtonBase
                onClick={() => this.showSection('orders')}
                className="account-back"
            >
                <ArrowIcon />
                <span>{__('Prehľad objednávok')}</span>
            </ButtonBase>
        ) : (
            <ButtonBase
                onClick={() => this.showSection(null)}
                className="account-back"
            >
                <ArrowIcon />
                <span>{__('Môj účet')}</span>
            </ButtonBase>
        );

        switch (section) {
            case 'orders':
                // Objednavky
                if (orderId !== null) {
                    // Konkretna objednavka
                    const order = _.find(data.orders, ({ id }) => id === orderId);

                    if (!_.isUndefined(order)) {
                        // Platba nastavenie
                        const currency = getText(eshop.currencies[toNumber(order.data.currency)].name);

                        const paymentSetting = _.has(eshop.payments, order.data.payment_id)
                            ? eshop.payments[order.data.payment_id].setting
                            : '';

                        // Je uhradena?
                        const isPaid = order.data.payment_status === 'yes';

                        // Je dobierka?
                        const isCod = paymentSetting === 'cod';

                        // Zobrazujeme prevod?
                        const showTransfer = !isPaid && paymentSetting === 'transfer';

                        let totalProducts = 0;

                        return (
                            <div className="account__content__order">
                                <h1 className="account__content__order__title">{__('Vitaj vo svojom účte')}</h1>
                                {accountPanel}
                                {backButton}
                                <div className="account__content__order__header">
                                    <div className="account__content__order__header__left">
                                        <div className="account__content__order__header__left__text">{__('Objednávka')}</div>
                                        <div className="account__content__order__header__left__number">{order.data.number}</div>
                                    </div>
                                    <div className="account__content__order__header__right">
                                        {order.data.invoice !== '' ? <ButtonBase
                                            onClick={() => window.open(order.data.invoice, '_blank')}
                                        >{__('Faktúra na stiahnutie')}</ButtonBase> : null}
                                    </div>
                                </div>
                                {isCod && !isPaid ? <div className="account__content__order__payment-state">
                                    <div className="account__content__order__payment-state__text">{__('Vaša objednávka čaká na spracovanie. O odoslaní budete informovaní e-mailem. Platbu dobierky vykonajte pri prevzatí balíka.')}</div>
                                </div> : null}
                                {isPaid ? <div className="account__content__order__payment-state success">
                                    <div className="account__content__order__payment-state__icon">
                                        <CheckIcon />
                                    </div>
                                    <div className="account__content__order__payment-state__text">{__('Vaša objednávka je zaplatená.')}</div>
                                </div> : null}
                                {!isPaid && !showTransfer && !isCod ? <div className="account__content__order__payment-state error">
                                    <div className="account__content__order__payment-state__icon">
                                        <ErrorIcon />
                                    </div>
                                    <div className="account__content__order__payment-state__text">{__('Pozor, platba sa nepodarila.')}</div>
                                    <div className="account__content__order__payment-state__button">
                                        <ButtonBase
                                            onClick={() => {}}
                                        >{__('Zaplatiť znovu')}</ButtonBase>
                                        <ButtonBase
                                            onClick={() => this.showLightbox('delivery', {
                                                country: order.data.delivery_address.country,
                                                order_id: order.id,
                                                order_token: order.token,
                                                payment_id: order.data.payment_id,
                                            })}
                                        >{__('Zaplatiť iným spôsobom')}</ButtonBase>
                                    </div>
                                </div> : null}
                                {showTransfer ? <div className="account__content__order__payment-state waiting">
                                    <div className="account__content__order__payment-state__icon">
                                        <InfoIcon />
                                    </div>
                                    <div className="account__content__order__payment-state__text">{__('Vaša objednávka čaká na úhradu')}</div>
                                </div> : null}
                                {showTransfer ? <div className="account__content__order__transfer">
                                    <div className="account__content__order__transfer__qrcode">
                                        <div className="account__content__order__transfer__qrcode__title">{__('Zaplaťte QR kódem')}</div>
                                        <img
                                            src={`https://api.qrfgen.cz/generator/image?ID=${order.data.number}&DD=${formatDate(order.data.date, 'yyyymmdd')}&AM=${order.data.amount}&VS=${order.data.number}&ACC=${eshop.settings.iban}`}
                                            alt="qrplatba"
                                        />
                                    </div>
                                    <div className="account__content__order__transfer__info">
                                        <div className="account__content__order__transfer__info__title">{__('Zaplaťte na náš bankový účet v EUR')}</div>
                                        <div className="account__content__order__transfer__info__item">
                                            <div className="account__content__order__transfer__info__item__name">{__('Číslo účtu')}:</div>
                                            <div className="account__content__order__transfer__info__item__value">
                                                <span>{eshop.settings.account}</span>
                                                <CopyIcon onClick={() => this.copy(eshop.settings.account)} />
                                            </div>
                                        </div>
                                        <div className="account__content__order__transfer__info__item">
                                            <div className="account__content__order__transfer__info__item__name">{__('Var. symbol')}:</div>
                                            <div className="account__content__order__transfer__info__item__value">
                                                <span>{order.data.number}</span>
                                                <CopyIcon onClick={() => this.copy(order.data.number)} />
                                            </div>
                                        </div>
                                        <div className="account__content__order__transfer__info__item">
                                            <div className="account__content__order__transfer__info__item__name">{__('Suma')}:</div>
                                            <div className="account__content__order__transfer__info__item__value">
                                                <span>{`${formatAmount(order.data.amount, 2, ',', false)} ${currency}`}</span>
                                                <CopyIcon onClick={() => this.copy(`${formatAmount(order.data.amount, 2, ',', false)} ${currency}`)} />
                                            </div>
                                        </div>
                                        <div className="account__content__order__transfer__info__item">
                                            <div className="account__content__order__transfer__info__item__name">{__('IBAN')}:</div>
                                            <div className="account__content__order__transfer__info__item__value">
                                                <span>{eshop.settings.iban}</span>
                                                <CopyIcon onClick={() => this.copy(eshop.settings.iban)} />
                                            </div>
                                        </div>
                                        <div className="account__content__order__transfer__info__item">
                                            <div className="account__content__order__transfer__info__item__name">{__('BIC')}:</div>
                                            <div className="account__content__order__transfer__info__item__value">
                                                <span>{eshop.settings.bic}</span>
                                                <CopyIcon onClick={() => this.copy(eshop.settings.bic)} />
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}
                                <div className="account__content__order__panels">
                                    <div className="account__content__order__panels__panel">
                                        <div className="account__content__order__panels__panel__value">
                                            <div className="account__content__order__panels__panel__value__name">{__('Číslo objednávky')}:</div>
                                            <div className="account__content__order__panels__panel__value__text">{order.data.number}</div>
                                        </div>
                                        <div className="account__content__order__panels__panel__value">
                                            <div className="account__content__order__panels__panel__value__name">{__('Dátum objednávky')}:</div>
                                            <div className="account__content__order__panels__panel__value__text">{formatDate(order.data.date, 'dd.mm.yyyy')}</div>
                                        </div>
                                        <div className="account__content__order__panels__panel__value">
                                            <div className="account__content__order__panels__panel__value__name">{__('Email')}:</div>
                                            <div className="account__content__order__panels__panel__value__text">{order.data.address.email}</div>
                                        </div>
                                        <div className="account__content__order__panels__panel__value">
                                            <div className="account__content__order__panels__panel__value__name">{__('Telefón')}:</div>
                                            <div className="account__content__order__panels__panel__value__text">{formatPhone(order.data.address.phone)}</div>
                                        </div>
                                        {order.data.tracking_number !== '' ? <div className="account__content__order__panels__panel__value">
                                            <div className="account__content__order__panels__panel__value__name">{__('Sledovanie zásielky')}:</div>
                                            <div className="account__content__order__panels__panel__value__text">
                                                <span>{_.has(eshop.delivers, order.data.delivery_id)
                                                    ? getText(eshop.delivers[order.data.delivery_id].name)
                                                    : null}</span>
                                                <a href={order.data.tracking_url} target="_blank">({order.data.tracking_number})</a>
                                            </div>
                                        </div> : null}
                                    </div>
                                    <div className="account__content__order__panels__panel">
                                        {!isPaid && showTransfer ? <div className="account__content__order__panels__panel__buttons">
                                            <ButtonBase
                                                onClick={() => this.showLightbox('delivery', {
                                                    country: order.data.delivery_address.country,
                                                    order_id: order.id,
                                                    order_token: order.token,
                                                    payment_id: order.data.payment_id,
                                                })}
                                            >{__('Zmena spôsobu platby')}</ButtonBase>
                                            <ButtonBase
                                                onClick={() => {}}
                                            >{__('Zaplatiť')}</ButtonBase>
                                        </div> : null}
                                    </div>
                                    <div className="account__content__order__panels__panel">
                                        <div className="account__content__order__panels__panel__address">
                                            <div className="account__content__order__panels__panel__address__title">{__('Fakturačná adresa')}</div>
                                            <div className="account__content__order__panels__panel__address__value">
                                                {order.data.address.company_name !== '' ? order.data.address.company_name : order.data.address.name}<br />
                                                {order.data.address.address}<br />
                                                {order.data.address.zip} {order.data.address.city}<br />
                                                {eshop.countries[order.data.address.country]}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="account__content__order__panels__panel">
                                        <div className="account__content__order__panels__panel__address">
                                            <div className="account__content__order__panels__panel__address__title">{__('Dodacia adresa')}</div>
                                            <div className="account__content__order__panels__panel__address__value">
                                                {order.data.delivery_address.company_name !== '' ? order.data.delivery_address.company_name : order.data.delivery_address.name}<br />
                                                {order.data.delivery_address.address}<br />
                                                {order.data.delivery_address.zip} {order.data.delivery_address.city}<br />
                                                {eshop.countries[order.data.delivery_address.country]}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="account__content__order__products">
                                    {_.map(order.data.products, ({ id, count, price, settings }) => {
                                        if (!_.has(eshop.products, id)) {
                                            return null;
                                        }

                                        // Vytiahneme produkt
                                        const product = eshop.products[id];
                                        const name = getText(product.name);

                                        // Ma extensions?
                                        const hasExtensions = !_.isEmpty(settings.extensions);

                                        let variant = null;
                                        let variantStockStatus = null;
                                        let variantData = null;

                                        if (_.has(settings, 'variant') && settings.variant > 0 && _.has(eshop.variants, settings.variant)) {
                                            variant = eshop.variants[settings.variant];

                                            // Najdeme variant
                                            const productVariant = _.find(product.variants, ({ id }) => id === settings.variant);

                                            if (!_.isUndefined(productVariant)) {
                                                variantData = productVariant;
                                                variantStockStatus = getProductStockStatus(productVariant.stock, productVariant.storage, productVariant.minus);
                                            }
                                        }

                                        totalProducts += price * count;

                                        return (
                                            <div className="account__content__order__products__product" key={id}>
                                                <div className={`account__content__order__products__product__up ${hasExtensions ? 'with-extensions' : ''}`}>
                                                    <div className="account__content__order__products__product__up__left">
                                                        <div className="account__content__order__products__product__up__left__photo">
                                                            {product.image !== '' ? <img src={getImageUrl(product.image)} alt={getText(product.name)} /> : null}
                                                        </div>
                                                    </div>
                                                    <div className="account__content__order__products__product__up__info">
                                                        <div className="account__content__order__products__product__up__info__name">
                                                            {`${name}${variant !== null ? ` - ${getText(variant.name)}` : ''}`}
                                                        </div>
                                                    </div>
                                                    <div className={`account__content__order__products__product__up__stock ${variantStockStatus}`}>{variantStockStatus !== null && variantData !== null
                                                        ? getProductStockTitle(variantStockStatus, variantData.minus, variantData.minus_text).toLowerCase()
                                                        : null}</div>
                                                    <div className="account__content__order__products__product__up__count">{count}x</div>
                                                    <div className="account__content__order__products__product__up__unitprice">{`${formatAmount(price, 2, ',', false)} ${currency}`}</div>
                                                    <div className="account__content__order__products__product__up__price">{`${formatAmount(price * count, 2, ',', false)} ${currency}`}</div>
                                                </div>
                                                {hasExtensions ? <div className="account__content__order__products__product__extensions">
                                                    {_.map(settings.extensions, id => {
                                                        if (!_.has(eshop.extensions, id)) {
                                                            return null;
                                                        }

                                                        let title = '';

                                                        return (
                                                            <div className="account__content__order__products__product__extensions__extension" key={id}>
                                                                <div className="account__content__order__products__product__extensions__extension__name">
                                                                    {getText(eshop.extensions[id].name)}
                                                                    {title !== ''
                                                                        ? <Tooltip title={title}><ExtensionsInfoIcon /></Tooltip>
                                                                        : null}
                                                                </div>
                                                                <div className="account__content__order__products__product__extensions__extension__price">
                                                                    {`${formatAmount(eshop.extensions[id].price, 2, ',', false)} ${currency}`}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div> : null}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="account__content__order__summary">
                                    <div className="account__content__order__summary__amounts">
                                        <div className="account__content__order__summary__amounts__item">
                                            <div className="account__content__order__summary__amounts__item__name">{__('Celkom za tovar')}</div>
                                            <div className="account__content__order__summary__amounts__item__value">
                                                {`${formatAmount(totalProducts, 2, ',', false)} ${currency}`}
                                            </div>
                                        </div>
                                        {order.data.discount_amount > 0 ? <div className="account__content__order__summary__amounts__item">
                                            <div className="account__content__order__summary__amounts__item__name">
                                                {order.data.coupon === 'credit' ? __('Kredit') : `${__('Kupón')} (${order.data.coupon})`}
                                            </div>
                                            <div className="account__content__order__summary__amounts__item__value">
                                                -{`${formatAmount(order.data.discount_amount, 2, ',', false)} ${currency}`}
                                            </div>
                                        </div> : null}
                                        {order.data.delivery_amount > 0 ? <div className="account__content__order__summary__amounts__item">
                                            <div className="account__content__order__summary__amounts__item__name">{__('Doprava')}</div>
                                            <div className="account__content__order__summary__amounts__item__value">
                                                {`${formatAmount(order.data.delivery_amount, 2, ',', false)} ${currency}`}
                                            </div>
                                        </div> : null}
                                        {order.data.payment_amount > 0 ? <div className="account__content__order__summary__amounts__item">
                                            <div className="account__content__order__summary__amounts__item__name">{__('Poplatok za dobierku')}</div>
                                            <div className="account__content__order__summary__amounts__item__value">
                                                {`${formatAmount(order.data.payment_amount, 2, ',', false)} ${currency}`}
                                            </div>
                                        </div> : null}
                                    </div>
                                    <div className="account__content__order__summary__amount">
                                        <div className="account__content__order__summary__amount__name">{__('Celkom s DPH')}</div>
                                        <div className="account__content__order__summary__amount__value">
                                            {`${formatAmount(order.data.amount, 2, ',', false)} ${currency}`}
                                        </div>
                                    </div>
                                    <div className="account__content__order__summary__vat">
                                        <div className="account__content__order__summary__vat__item">
                                            <div className="account__content__order__summary__vat__item__name">{__('DPH')} {order.data.tax}%</div>
                                            <div className="account__content__order__summary__vat__item__value">
                                                {`${formatAmount(order.data.amount - toFixed(order.data.amount * (100 / (100 + toNumber(order.data.tax)))), 2, ',', false)} ${currency}`}
                                            </div>
                                        </div>
                                        <div className="account__content__order__summary__vat__item">
                                            <div className="account__content__order__summary__vat__item__name">{__('Cena bez DPH')}</div>
                                            <div className="account__content__order__summary__vat__item__value">
                                                {`${formatAmount(order.data.amount * (100 / (100 + toNumber(order.data.tax))), 2, ',', false)} ${currency}`}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                }

                return (
                    <div className="account__content__orders">
                        <h1 className="account__content__orders__title">{__('Vitaj vo svojom účte')}</h1>
                        {accountPanel}
                        {backButton}
                        <h2 className="account__content__orders__subtitle">{__('Prehľad objednávok')}</h2>
                        <div className="account__content__orders__table">
                            <div className="account__content__orders__table__line">
                                <div className="account__content__orders__table__line__cell">{__('Číslo objednávky')}</div>
                                <div className="account__content__orders__table__line__cell">{__('Dátum')}</div>
                                <div className="account__content__orders__table__line__cell">{__('Cena')}</div>
                                <div className="account__content__orders__table__line__cell">{__('Doprava')}</div>
                                <div className="account__content__orders__table__line__cell">{__('Stav')}</div>
                            </div>
                            {_.map(data.orders, ({ id, data }) => {
                                return (
                                    <div className="account__content__orders__table__line" key={id}>
                                        <div className="account__content__orders__table__line__cell">
                                            <ButtonBase
                                                onClick={() => this.showOrder(id)}
                                            >{data.number}</ButtonBase>
                                        </div>
                                        <div className="account__content__orders__table__line__cell">
                                            {formatDate(data.date, 'dd.mm.yyyy')}
                                        </div>
                                        <div className="account__content__orders__table__line__cell">
                                            {`${formatAmount(data.amount, 2, ',', false)} ${getText(eshop.currencies[toNumber(data.currency)].name)}`}
                                        </div>
                                        <div className="account__content__orders__table__line__cell">
                                            {_.has(eshop.delivers, data.delivery_id) && eshop.delivers[data.delivery_id].image !== ''
                                                ? <img
                                                    src={getImageUrl(eshop.delivers[data.delivery_id].image)}
                                                    alt={getText(eshop.delivers[data.delivery_id].name)}
                                                /> : null}
                                            {data.tracking_number !== ''
                                                ? <a href={data.tracking_url} target="_blank">({data.tracking_number})</a>
                                                : null}
                                        </div>
                                        <div
                                            className="account__content__orders__table__line__cell"
                                            style={{ color: eshop.states[data.state].color }}
                                        >
                                            {getText(eshop.states[data.state].name)}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );

            case 'reviews':
                // Recenzie
                const { showReviews } = this.state;

                let notReviewsProducts = [];

                return (
                    <div className="account__content__reviews">
                        <h1 className="account__content__reviews__title">{__('Vitaj vo svojom účte')}</h1>
                        {accountPanel}
                        {backButton}
                        <h2 className="account__content__reviews__subtitle">{__('Prehľad recenzií')}</h2>
                        <div className="account__content__reviews__table">
                            <div className="account__content__reviews__table__line">
                                <div className="account__content__reviews__table__line__cell">{__('Produkt')}</div>
                                <div className="account__content__reviews__table__line__cell">{__('Dátum')}</div>
                                <div className="account__content__reviews__table__line__cell">{__('Recenzia')}</div>
                                <div className="account__content__reviews__table__line__cell">{__('Text recenzie')}</div>
                            </div>
                            {_.map(data.reviews, ({ id, parent_id, data, created }) => {
                                if (!_.has(eshop.products, parent_id)) {
                                    return null;
                                }

                                return (
                                    <div className="account__content__reviews__table__line" key={id}>
                                        <div className="account__content__reviews__table__line__cell">
                                            <ButtonBase
                                                onClick={() => this.redirect(`/obchod/${getText(eshop.products[parent_id].url)}`)}
                                            >{getText(eshop.products[parent_id].name)}</ButtonBase>
                                        </div>
                                        <div className="account__content__reviews__table__line__cell">{formatDate(created, 'dd.mm.yyyy')}</div>
                                        <div className="account__content__reviews__table__line__cell">
                                            <Rating value={round(data.review, 0)} readOnly />
                                        </div>
                                        <div className="account__content__reviews__table__line__cell">
                                            <span>{_.has(showReviews, id) ? data.text : _.truncate(data.text, { length: 50 })}</span>
                                            {data.text.length > 50 ? <ButtonBase
                                                onClick={() => this.showReview(id)}
                                            >{_.has(showReviews, id) ? __('zobraziť menej') : __('zobraziť viac')}</ButtonBase> : null}
                                        </div>
                                    </div>
                                );
                            })}
                            {_.map(data.orders, order => {
                                return _.map(order.data.products, ({ id }) => {
                                    if (!_.has(eshop.products, id)) {
                                        return null;
                                    }

                                    let hasReview = false;

                                    _.forEach(data.reviews, ({ parent_id }) => {
                                        if (toNumber(parent_id) === toNumber(id)) {
                                            // Mate recenziu
                                            hasReview = true;
                                        }
                                    });

                                    if (hasReview || _.includes(notReviewsProducts, id)) {
                                        // Uz ma recenziu
                                        return null;
                                    }

                                    notReviewsProducts = [ ...notReviewsProducts, id ];

                                    return (
                                        <div className="account__content__reviews__table__line" key={id}>
                                            <div className="account__content__reviews__table__line__cell">
                                                <ButtonBase
                                                    onClick={() => this.redirect(`/obchod/${getText(eshop.products[id].url)}`)}
                                                >{getText(eshop.products[id].name)}</ButtonBase>
                                            </div>
                                            <div className="account__content__reviews__table__line__cell">{formatDate(order.data.date, 'dd.mm.yyyy')}</div>
                                            <div className="account__content__reviews__table__line__cell">
                                                <ButtonBase
                                                    onClick={() => this.redirect(`/obchod/${getText(eshop.products[id].url)}/1`)}
                                                >{__('pridať')}</ButtonBase>
                                            </div>
                                            <div className="account__content__reviews__table__line__cell">
                                            </div>
                                        </div>
                                    );
                                });
                            })}
                        </div>
                    </div>
                );

            case 'credit':
                // Kredit
                return (
                    <div className="account__content__credit">
                        <h1 className="account__content__credit__title">{__('Vitaj vo svojom účte')}</h1>
                        {accountPanel}
                        {backButton}
                        <div className="account__content__credit__panel">
                            <div className="account__content__credit__panel__title">{__('Stav kreditu')}</div>
                            <div className="account__content__credit__panel__line">
                                <div className="account__content__credit__panel__line__name">{__('Aktuálny stav kreditu')}:</div>
                                <div className="account__content__credit__panel__line__value">{formatAmount(getPrice(eshop.user.credit))}</div>
                            </div>
                            {!_.isEmpty(eshop.user.credit_end) ? <div className="account__content__credit__panel__line">
                                <div className="account__content__credit__panel__line__name">{__('Platnosť kreditu do')}:</div>
                                <div className="account__content__credit__panel__line__value">{formatDate(eshop.user.credit_end, 'dd.mm.yyyy')}</div>
                            </div> : null}
                        </div>
                        <div className="account__content__credit__table">
                            <div className="account__content__credit__table__line">
                                <div className="account__content__credit__table__line__cell">{__('Smer')}</div>
                                <div className="account__content__credit__table__line__cell">{__('Číslo objednávky')}</div>
                                <div className="account__content__credit__table__line__cell">{__('Dátum')}</div>
                                <div className="account__content__credit__table__line__cell">{__('Suma')}</div>
                            </div>
                            {_.map(data.credits, ({ id, order_id, amount, created }) => {
                                const debet = amount < 0;
                                let order = undefined;

                                if (order_id > 0) {
                                    order = _.find(data.orders, ({ id }) => id === order_id);
                                }

                                return (
                                    <div className={`account__content__credit__table__line ${debet ? 'debet' : 'credit'}`} key={id}>
                                        <div className="account__content__credit__table__line__cell">
                                            {debet ? <DebetLineIcon /> : <CreditLineIcon />}
                                        </div>
                                        <div className="account__content__credit__table__line__cell">
                                            {!_.isUndefined(order) ? <ButtonBase
                                                onClick={() => this.showOrder(order_id)}
                                            >{order.data.number}</ButtonBase> : null}
                                        </div>
                                        <div className="account__content__credit__table__line__cell">{formatDate(created, 'dd.mm.yyyy')}</div>
                                        <div className="account__content__credit__table__line__cell">{formatAmount(getPrice(amount))}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );

            case 'settings':
                // Nastavenia
                const {
                    loading,
                    settings,
                    errors,
                    email_valid,
                    phone_loading,
                    phone_valid,
                    ico_loading,
                    dic_loading,
                    showAddress,
                    address,
                    showDeliveryAddress,
                    deliveryAddress,
                    delivery_phone_valid,
                    delivery_phone_loading,
                    showPassword,
                    oldPassword,
                    newPassword,
                    newPasswordRepeat,
                } = this.state;

                return (
                    <div className="account__content__settings">
                        <h1 className="account__content__settings__title">{__('Vitaj vo svojom účte')}</h1>
                        {accountPanel}
                        {backButton}
                        <div className="account__content__settings__password">
                            <h2 className="account__content__settings__password__title">{__('Prihlasovacie údaje')}</h2>
                            <div className="account__content__settings__password__data">
                                <div className="account__content__settings__password__data__text">{__('Prihlasovací email')}:</div>
                                <div className="account__content__settings__password__data__email">{eshop.user.email}</div>
                                <ButtonBase
                                    onClick={() => this.showPassword()}
                                >{__('Zmena hesla')}</ButtonBase>
                            </div>
                            {showPassword ? <div className="account__content__settings__password__settings">
                                <Input
                                    label={__('Staré heslo')}
                                    value={oldPassword}
                                    onChange={value => this.onChangeOldPassword(value)}
                                    error={_.has(errors, 'old_password') ? errors.old_password : ''}
                                    type="password"
                                />
                                <Input
                                    label={__('Nové heslo')}
                                    value={newPassword}
                                    onChange={value => this.onChangeNewPassword(value)}
                                    error={_.has(errors, 'new_password') ? errors.new_password : ''}
                                    type="password"
                                />
                                <Input
                                    label={__('Potvrdenie hesla')}
                                    value={newPasswordRepeat}
                                    onChange={value => this.onChangeNewPasswordRepeat(value)}
                                    error={_.has(errors, 'new_password_repeat') ? errors.new_password_repeat : ''}
                                    type="password"
                                />
                            </div> : null}
                        </div>
                        <div className="account__content__settings__contact">
                            <h2 className="account__content__settings__contact__title">{__('Kontaktné údaje')}</h2>
                            <div className="account__content__settings__contact__inputs">
                                <Input
                                    label={<div>{__('Email')} <span>{__('(pre informácie o objednávke)')}</span></div>}
                                    value={settings.email}
                                    onChange={value => this.onChangeSetting('email', value)}
                                    onBlur={() => this.checkEmail()}
                                    error={_.has(errors, 'email') ? errors.email : ''}
                                    right={email_valid ? <div className="input__right valid-icon"><ValidIcon /></div> : null}
                                    type="email"
                                />
                                <div className="phone">
                                    <Select
                                        label={__('Predvoľba')}
                                        options={eshop.phone_prefixes}
                                        value={settings.phone_prefix.toUpperCase()}
                                        onChange={value => this.onChangeSetting('phone_prefix', value.toLowerCase())}
                                        allowEmpty={false}
                                        phones
                                    />
                                    <Input
                                        label={<div>{__('Telefón')} <span>{__('(pre doručenie a prípadne otázky)')}</span></div>}
                                        value={settings.phone}
                                        onChange={value => this.onChangeSetting('phone', value)}
                                        onBlur={() => this.checkPhone()}
                                        error={_.has(errors, 'phone') ? errors.phone : ''}
                                        right={phone_loading
                                            ? <div className="input__right"><CircularProgress className="loading" color="inherit" size={20} /></div>
                                            : (phone_valid ? <div className="input__right valid-icon"><ValidIcon /></div> : null)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="account__content__settings__address">
                            <h2 className="account__content__settings__address__title">{__('Fakturačné údaje')}</h2>
                            <Checkbox
                                label={__('Nakupujem na firmu')}
                                value={settings.company}
                                onChange={value => this.onChangeSetting('company', value)}
                            />
                            {settings.company ? <div className="account__content__settings__address__inputs">
                                <Input
                                    label={<div>{__('IČO')} <span>{__('(automatické načítanie údajov z ARES)')}</span></div>}
                                    value={settings.ico}
                                    onChange={value => this.onChangeSetting('ico', value)}
                                    onBlur={() => this.loadAres()}
                                    error={_.has(errors, 'ico') ? errors.ico : ''}
                                    right={ico_loading
                                        ? <div className="input__right"><CircularProgress className="loading" color="inherit" size={20} /></div>
                                        : null}
                                />
                                <Input
                                    label={<div>{__('DIČ')} {settings.vies_token !== ''
                                        ? <span className="c-green">{__('(Overené v systéme VIES)')}</span>
                                        : null}</div>}
                                    value={settings.dic}
                                    onChange={value => this.onChangeSetting('dic', value)}
                                    onBlur={() => this.loadVies()}
                                    error={_.has(errors, 'dic') ? errors.dic : ''}
                                    right={dic_loading
                                        ? <div className="input__right"><CircularProgress className="loading" color="inherit" size={20} /></div>
                                        : (settings.vies_token !== ''
                                            ? <div className="input__right valid-icon"><ValidIcon /></div>
                                            : null)}
                                />
                            </div> : null}
                            {settings.company ? <Input
                                label={__('Názov firmy')}
                                value={settings.company_name}
                                onChange={value => this.onChangeSetting('company_name', value)}
                                error={_.has(errors, 'company_name') ? errors.company_name : ''}
                            /> : null}
                            {!settings.company ? <Input
                                label={__('Celé meno')}
                                value={settings.name}
                                onChange={value => this.onChangeSetting('name', value)}
                                error={_.has(errors, 'name') ? errors.name : ''}
                            /> : null}
                            {!showAddress ? <GoogleAutocomplete
                                label={<div>{__('Adresa')} <span>{__('(automatické vyhľadávanie)')}</span></div>}
                                value={address}
                                onChange={value => this.onChangeAddress(value)}
                                onPlaceSelected={place => this.onChangePlace(place)}
                                placeApiKey={eshop.settings.gmaps_apikey}
                                placeholder={__('Začnite písať adresu a vyberte spolu s popisním číslom')}
                                left={<SearchIcon strokeWidth={1} />}
                            /> : null}
                            {!showAddress ? <ButtonBase
                                onClick={() => this.showAddress()}
                                className="account__content__settings__address__manual"
                            >{__('Adresu zadám ručne')}</ButtonBase> : null}
                            {showAddress ? <Input
                                label={__('Ulica a popisné číslo')}
                                value={settings.address}
                                onChange={value => this.onChangeSetting('address', value)}
                                error={_.has(errors, 'address') ? errors.address : ''}
                            /> : null}
                            {showAddress ? <Input
                                label={<div>{__('Číslo hotelovej izby, apartmánu alebo ďalšie upresnenie adresy')} <span>{__('(nepovinné)')}</span></div>}
                                value={settings.address_additional}
                                onChange={value => this.onChangeSetting('address_additional', value)}
                                error={_.has(errors, 'address_additional') ? errors.address_additional : ''}
                            /> : null}
                            {showAddress ? <div className="account__content__settings__address__inputs">
                                <Input
                                    label={__('PSČ')}
                                    value={settings.zip}
                                    onChange={value => this.onChangeSetting('zip', value)}
                                    error={_.has(errors, 'zip') ? errors.zip : ''}
                                />
                                <Input
                                    label={__('Mesto')}
                                    value={settings.city}
                                    onChange={value => this.onChangeSetting('city', value)}
                                    error={_.has(errors, 'city') ? errors.city : ''}
                                />
                            </div> : null}
                            {showAddress ? <Select
                                label={__('Krajina')}
                                options={eshop.countries}
                                value={settings.country}
                                onChange={value => this.onChangeSetting('country', value)}
                                allowEmpty={false}
                                countries
                            /> : null}
                        </div>
                        <Checkbox
                            label={__('Doručiť na inú adresu')}
                            value={settings.delivery}
                            onChange={value => this.onChangeSetting('delivery', value)}
                        />
                        {settings.delivery ? <div className="account__content__settings__delivery">
                            <h2 className="account__content__settings__delivery__title">{__('Doručovacie údaje')}</h2>
                            {settings.company ? <Input
                                label={__('Názov firmy')}
                                value={settings.delivery_company_name}
                                onChange={value => this.onChangeSetting('delivery_company_name', value)}
                                error={_.has(errors, 'delivery_company_name') ? errors.delivery_company_name : ''}
                            /> : null}
                            {!settings.company ? <Input
                                label={__('Celé meno')}
                                value={settings.delivery_name}
                                onChange={value => this.onChangeSetting('delivery_name', value)}
                                error={_.has(errors, 'delivery_name') ? errors.delivery_name : ''}
                            /> : null}
                            <div className="account__content__settings__delivery__inputs">
                                <div className="phone">
                                    <Select
                                        label={__('Predvoľba')}
                                        options={eshop.phone_prefixes}
                                        value={settings.delivery_phone_prefix.toUpperCase()}
                                        onChange={value => this.onChangeSetting('delivery_phone_prefix', value.toLowerCase())}
                                        allowEmpty={false}
                                        phones
                                    />
                                    <Input
                                        label={<div>{__('Telefon')} <span>{__('(pre doručenie a prípadne otázky)')}</span></div>}
                                        value={settings.delivery_phone}
                                        onChange={value => this.onChangeSetting('delivery_phone', value)}
                                        onBlur={() => this.checkPhone('delivery_phone')}
                                        error={_.has(errors, 'delivery_phone') ? errors.delivery_phone : ''}
                                        right={delivery_phone_loading
                                            ? <div className="input__right"><CircularProgress className="loading" color="inherit" size={20} /></div>
                                            : (delivery_phone_valid ? <div className="input__right valid-icon"><ValidIcon /></div> : null)}
                                    />
                                </div>
                            </div>
                            {!showDeliveryAddress ? <GoogleAutocomplete
                                label={<div>{__('Adresa')} <span>{__('(automatické vyhľadávanie)')}</span></div>}
                                value={deliveryAddress}
                                onChange={value => this.onChangeDeliveryAddress(value)}
                                onPlaceSelected={place => this.onChangePlace(place, 'delivery_')}
                                placeApiKey={eshop.settings.gmaps_apikey}
                                placeholder={__('Začnite písať adresu a vyberte spolu s popisním číslom')}
                                left={<SearchIcon strokeWidth={1} />}
                            /> : null}
                            {!showDeliveryAddress ? <ButtonBase
                                onClick={() => this.showDeliveryAddress()}
                                className="account__content__settings__delivery__manual"
                            >{__('Adresu zadám ručne')}</ButtonBase> : null}
                            {showDeliveryAddress ? <Input
                                label={__('Ulica a popisné číslo')}
                                value={settings.delivery_address}
                                onChange={value => this.onChangeSetting('delivery_address', value)}
                                error={_.has(errors, 'delivery_address') ? errors.delivery_address : ''}
                            /> : null}
                            {showDeliveryAddress ? <Input
                                label={<div>{__('Číslo hotelovej izby, apartmánu alebo ďalšie upresnenie adresy')} <span>{__('(nepovinné)')}</span></div>}
                                value={settings.delivery_address_additional}
                                onChange={value => this.onChangeSetting('delivery_address_additional', value)}
                                error={_.has(errors, 'delivery_address_additional') ? errors.delivery_address_additional : ''}
                            /> : null}
                            {showDeliveryAddress ? <div className="account__content__settings__delivery__inputs">
                                <Input
                                    label={__('PSČ')}
                                    value={settings.delivery_zip}
                                    onChange={value => this.onChangeSetting('delivery_zip', value)}
                                    error={_.has(errors, 'delivery_zip') ? errors.delivery_zip : ''}
                                />
                                <Input
                                    label={__('Mesto')}
                                    value={settings.delivery_city}
                                    onChange={value => this.onChangeSetting('delivery_city', value)}
                                    error={_.has(errors, 'delivery_city') ? errors.delivery_city : ''}
                                />
                            </div> : null}
                            {showDeliveryAddress ? <Select
                                label={__('Krajina')}
                                options={eshop.countries}
                                value={settings.delivery_country}
                                onChange={value => this.onChangeSetting('delivery_country', value)}
                                allowEmpty={false}
                                countries
                            /> : null}
                        </div> : null}
                        <ButtonBase
                            onClick={() => this.saveSettings()}
                            className="account__content__settings__button"
                            loading={loading}
                        >{__('Uložiť')}</ButtonBase>
                    </div>
                );

            default:
                return null;
        }
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    renderScreen() {
        const { eshop } = this.props;
        const { section, lightbox } = this.state;

        let photoName = eshop.user.name.split(' ');
        photoName = photoName.length === 2 && photoName[0].length > 0 && photoName[1].length > 0
            ? `${photoName[0][0].toUpperCase()}${photoName[1][0].toUpperCase()}`
            : '';

        const hasPhoto = _.has(eshop.users, eshop.user.user_id) && !_.isEmpty(eshop.users[eshop.user.user_id].image);

        const accountPanel = (
            <div className="account__menu__panel">
                <div className={`account__menu__panel__photo ${hasPhoto ? 'has-photo' : ''}`}>
                    <div className="account__menu__panel__photo__empty">{photoName}</div>
                    {hasPhoto
                        ? <img src={getImageUrl(eshop.users[eshop.user.user_id].image)} alt={photoName} />
                        : null}
                    <div className="account__menu__panel__photo__add">
                        <PlusIcon />
                    </div>
                    <input type="file" onChange={event => this.uploadPhoto(event.target.files[0])} />
                </div>
                <div className="account__menu__panel__credit">
                    <div className="account__menu__panel__credit__name">{__('Stav kreditu')}</div>
                    <div className="account__menu__panel__credit__value">{formatAmount(getPrice(eshop.user.credit))}</div>
                </div>
            </div>
        );

        return (
            <div className="account">
                {section === null || !this.isMobile() ? <div className="account__menu">
                    <h1 className="account__menu__title">{__('Vitaj vo svojom účte')}</h1>
                    {accountPanel}
                    <div className="account__menu__buttons">
                        <ButtonBase
                            onClick={() => this.showSection('settings')}
                            className={section === 'settings' ? 'active' : ''}
                        >
                            <AccountIcon />
                            <span>{__('Osobné a kontaktné údaje')}</span>
                        </ButtonBase>
                        <ButtonBase
                            onClick={() => this.showSection('credit')}
                            className={section === 'credit' ? 'active' : ''}
                        >
                            <CreditIcon />
                            <span>{__('Stav kreditu')}</span>
                        </ButtonBase>
                        <ButtonBase
                            onClick={() => this.showSection('orders')}
                            className={section === 'orders' ? 'active' : ''}
                        >
                            <OrdersIcon />
                            <span>{__('Objednávky')}</span>
                        </ButtonBase>
                        <ButtonBase
                            onClick={() => this.showSection('reviews')}
                            className={section === 'reviews' ? 'active' : ''}
                        >
                            <ReviewsIcon />
                            <span>{__('Recenzie')}</span>
                        </ButtonBase>
                        <ButtonBase
                            onClick={() => this.showPopup('reklamace')}
                        >
                            <PrivacyIcon />
                            <span>{__('Reklamácie, vratky, výmeny')}</span>
                        </ButtonBase>
                        <ButtonBase
                            onClick={() => window.location = '/logout'}
                        >
                            <LogoutIcon />
                            <span>{__('Odhlásiť')}</span>
                        </ButtonBase>
                    </div>
                </div> : null}
                <div className="account__content">{this.renderSection(accountPanel)}</div>
                {this.renderLightbox('popup', '', !_.isEmpty(lightbox.popup)
                    ? <div dangerouslySetInnerHTML={{ __html: lightbox.popup.data }} />
                    : null, '', __('Zrušiť'))}
                {this.renderLightbox('delivery', __('Zmena spôsobu platby'), !_.isEmpty(lightbox.delivery)
                    ? <ChangeDelivery
                        eshop={eshop}
                        country={lightbox.delivery.country}
                        orderId={lightbox.delivery.order_id}
                        orderToken={lightbox.delivery.order_token}
                        paymentId={lightbox.delivery.payment_id}
                        callback={(status, data) => this.changePayment(status, data)}
                    />
                    : null, '', __('Zrušiť'))}
                {this.renderSnackbar()}
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        return super.render();
    }
}

const stateToProps = ({ eshop, settings }) => ({ eshop, settings });

export default withCookies(connect(stateToProps, { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchLists, fetchCompares })(AccountScreen));
