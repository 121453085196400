import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { ButtonGroup, Button, ButtonBase, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExportIcon from '@mui/icons-material/GetApp';
import ImportIcon from '@mui/icons-material/Publish';
import { DataGrid } from '@mui/x-data-grid';
import { Screen, Input, Select, TranslateHtml, Checkbox } from '../components';
import { request, isEmptyString, replaceDia, getUrl, getText, formatDate, toNumber } from '../functions';
import '../assets/styles/admin.css';

/**
 * Admin.
 */
class AdminScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = 'Admin';

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        type: 'orders',
        edit: {
            id: 0,
            field: '',
            value: '',
        },
        settings: {},
        updated: [],
        deleted: [],
        errors: [],
        loading: false,
        multipriceQuantity: '',
        lightbox: {
            translate_html: false,
            multiprice: false,
            company: false,
            address: false,
        },
    };

    /**
     * Sekcie.
     *
     * @type {Object}
     */
    sections = {
        'orders': 'Objednavky',
        'order-states': 'Stavy',
        'products': 'Produkty',
        'product-categories': 'Kategorie',
        'product-variables': 'Vlastnosti',
        'product-variants': 'Varianty',
        'product-extensions': 'Rozsíření',
        'product-images': 'Obrázky',
        'product-discounts': 'Slevy',
        'storages': 'Sklad',
        'storage-clients': 'Sklad - dodavatele',
        'deliveries': 'Dodání',
        'payments': 'Platby',
        'blogs': 'Blog',
        'blog-categories': 'Blog - kategorie',
        'testimonials': 'Reference',
        'coupons': 'Kupony',
        'languages': 'Lokalizace',
        'countries': 'Krajiny',
        'currencies': 'Meny',
        'users': 'Uživatelé',
        'blacklists': 'Blacklist',
        'newsletters': 'Newsletter',
        'charts': 'Tabulky',
        'popups': 'Popupy',
        'settings': 'Nastavení',
    };

    /**
     * Sirky stlpcov.
     *
     * @type {Object}
     */
    fieldsWidths = {
        'orders': { number: 100, state_id: 200, payment_id: 200, delivery_id: 200 },
        'order-states': { name: 400 },
        'products': { name: 400, price: 100, chart: 200 },
        'product-categories': { parent_id: 400, name: 400 },
        'product-variables': { parent_id: 400, name: 400 },
        'product-variants': { text: 800 },
        'product-extensions': { name: 400 },
        'product-images': { product_id: 400 },
        'product-discounts': { product_id: 400 },
        'storages': { product_id: 400, sku: 100, weight: 100, height: 100, width: 100, length: 100, stock: 100, reserved: 100, ready: 100, limit_min: 100, buy_price: 100, buy_currency: 100 },
        'storage-clients': { name: 400 },
        'deliveries': { name: 400 },
        'payments': { name: 400 },
        'blogs': { name: 400 },
        'blog-categories': { name: 400 },
        'languages': { name: 400 },
        'countries': { name: 400 },
        'users': { email: 400, name: 400 },
        'blacklists': { email: 400 },
        'newsletters': { email: 400 },
        'charts': { name: 400 },
        'popups': { name: 400 },
        'coupons': { order_id: 120, code: 100, value: 120, discount: 70, products_in: 150, products_out: 150, categories_in: 150, categories_out: 150 },
    };

    /**
     * Edit ref.
     *
     * @type {null}
     */
    editRef = null;

    /**
     * Construktor.
     *
     * @param props
     */
    constructor(props) {
        super(props);

        this.editRef = React.createRef();
    }

    /**
     * Komponenta bola pripojena.
     */
    async componentDidMount() {
        this.scrollToTop();

        // Nasetujeme title
        this.setTitle(this.title);

        // Nastavime token
        this.setToken();

        if (global.token === '') {
            window.location = '/moj-ucet?admin=1';
            return;
        }

        const { params } = this.props;

        await this.changeContent(_.has(params, 'type') ? params.type : 'orders');
    }

    /**
     * Zmenime obsah.
     *
     * @param {string} type
     */
    async changeContent(type) {
        this.setState({ data: {}, changed: false });

        await request(`/${type}/admin/`).then(response => {
            const { status, data } = response.data;

            if (status !== 'success') {
                window.location = '/';
                return;
            }

            // Nahradime fields v polozkach default itemom
            let items = _.map(data.items, (item) => ({ ...item, data: { ...item.data, fields: data.empty.fields } }));

            this.setState({ type, data: { ...data, items } });
        }).catch(error => window.location = '/');
    }

    /**
     * Pridame novy item.
     */
    add() {
        let { type, data, settings } = this.state;

        // Buildneme
        let empty = _.reduce(data.empty, (result, value, field) => {
            if (field === 'ignore' || _.includes(data.empty.ignore, field)) {
                return result;
            }

            // Vytiahneme options
            const fieldOptions = _.has(data.empty.fields, field)
                ? data.empty.fields[field]
                : {};

            if (!_.isEmpty(fieldOptions) && fieldOptions.type === 'id') {
                // Id vynulujeme
                value = '';
            }

            return { ...result, [field]: value };
        }, {});

        if (type === 'coupons') {
            // Vygenerujeme kod kuponu
            const code = data.codes[0];

            empty = { ...empty, code };

            // Odstranime kupon z poolu
            data = { ...data, codes: _.without(data.codes, code) };
        }

        const id = `new-${data.items.length + 1}`;

        this.setState({ settings: { ...settings, [id]: empty }, data: { ...data, items: [ ...data.items, {
            id,
            data: empty,
        } ] } });
    }

    /**
     * Zmazeme item.
     *
     * @param {number} id
     */
    delete(id) {
        const { data, deleted } = this.state;

        const items = _.reduce(data.items, (result, item) => {
            if (item.id === id) {
                // Odstranujeme
                return result;
            }

            return [ ...result, item ];
        }, []);

        this.setState({
            data: { ...data, items },
            deleted: id > 0 ? [ ...deleted, id ] : deleted,
        });
    }

    /**
     * Vratime stlpce.
     *
     * @return {Array}
     */
    getColumns() {
        const { type, data, edit, errors } = this.state;

        if (type === 'settings') {
            // Nastavenia
            return [
                {
                    field: 'key',
                    headerName: 'Nazev',
                    width: 200,
                },
                {
                    field: 'value',
                    headerName: 'Hodnota',
                    width: 400,
                },
            ];
        }

        let defaultFields = [
            {
                field: 'delete',
                headerName: '',
                width: 30,
                renderCell: type !== 'languages' ? ({ id }) => (
                    <IconButton onClick={() => this.delete(id)}>
                        <DeleteIcon />
                    </IconButton>
                ) : ({ value }) => (
                    <Tooltip title="Exportovat">
                        <IconButton onClick={() => this.downloadExportLanguage(value)}>
                            <ExportIcon />
                        </IconButton>
                    </Tooltip>
                ),
            },
        ];

        if (type === 'languages') {
            // Preklady
            defaultFields = [ ...defaultFields, {
                field: 'import',
                headerName: '',
                width: 30,
                renderCell: ({ value }) => (
                    <Tooltip title="Importovat">
                        <IconButton onClick={() => {}} className="import">
                            <ImportIcon />
                            <input type="file" onChange={event => this.importLanguage(value, event.target.files[0])} />
                        </IconButton>
                    </Tooltip>
                ),
            } ];
        }

        defaultFields = [ ...defaultFields, {
            field: 'id',
            headerName: 'ID',
            width: 50,
            renderCell: ({ id }) => _.toString(id).indexOf('new-') === -1 ? id : '',
        } ];

        if (data.empty.allow_image) {
            // Povolujeme obrazok
            defaultFields = [ ...defaultFields, {
                field: 'image',
                headerName: 'Img',
                width: 50,
                renderCell: ({ id, value }) => (
                    <div className="upload">
                        {!_.isEmpty(value) ? <img src={`${getUrl()}${value}`} /> : null}
                        <input type="file" onChange={event => this.uploadFile(id, event.target.files[0])} />
                    </div>
                ),
            } ];
        }

        // Sirky stlpcov
        const fieldsWidths = _.has(this.fieldsWidths, type) ? this.fieldsWidths[type] : {};

        // Zoradime fieldy
        const fields = _.orderBy(_.map(data.empty.fields, (field, key) => ({ ...field, key })), [item => item.position], ['asc']);

        return _.reduce(fields, (result, { key, name, type }) => {
            let field = {
                field: key,
                headerName: name,
                width: 120,
                editable: false,
                type,
                renderCell: ({ id, field, value, colDef }) => {
                    if (_.includes(['translated_html_text', 'address', 'company', 'products', 'multiprice'], colDef.type)) {
                        // Preklady
                        return <IconButton><EditIcon /></IconButton>;
                    } else if (colDef.type === 'translated_text') {
                        // Default zobrazime sk preklad
                        value = _.has(value, 'sk') ? value.sk : '';
                    } else if (colDef.type === 'checkbox') {
                        return <Checkbox
                            value={value}
                            onChange={value => this.onChangeValue(id, field, value)}
                        />;
                    } else if (colDef.type === 'date') {
                        // Datum
                        return value !== null ? formatDate(value) : '';
                    }

                    if (_.isArray(value)) {
                        value = _.join(value, ',');
                    } else if (_.isObject(value)) {
                        value = '-';
                    }

                    if (colDef.type === 'id') {
                        // Zobrazujeme hodnotu
                        const from = _.mapValues(data[data.empty.fields[field].from], value => getText(value));

                        value = _.has(from, value) ? from[value] : value;
                    }

                    const errorKey = `${id}-${field}`;

                    if (_.includes(errors, errorKey)) {
                        // Mame error
                        return <div style={{
                            backgroundColor: '#c24646',
                            color: 'white',
                            height: '34px',
                            width: colDef.width,
                            display: 'flex',
                            alignItems: 'center',
                            margin: '0 -8px',
                            padding: '0 10px',
                        }}>{value}</div>;
                    }

                    if (edit.id === id && edit.field === field) {
                        // Editujeme
                        return <div style={{
                            backgroundColor: '#d3007b',
                            color: 'white',
                            height: '34px',
                            width: colDef.width,
                            display: 'flex',
                            alignItems: 'center',
                            margin: '0 -8px',
                            padding: '0 10px',
                        }}>{value}</div>;
                    }

                    return value;
                },
            };

            switch (type) {
                case 'translated_text':
                case 'generated':
                case 'text':
                    field = { ...field, width: 200 };
                    break;

                case 'id':
                case 'ids_array':
                    // Select
                    break;

                default:
                    break;
            }

            if (_.has(fieldsWidths, key)) {
                field = { ...field, width: fieldsWidths[key] };
            }

            return [ ...result, field ];
        }, defaultFields);
    }

    /**
     * Vratime zoznam riadkov.
     *
     * @return {Array}
     */
    getRows() {
        const { type, data, settings } = this.state;

        if (type === 'settings') {
            // Nastavenia
            return _.reduce(data, (result, value, key) => {
                if (key === 'items') {
                    return result;
                }

                return [ ...result, { id: key, key, value } ];
            }, []);
        }

        // Fieldy
        const emptyFields = data.empty.fields;
        const fields = _.keys(emptyFields);

        return _.reduce(data.items, (result, { id, data, image }) => {
            const hasSettings = _.has(settings, id);

            let defaultFields = { id, delete: 'delete' };

            if (type === 'languages') {
                defaultFields = { ...defaultFields, delete: data.code, import: data.code };
            }

            if (this.state.data.empty.allow_image) {
                defaultFields = { ...defaultFields, image };
            }

            return [ ...result, _.reduce(fields, (result, field) => {
                let value = _.has(data, field) ? data[field] : null;

                if (hasSettings && _.has(settings[id], field)) {
                    // Prepiseme
                    value = settings[id][field];
                }

                if (emptyFields[field].type === 'translated_html_text') {
                    // Nastavime preklady do hodnoty
                    value = _.has(data.translations, field) ? data.translations[field] : {};
                } else if (emptyFields[field].type === 'translated_text') {
                    // Nastavime preklady do hodnoty
                    value = _.has(data.translations, field) ? data.translations[field] : { sk: value };
                }

                return { ...result, [field]: value };
            }, defaultFields) ];
        }, []);
    }

    /**
     * Editujeme bunku.
     *
     * @param {number} id
     * @param {string} field
     * @param {string} value
     */
    editCell(id, field, value) {
        if (field === '__check__' || field === 'key') {
            return;
        }

        const { data, settings } = this.state;

        let translations = {};

        if (_.has(data, 'empty') && _.has(data.empty.fields, field)) {
            const type = data.empty.fields[field].type;

            if (type === 'checkbox') {
                // Neriesime
                return;
            } else if (type === 'products') {
                // Produkty
                return;
            }

            // Vygenerujeme default preklady
            const defaultTranslations = _.reduce(data.languages, (result, { code }) => ({ ...result, [code]: '' }), {});

            if (type === 'translated_html_text') {
                // Preklad html kodu
                // Buildneme field preklady
                let htmlTranslations = { ...defaultTranslations, ...value };

                if (
                    _.has(settings, id)
                    && _.has(settings[id], 'translations')
                    && _.has(settings[id].translations, field)
                ) {
                    htmlTranslations = { ...htmlTranslations, ...settings[id].translations[field] };
                }

                this.setState({ edit: { id: 0, field: '', value: '' } });
                this.showLightbox('translate_html', {
                    id,
                    field,
                    tab: 0,
                    translation: _.keys(htmlTranslations)[0],
                    translations: htmlTranslations,
                });
                return;
            } else if (type === 'translated_text') {
                // Preklad textu
                // Buildneme field preklady
                value = { ...defaultTranslations, ...value };

                if (
                    _.has(settings, id)
                    && _.has(settings[id], 'translations')
                    && _.has(settings[id].translations, field)
                ) {
                    value = { ...translations, ...settings[id].translations[field] };
                }
            } else if (type === 'multiprice') {
                let prices = value;

                if (
                    _.has(settings, id)
                    && _.has(settings[id], 'prices')
                ) {
                    prices = { ...prices, ...settings[id].prices };
                }

                this.setState({ edit: { id: 0, field: '', value: '' } });
                this.showLightbox('multiprice', {
                    id,
                    field,
                    prices,
                });
                return;
            } else if (type === 'company') {
                let company = value;

                if (
                    _.has(settings, id)
                    && _.has(settings[id], 'company')
                ) {
                    company = { ...company, ...settings[id].company };
                }

                this.setState({ edit: { id: 0, field: '', value: '' } });
                this.showLightbox('company', {
                    id,
                    field,
                    data: company,
                    errors: {},
                });
                return;
            } else if (type === 'address') {
                let address = value;

                if (
                    _.has(settings, id)
                    && _.has(settings[id], field)
                ) {
                    address = { ...address, ...settings[id][field] };
                }

                this.setState({ edit: { id: 0, field: '', value: '' } });
                this.showLightbox('address', {
                    id,
                    field,
                    data: address,
                    errors: {},
                });
                return;
            }
        }

        this.setState({ edit: { id, field, value } });

        setTimeout(() => this.editRef.current.focus(), 100);
    }

    /**
     * Generovanie fieldu.
     *
     * @param {number} id
     * @param {string} field
     * @param {string} from
     */
    generateField(id, field, from) {
        this.onChangeValue(id, field, replaceDia(from.toLowerCase()).replace(/[^0-9a-z]+/ig, '-'));
    }

    /**
     * Event po zmene prekladu.
     *
     * @param {number} quantity
     * @param {string} price
     */
    onChangePrice(quantity, price) {
        const { lightbox } = this.state;

        this.setState({
            multipriceQuantity: '',
            lightbox: { ...lightbox, multiprice: { ...lightbox.multiprice, prices: { ...lightbox.multiprice.prices, [quantity]: price } } },
        });
    }

    /**
     * Event po zmene prekladu.
     *
     * @param {number} value
     */
    onChangePriceQuantity(value) {
        this.setState({ multipriceQuantity: value });
    }

    /**
     * Pridanie ceny.
     */
    addPrice() {
        const { multipriceQuantity } = this.state;

        this.onChangePrice(toNumber(multipriceQuantity), '');
    }

    /**
     * Zmazeme cenu.
     *
     * @param {number} quantity
     */
    deletePrice(quantity) {
        const { lightbox } = this.state;

        this.setState({
            lightbox: { ...lightbox, multiprice: {
                ...lightbox.multiprice,
                prices: _.omit(lightbox.multiprice.prices, [quantity]),
            } },
        });
    }

    /**
     * Event po zmene udaju na adrese.
     *
     * @param {string} key
     * @param {string} value
     */
    onChangeAddressData(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, address: { ...lightbox.address, data: { ...lightbox.address.data, [key]: value } } } });
    }

    /**
     * Event po zmene udaju na firme.
     *
     * @param {string} key
     * @param {string} value
     */
    onChangeCompanyData(key, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, company: { ...lightbox.company, data: { ...lightbox.company.data, [key]: value } } } });
    }

    /**
     * Ulozenie adresy.
     */
    saveAddress() {
        const { lightbox } = this.state;

        // Zmenime polozku
        this.onChangeValue(lightbox.address.id, lightbox.address.field, lightbox.address.data);
        this.closeLightbox('address');
    }

    /**
     * Ulozenie firmy.
     */
    saveCompany() {
        const { lightbox } = this.state;

        // Zmenime polozku
        this.onChangeValue(lightbox.company.id, lightbox.company.field, lightbox.company.data);
        this.closeLightbox('company');
    }

    /**
     * Event po zmene editovanej bunky.
     *
     * @param {string|array} value
     */
    onChangeEditValue(value) {
        const { edit } = this.state;

        this.onChangeValue(edit.id, edit.field, value);
    }

    /**
     * Event po zmene editovanej bunky.
     *
     * @param {string} code
     * @param {string} value
     */
    onChangeEditTranslation(code, value) {
        const { data, edit, settings } = this.state;

        let allTranslations = {};

        _.forEach(data.items, ({ id, data }) => {
            if (id === edit.id) {
                allTranslations = data.translations;
            }
        });

        if (
            _.has(settings, edit.id)
            && _.has(settings[edit.id], 'translations')
        ) {
            allTranslations = { ...allTranslations, ...settings[edit.id].translations };
        }

        if (!_.has(allTranslations, edit.field)) {
            allTranslations = { ...allTranslations, [edit.field]: {} };
        }

        // Vygenerujeme default preklady
        const defaultTranslations = _.reduce(data.languages, (result, { code }) => ({ ...result, [code]: '' }), {});

        allTranslations = { ...allTranslations, [edit.field]: { ...defaultTranslations, ...allTranslations[edit.field], [code]: value } };

        // Zmenime polozku
        this.onChangeValue(edit.id, 'translations', allTranslations);
    }

    /**
     * Event po zmene hodnoty.
     *
     * @param {number} id
     * @param {string} field
     * @param {string|array|Object} value
     */
    onChangeValue(id, field, value) {
        let { edit, settings, updated } = this.state;

        if (!_.has(settings, id)) {
            // Nie je zadany riadok
            settings = { ...settings, [id]: {} };
        }

        if (!_.includes(updated, id)) {
            // Nie je zadane
            updated = [ ...updated, id ];
        }

        let editValue = value;
        let additional = {};

        if (field === 'translations' && edit.field !== '') {
            // Preklady
            editValue = value[edit.field];

            // Ako default dame sk
            additional = { [edit.field]: editValue.sk };
        }


        this.setState({
            edit: { ...edit, value: editValue },
            settings: { ...settings, [id]: { ...settings[id], [field]: value, ...additional } },
            updated,
        });
    }

    /**
     * Upload file.
     *
     * @param {number} id
     * @param {Object} file
     */
    uploadFile(id, file) {
        const { type } = this.state;

        // Ulozime
        request(`/${type}/adminUpload/${id}`, file, 'FILE').then(response => {
            const { status } = response.data;

            if (status !== 'success') {
                this.showSnackbar('error', 'Nastala chyba');
                return;
            }

            window.location.reload();
        });
    }

    /**
     * Event po zmene translate html.
     *
     * @param {Object} translations
     */
    onSaveTranslateHtml(translations) {
        const { data, settings, lightbox } = this.state;

        let allTranslations = {};

        _.forEach(data.items, ({ id, data }) => {
            if (id === lightbox.translate_html.id) {
                allTranslations = data.translations;
            }
        });

        if (
            _.has(settings, lightbox.translate_html.id)
            && _.has(settings[lightbox.translate_html.id], 'translations')
        ) {
            allTranslations = { ...allTranslations, ...settings[lightbox.translate_html.id].translations };
        }

        // Zmenime polozku
        this.onChangeValue(
            lightbox.translate_html.id,
            'translations',
            { ...allTranslations, ...{ [lightbox.translate_html.field]: translations } }
        );

        this.closeLightbox('translate_html');
    }

    /**
     * Ulozenie cien.
     */
    savePrices() {
        const { lightbox } = this.state;

        // Vytiahneme ceny
        const prices = _.reduce(lightbox.multiprice.prices, (result, price, quantity) => {
            if (toNumber(price) > 0) {
                return { ...result, [quantity]: price };
            }

            return result;
        }, {});

        // Zmenime polozku
        this.onChangeValue(lightbox.multiprice.id, 'prices', prices);

        this.closeLightbox('multiprice');
    }

    /**
     * Ulozime.
     */
    save() {
        const { type, data, updated, deleted, settings } = this.state;

        if (type === 'settings') {
            // Nastavenia
            const errors = _.reduce(data, (result, value, name) => {
                if (!_.includes(updated, name)) {
                    // Editujeme a nie je oznacene ako upravovane
                    return result;
                }

                if (isEmptyString(value)) {
                    // Je prazdne
                    return [ ...result, name ];
                }

                return result;
            }, []);

            if (!_.isEmpty(errors)) {
                // Mame errory
                this.setState({ errors });
                return;
            }

            this.setState({ errors: [], loading: true });

            // Nastavenia
            const settingsData = _.reduce(_.omit(data, ['items']), (result, value, key) => {
                if (_.has(settings, key)) {
                    return { ...result, [key]: settings[key].value };
                }

                return { ...result, [key]: value };
            }, {});

            // Ulozime
            request('/settings/adminSave', settingsData, 'POST').then(response => {
                const { status } = response.data;

                if (status !== 'success') {
                    this.showSnackbar('error', 'Nastala chyba');
                    return;
                }

                window.location.reload();
            });

            return;
        }

        // Prejdeme polozky a zvalidujeme
        const errors = _.reduce(data.items, (result, { id }) => {
            if (id > 0 && !_.includes(updated, id)) {
                // Editujeme a nie je oznacene ako upravovane
                return result;
            }

            if (!_.has(settings, id)) {
                // Neexistuje
                return result;
            }

            // Prejdeme fieldy
            _.each(settings[id], (value, field) => {
                if (
                    _.has(data.empty.fields, field)
                    && !_.has(data.empty.fields[field], 'not_required')
                    && data.empty.fields[field].type !== 'translated_html_text'
                    && (
                        (!_.isArray(value) && isEmptyString(value))
                        || (_.isArray(value) && _.isEmpty(value))
                    )
                ) {
                    // Je required a je prazdny
                    result = [ ...result, `${id}-${field}` ];
                }
            });

            return result;
        }, []);

        if (!_.isEmpty(errors)) {
            // Mame errory
            this.setState({ errors });
            return;
        }

        this.setState({ errors: [], loading: true });

        const items = _.reduce(data.items, (result, item) => {
            if (item.id > 0 && !_.includes(updated, item.id)) {
                // Editujeme a nie je oznacene ako upravovane
                return result;
            }

            if (!_.has(settings, item.id)) {
                // Neexistuje
                return result;
            }

            return [ ...result, { id: item.id, data: settings[item.id] } ];
        }, []);

        if (items.length === 0 && _.isEmpty(deleted)) {
            this.setState({ loading: false });
            return;
        }

        // Ulozime
        request(`/${type}/adminSave`, { items, deleted_ids: deleted }, 'POST').then(response => {
            const { status } = response.data;

            if (status !== 'success') {
                this.setState({ loading: false });
                this.showSnackbar('error', 'Nastala chyba');
                return;
            }

            window.location.reload();
        });
    }

    /**
     * Import prekladu.
     *
     * @param {string} code
     * @param {Object} file
     */
    importLanguage(code, file) {
        // Ulozime
        request(`/languages/adminImport/${code}`, file, 'FILE').then(response => {
            const { status } = response.data;

            if (status !== 'success') {
                this.showSnackbar('error', 'Nastala chyba');
                return;
            }

            window.location.reload();
        });
    }

    /**
     * Akceptujeme preklady.
     */
    acceptLanguages() {
        request('/languages/adminAccept').then(response => {
            const { status } = response.data;

            if (status !== 'success') {
                this.showSnackbar('error', 'Nastala chyba');
                return;
            }

            window.location.reload();
        });
    }

    /**
     * Stiahneme export prekladov.
     *
     * @param {string} code
     */
    downloadExportLanguage(code) {
        window.open(`${getUrl()}/languages/adminExport/${code}`, '_blank');
    }

    /**
     * Rendrujeme edit.
     *
     * @return {JSX.Element}
     */
    renderEdit() {
        const { data, edit } = this.state;

        if (!_.has(data, 'empty')) {
            return <Input
                value={edit.value}
                onChange={value => this.onChangeEditValue(value)}
                variant="outlined"
                inputRef={this.editRef}
            />
        }

        if (!_.has(data.empty.fields, edit.field)) {
            // Neexistuje
            return null;
        }

        // Vytiahneme data fieldu
        let field = data.empty.fields[edit.field];

        switch (field.type) {
            case 'id':
                return <Select
                    value={edit.value}
                    onChange={value => this.onChangeEditValue(value)}
                    options={_.mapValues(data[field.from], value => getText(value))}
                    variant="outlined"
                    allowEmpty={_.has(field, 'not_required')}
                />;

            case 'ids_array':
                return <Select
                    value={_.join(edit.value, '===')}
                    onChange={value => this.onChangeEditValue(_.split(value, '==='))}
                    options={_.mapValues(data[field.from], value => getText(value))}
                    variant="outlined"
                    allowEmpty={_.has(field, 'not_required')}
                    multiple
                />;

            case 'translated_text':
                // Preklady textov
                const defaultTranslations = _.reduce(data.languages, (result, { code }) => ([ ...result, code ]), []);

                return (
                    <div className="inputs">
                        {_.map(edit.value, (value, key) => {
                            if (!_.includes(defaultTranslations, key)) {
                                // Nezobrazujeme
                                return null;
                            }

                            return (
                                <Input
                                    label={key}
                                    value={value}
                                    onChange={value => this.onChangeEditTranslation(key, value)}
                                    variant="outlined"
                                    inputRef={key === 'sk' ? this.editRef : null}
                                    key={key}
                                />
                            );
                        })}
                    </div>
                );

            default:
                return <Input
                    value={edit.value}
                    onChange={value => this.onChangeEditValue(value)}
                    variant="outlined"
                    inputRef={this.editRef}
                />;
        }
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { type, data, updated, deleted, loading, lightbox, multipriceQuantity } = this.state;

        if (_.isEmpty(data)) {
            // Nemame data
            return this.renderLoading();
        }

        // Vytiahneme stlpce
        const columns = this.getColumns();

        // Vytiahneme riadky
        const rows = this.getRows();

        return (
            <div className="admin">
                <ButtonGroup variant="contained" className="admin__menu">
                    {_.map(this.sections, (name, key) => <Button
                        onClick={() => this.redirect(`/full-admin/${key}`)}
                        className={key === type ? 'active' : ''}
                        key={key}
                    >{name}</Button>)}
                </ButtonGroup>
                <div className="admin__content">
                    <div className="admin__content__edit">
                        {this.renderEdit()}
                        <div className="admin__content__edit__buttons">
                            {type === 'languages' && _.has(data, 'new_translantions') && !_.isEmpty(data.new_translantions) ? <ButtonBase
                                onClick={() => this.acceptLanguages()}
                                disabled={loading}
                            >Schválit</ButtonBase> : null}
                            {type !== 'settings' && type !== 'orders' ? <ButtonBase
                                onClick={() => this.add()}
                            >Pridat</ButtonBase> : null}
                            <ButtonBase
                                onClick={() => this.save()}
                                disabled={_.isEmpty(updated) && _.isEmpty(deleted)}
                            >{loading ? this.renderButtonLoading() : 'Ulozit'}</ButtonBase>
                        </div>
                    </div>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        onCellClick={({ id, field, value, colDef }) => this.editCell(id, field, value)}
                        onSelectionModelChange={updated => this.setState({ updated })}
                        selectionModel={updated}
                        rowHeight={38}
                        checkboxSelection
                        disableSelectionOnClick
                        hideFooter
                        className="admin__content__grid"
                    />
                </div>
                {!_.isEmpty(data) && !_.isEmpty(lightbox.translate_html) ? this.renderLightbox(
                    'translate_html',
                    'Překlady',
                    <div className="translate-html-lightbox">
                        <TranslateHtml
                            languages={data.languages}
                            tab={lightbox.translate_html.tab}
                            translation={lightbox.translate_html.translation}
                            translations={lightbox.translate_html.translations}
                            onSave={data => this.onSaveTranslateHtml(data)}
                        />
                    </div>,
                    '',
                    'Zrušit',
                ) : null}
                {!_.isEmpty(data) && !_.isEmpty(lightbox.multiprice) ? this.renderLightbox(
                    'multiprice',
                    'Ceny',
                    <div className="prices-lightbox">
                        {_.map(lightbox.multiprice.prices, (price, quantity) => <div className="prices-lightbox__price" key={quantity}>
                            <Input
                                value={quantity}
                                variant="outlined"
                                label={'Počet ks'}
                                disabled
                            />
                            <Input
                                value={price}
                                onChange={value => this.onChangePrice(quantity, value)}
                                variant="outlined"
                                label={'Cena'}
                            />
                            <IconButton onClick={() => this.deletePrice(quantity)}>
                                <DeleteIcon />
                            </IconButton>
                        </div>)}
                        <div className="prices-lightbox__add">
                            <Input
                                onChange={value => this.onChangePriceQuantity(value)}
                                value={multipriceQuantity}
                                variant="outlined"
                                placeholder={'Počet ks'}
                                type="number"
                            />
                            <Button onClick={() => this.addPrice()}>{'Pridat'}</Button>
                        </div>
                    </div>,
                    'Uložit',
                    'Zrušit',
                    () => this.savePrices(),
                ) : null}
                {!_.isEmpty(data) && !_.isEmpty(lightbox.company) ? this.renderLightbox(
                    'company',
                    'Firma',
                    <div className="company-lightbox">
                        <Input
                            label={'IČO'}
                            value={lightbox.company.data.ico}
                            onChange={value => this.onChangeCompanyData('ico', value)}
                            error={_.has(lightbox.company.errors, 'ico') ? lightbox.company.errors.ico : ''}
                            variant="outlined"
                        />
                        <Input
                            label={'DIČ'}
                            value={lightbox.company.data.dic}
                            onChange={value => this.onChangeCompanyData('dic', value)}
                            error={_.has(lightbox.company.errors, 'dic') ? lightbox.company.errors.dic : ''}
                            variant="outlined"
                        />
                        <Input
                            label={'IČ DPH'}
                            value={lightbox.company.data.ic_dph}
                            onChange={value => this.onChangeCompanyData('ic_dph', value)}
                            error={_.has(lightbox.company.errors, 'ic_dph') ? lightbox.company.errors.ic_dph : ''}
                            variant="outlined"
                        />
                        <Input
                            label={'VIES datum'}
                            value={lightbox.company.data.vies_date}
                            onChange={value => this.onChangeCompanyData('vies_date', value)}
                            error={_.has(lightbox.company.errors, 'vies_date') ? lightbox.company.errors.vies_date : ''}
                            variant="outlined"
                        />
                        <Input
                            label={'VIES token'}
                            value={lightbox.company.data.vies_token}
                            onChange={value => this.onChangeCompanyData('vies_token', value)}
                            error={_.has(lightbox.company.errors, 'vies_token') ? lightbox.company.errors.vies_token : ''}
                            variant="outlined"
                        />
                    </div>,
                    'Uložit',
                    'Zrušit',
                    () => this.saveCompany(),
                    false,
                    false
                ) : null}
                {!_.isEmpty(data) && !_.isEmpty(lightbox.address) ? this.renderLightbox(
                    'address',
                    lightbox.address.field === 'address' ? 'Adresa' : 'Dodací',
                    <div className="address-lightbox">
                        <div className="address-lightbox__panels">
                            <div className="address-lightbox__panels__panel">
                                <Input
                                    label={'Společnosť'}
                                    value={lightbox.address.data.company_name}
                                    onChange={value => this.onChangeAddressData('company_name', value)}
                                    error={_.has(lightbox.address.errors, 'company_name') ? lightbox.address.errors.company_name : ''}
                                    variant="outlined"
                                />
                                <Input
                                    label={'Jméno'}
                                    value={lightbox.address.data.name}
                                    onChange={value => this.onChangeAddressData('name', value)}
                                    error={_.has(lightbox.address.errors, 'name') ? lightbox.address.errors.name : ''}
                                    variant="outlined"
                                />
                                <Input
                                    label={'Telefon'}
                                    value={lightbox.address.data.phone}
                                    onChange={value => this.onChangeAddressData('phone', value)}
                                    error={_.has(lightbox.address.errors, 'phone') ? lightbox.address.errors.phone : ''}
                                    variant="outlined"
                                    required
                                />
                                {_.has(lightbox.address.data, 'email') ? <Input
                                    label={'E-mailová adresa'}
                                    value={lightbox.address.data.email}
                                    onChange={value => this.onChangeAddressData('email', value)}
                                    error={_.has(lightbox.address.errors, 'email') ? lightbox.address.errors.email : ''}
                                    variant="outlined"
                                    required
                                /> : null}
                                {_.has(lightbox.address.data, 'comment') ? <Input
                                    label={'Poznámka k objednávce'}
                                    value={lightbox.address.data.comment}
                                    onChange={value => this.onChangeAddressData('comment', value)}
                                    variant="outlined"
                                    multiline
                                /> : null}
                            </div>
                            <div className="address-lightbox__panels__panel">
                                <Input
                                    label={'Ulice a č.p.'}
                                    value={lightbox.address.data.address}
                                    onChange={value => this.onChangeAddressData('address', value)}
                                    variant="outlined"
                                    required
                                    error={_.has(lightbox.address.errors, 'address') ? lightbox.address.errors.address : ''}
                                />
                                <Input
                                    label={'Město'}
                                    value={lightbox.address.data.city}
                                    onChange={value => this.onChangeAddressData('city', value)}
                                    error={_.has(lightbox.address.errors, 'city') ? lightbox.address.errors.city : ''}
                                    variant="outlined"
                                    required
                                />
                                <Input
                                    label={'PSČ'}
                                    value={lightbox.address.data.zip}
                                    onChange={value => this.onChangeAddressData('zip', value)}
                                    error={_.has(lightbox.address.errors, 'zip') ? lightbox.address.errors.zip : ''}
                                    variant="outlined"
                                    required
                                />
                                <Select
                                    label={'Země'}
                                    options={data.countries}
                                    value={lightbox.address.data.country}
                                    onChange={value => this.onChangeAddressData('country', value)}
                                    allowEmpty={false}
                                    variant="outlined"
                                    required
                                />
                            </div>
                        </div>
                    </div>,
                    'Uložit',
                    'Zrušit',
                    () => this.saveAddress(),
                    false,
                    false
                ) : null}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ eshop }) => ({ eshop });

export default withCookies(connect(stateToProps)(AdminScreen));
