import React from 'react';
import _ from 'lodash';
import { Navigate } from './Navigate';
import { __, formatAmount, getImageUrl, getPrice, getText } from '../functions';
import '../assets/styles/delivery-payment.css';

/**
 * Delivery payment.
 */
class DeliveryPayment extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        eshop: {},
        country: 'sk',
    };

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { eshop, country } = this.props;

        return (
            <div className="delivery-payment">
                <div className="delivery-payment__table">
                    <h2 className="delivery-payment__table__title">{__('Doprava')}</h2>
                    <div className="delivery-payment__table__rows">
                        <div className="delivery-payment__table__rows__row">
                            <div className="delivery-payment__table__rows__row__column">{__('Dopravca')}</div>
                            <div className="delivery-payment__table__rows__row__column">{__('Druh dopravy')}</div>
                            <div className="delivery-payment__table__rows__row__column">{__('Informácie')}</div>
                            <div className="delivery-payment__table__rows__row__column">{__('Cena s DPH')}</div>
                            <div className="delivery-payment__table__rows__row__column">{__('Doba doručenia')}</div>
                        </div>
                        {_.map(this.getDelivers(country), (item, id) => {
                            const name = getText(item.name);

                            return (
                                <div className="delivery-payment__table__rows__row" key={id}>
                                    <div className="delivery-payment__table__rows__row__column">
                                        {item.image !== '' ? <img src={getImageUrl(item.image)} alt={name} /> : null}
                                    </div>
                                    <div className="delivery-payment__table__rows__row__column">{name}</div>
                                    <div
                                        className="delivery-payment__table__rows__row__column"
                                        dangerouslySetInnerHTML={{ __html: getText(item.info) }}
                                    />
                                    <div className="delivery-payment__table__rows__row__column">
                                        {formatAmount(getPrice(item.price))} {__('s DPH')}
                                    </div>
                                    <div className="delivery-payment__table__rows__row__column">{getText(item.delivery_text)}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="delivery-payment__table payments">
                    <h2 className="delivery-payment__table__title">{__('Platba')}</h2>
                    <div className="delivery-payment__table__rows">
                        <div className="delivery-payment__table__rows__row">
                            <div className="delivery-payment__table__rows__row__column">{__('Druh platby')}</div>
                            <div className="delivery-payment__table__rows__row__column">{__('Informácie')}</div>
                        </div>
                        {_.map(this.getPayments(country), (item, id) => {
                            return (
                                <div className="delivery-payment__table__rows__row" key={id}>
                                    <div className="delivery-payment__table__rows__row__column">{getText(item.name)}</div>
                                    <div
                                        className="delivery-payment__table__rows__row__column"
                                        dangerouslySetInnerHTML={{ __html: getText(item.info) }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export { DeliveryPayment };
