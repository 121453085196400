import {
    ADD_CART_PRODUCT,
    DELETE_CART_PRODUCT,
    CHANGE_CART_PRODUCT_COUNT,
    CHANGE_CART_PAYMENT_ID,
    CHANGE_CART_DELIVERY_ID,
    FETCH_CART,
    CLEAN_CART,
    SET_CART_DISCOUNT,
    DELETE_CART_DISCOUNT,
    CHANGE_CART_VAT,
} from '../actions/types';
import _ from 'lodash';
import { toNumber } from '../functions';
import { COOKIE_CART_NAME, COOKIE_SETTINGS } from '../config';

const CART_DEFAULT_STATE = {
    items: {},
    delivery_id: 0,
    payment_id: 0,
    discount_type: '',
    discount_value: 0,
    vat: false,
    id: Math.floor(Math.random() * (10000000 - 100000 + 1) + 100000)
};

/**
 * Reducer pre kosik.
 */
const CartReducer = (state = CART_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_CART:
            // Nacitame kosik
            return { ...CART_DEFAULT_STATE, ...payload, discount_type: '', discount_value: 0, vat: false };

        case CLEAN_CART:
            // Vycistime kosik
            return CART_DEFAULT_STATE;

        case ADD_CART_PRODUCT:
            // Pridame produkt do kosika
            state = { ...state, items: { ...state.items, [Math.random()]: payload } };

            // Ulozime do cookie
            global.screen.props.cookies.set(COOKIE_CART_NAME, JSON.stringify(state), COOKIE_SETTINGS);

            return state;

        case DELETE_CART_PRODUCT:
            // Zmazeme produkt z kosika
            state = { ...state, items: _.reduce(state.items, (result, item, id) => (toNumber(id) === toNumber(payload)
                ? result
                : { ...result, [id]: item  }), {}) };

            // Ulozime do cookie
            global.screen.props.cookies.set(COOKIE_CART_NAME, JSON.stringify(state), COOKIE_SETTINGS);

            return state;

        case CHANGE_CART_PRODUCT_COUNT:
            // Zmenime pocet produktu v kosiku
            state = { ...state, items: { ...state.items, [payload.id]: {
                ...state.items[payload.id],
                count: payload.count,
            } } };

            // Ulozime do cookie
            global.screen.props.cookies.set(COOKIE_CART_NAME, JSON.stringify(state), COOKIE_SETTINGS);

            return state;

        case CHANGE_CART_PAYMENT_ID:
            // Zmenime id platby v kosiku
            return { ...state, payment_id: payload };

        case CHANGE_CART_DELIVERY_ID:
            // Zmenime id dodania v kosiku
            return { ...state, delivery_id: payload };

        case CHANGE_CART_VAT:
            // Zmenime dph v kosiku
            return { ...state, vat: payload };

        case SET_CART_DISCOUNT:
            // Nastavime zlavu
            return { ...state, discount_type: payload.type, discount_value: payload.value };

        case DELETE_CART_DISCOUNT:
            // Zrusime zlavu
            return { ...state, discount_type: '', discount_value: 0 };

        default:
            return state;
    }
};

export default CartReducer;
