import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import InfoIcon from '@mui/icons-material/Info';
import MinusIcon from '@mui/icons-material/Remove';
import PlusIcon from '@mui/icons-material/Add';
import RenameIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { ButtonBase, Tooltip } from '@mui/material';
import { Navigate } from './Navigate';
import { Input } from './Input';
import { __, getText, getImageUrl, getProductPrice, formatAmount, getPrice, isEmptyString } from '../functions';
import { changeListsProductCount, deleteListsProduct, renameListsName, deleteListsName, addCartProduct } from '../actions';
import '../assets/styles/lists.css';

/**
 * Lists.
 */
class Lists extends Navigate {
    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        activeList: null,
        lightbox: {
            rename: false,
        },
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { lists } = this.props;

        this.setState({ activeList: _.keys(lists.names)[0] });
    }

    /**
     * Event po zmene premenovania.
     *
     * @param {string} value
     */
    onChangeLightboxRename(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, rename: value } });
    }

    /**
     * Zmenime pocet kusov produktu.
     *
     * @param {number} id
     * @param {number} count
     */
    changeProductCount(id, count) {
        const { changeListsProductCount } = this.props;

        changeListsProductCount(this, id, Math.max(count, 1));
    }

    /**
     * Zmazeme produkt.
     *
     * @param {number} id
     */
    deleteProduct(id) {
        const { deleteListsProduct } = this.props;

        deleteListsProduct(this, id);
    }

    /**
     * Presun do kosika.
     */
    toCart() {
        const { lists, addCartProduct, closeLists, showCart } = this.props;
        let { activeList } = this.state;

        _.forEach(lists.items, ({ id, count, settings, listId }, key) => {
            if (listId.toString() === activeList.toString()) {
                // Je na zozname
                // Pridame do kosika
                addCartProduct(this, id, count, settings);
            }
        });

        // Zavrieme zoznamy
        closeLists();

        // Zobrazime kosik
        showCart();
    }

    /**
     * Premenovanie.
     */
    rename() {
        const { renameListsName } = this.props;
        const { activeList, lightbox } = this.state;

        if (!isEmptyString(lightbox.rename)) {
            // Mame nazov
            this.closeLightbox('rename');

            // Premenujeme
            renameListsName(this, activeList, lightbox.rename);
        }
    }

    /**
     * Zmazanie.
     */
    remove() {
        const { deleteListsName, lists, closeLists } = this.props;
        let { activeList } = this.state;

        deleteListsName(this, activeList);

        if (_.keys(lists.names).length === 1) {
            // Iba jeden zoznam, zavreme okno
            closeLists();
        } else {
            // Viacej musime dat aktivny iny zoznam
            let found = false;

            _.forEach(lists.names, (name, key) => {
                if (!found && key.toString() !== activeList.toString()) {
                    activeList = key;
                    found = true;
                }
            });

            this.setState({ activeList });
        }
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { eshop, lists } = this.props;
        const { activeList, lightbox } = this.state;

        if (activeList === null) {
            return null;
        }

        // Celkova suma
        let total = 0;

        return (
            <div className="lists-content">
                <div className="lists-content__names">
                    {_.map(lists.names, (name, key) => {
                        return (
                            <ButtonBase
                                onClick={() => this.setState({ activeList: key })}
                                key={key}
                                className={key.toString() === activeList.toString() ? 'active' : ''}
                            >
                                {name}
                            </ButtonBase>
                        );
                    })}
                </div>
                <div className="lists-content__settings">
                    <ButtonBase
                        onClick={() => this.showLightbox('rename', lists.names[activeList])}
                    >
                        <RenameIcon />
                        <span>{__('Premenovať zoznam')}</span>
                    </ButtonBase>
                    <ButtonBase
                        onClick={() => this.remove()}
                    >
                        <DeleteIcon />
                        <span>{__('Odstrániť zoznam')}</span>
                    </ButtonBase>
                </div>
                <div className="lists-content__products">
                    {_.map(lists.items, ({ id, count, settings, listId }, key) => {
                        console.log(listId, activeList);
                        if (!_.has(eshop.products, id) || listId.toString() !== activeList.toString()) {
                            return null;
                        }

                        // Vytiahneme produkt
                        const product = eshop.products[id];
                        const name = getText(product.name);

                        let extensionsPrice = 0;

                        // Prejdeme rozsirenia
                        _.each(settings.extensions, id => {
                            if (_.has(eshop.extensions, id)) {
                                extensionsPrice += eshop.extensions[id].price;
                            }
                        });

                        // Ma extensions?
                        const hasExtensions = !_.isEmpty(settings.extensions);

                        let price = (getProductPrice(product.price, count, product.prices) + extensionsPrice);
                        price *= count;
                        total += price;

                        return (
                            <div className="lists-content__products__product" key={key}>
                                <div className={`lists-content__products__product__up ${hasExtensions ? 'with-extensions' : ''}`}>
                                    <div className="lists-content__products__product__up__left">
                                        <ButtonBase
                                            onClick={() => this.deleteProduct(key)}
                                        >
                                            <DeleteIcon />
                                        </ButtonBase>
                                        <div className="lists-content__products__product__up__left__photo">
                                            {product.image !== '' ? <img src={getImageUrl(product.image)} alt={getText(product.name)} /> : null}
                                        </div>
                                    </div>
                                    <div className="lists-content__products__product__up__right">
                                        <div className="lists-content__products__product__up__right__info">
                                            <div className="lists-content__products__product__up__right__info__name">
                                                {`${name}${_.has(settings, 'variant') && settings.variant > 0 && _.has(eshop.variants, settings.variant) ? ` - ${getText(eshop.variants[settings.variant].name)}` : ''}`}
                                            </div>
                                        </div>
                                        <div className="lists-content__products__product__up__right__counter">
                                            <ButtonBase
                                                onClick={() => this.changeProductCount(key, count - 1)}
                                                className="lists-content__products__product__up__right__counter__button"
                                            >
                                                <MinusIcon />
                                            </ButtonBase>
                                            <div className="lists-content__products__product__up__right__counter__value">{count}</div>
                                            <ButtonBase
                                                onClick={() => this.changeProductCount(key, count + 1)}
                                                className="lists-content__products__product__up__right__counter__button"
                                            >
                                                <PlusIcon />
                                            </ButtonBase>
                                        </div>
                                        <div className="lists-content__products__product__up__right__price">{formatAmount(getPrice(price))}</div>
                                    </div>
                                </div>
                                {hasExtensions ? <div className="lists-content__products__product__extensions">
                                    {_.map(settings.extensions, id => {
                                        if (!_.has(eshop.extensions, id)) {
                                            return null;
                                        }

                                        let title = '';

                                        return (
                                            <div className="lists-content__products__product__extensions__extension" key={id}>
                                                <div className="lists-content__products__product__extensions__extension__name">
                                                    {getText(eshop.extensions[id].name)}
                                                    {title !== ''
                                                        ? <Tooltip title={title}><InfoIcon /></Tooltip>
                                                        : null}
                                                </div>
                                                <div className="lists-content__products__product__extensions__extension__price">
                                                    {formatAmount(getPrice(eshop.extensions[id].price))}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div> : null}
                            </div>
                        );
                    })}
                </div>
                <div className="lists-content__down">
                    <div className="lists-content__down__price">
                        <div className="lists-content__down__price__text">{__('Celkom za tovar')}</div>
                        <div className="lists-content__down__price__amount">{formatAmount(getPrice(total))}</div>
                    </div>
                    <ButtonBase
                        onClick={() => this.toCart()}
                        className="lists-content__down__button"
                        disabled={_.values(lists.items).length === 0}
                    >{__('Presunúť do košíka')}</ButtonBase>
                </div>
                {this.renderLightbox(
                    'rename',
                    __('Premenovať zoznam'),
                    lightbox.rename !== false ? <div className="lists-rename-lightbox">
                        <Input
                            value={lightbox.rename}
                            onChange={value => this.onChangeLightboxRename(value)}
                        />
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.rename(),
                )}
            </div>
        );
    }
}

const stateToProps = ({ eshop, settings, lists }) => ({ eshop, settings, lists });

export default withCookies(connect(stateToProps, {
    changeListsProductCount,
    deleteListsProduct,
    renameListsName,
    deleteListsName,
    addCartProduct,
})(Lists));
