import React from 'react';
import { ButtonBase } from '@mui/material';
import { Navigate, Input } from '../components';
import { __, isValidEmail, request } from '../functions';
import '../assets/styles/newsletter.css';

/**
 * Newsletter.
 */
class Newsletter extends Navigate {
    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        email: '',
        loading: false,
    };

    /**
     * Event po zmene emailu.
     *
     * @param {string} email
     */
    onChangeEmail(email) {
        this.setState({ email });
    }

    /**
     * Ulozime
     */
    save() {
        const { email } = this.state;

        if (!isValidEmail(email)) {
            // Neplatny email
            this.showSnackbar('error', __('Toto nie je email'));
            return;
        }

        this.setState({ loading: true });

        // Ulozime
        request(`/eshops/newsletter/${email}`).then(async response => {
            this.setState({ loading: false, email: '' });

            this.showSnackbar('success', __('Email bol pridaný'));
        });
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { email, loading } = this.state;

        return (
            <div className="newsletter">
                <div className="newsletter__left">
                    <div className="newsletter__left__text"
                         dangerouslySetInnerHTML={{ __html: __('Prihláste sa k odberu našich emailov a už vám nikdy neunikne žiadna <b>novinka alebo zľava</b>') }}
                    />
                    <div className="newsletter__left__subtext">
                        <span>{__('Vaša e–mailová adresa je u nás v bezpečí, prečítajte si naše')}</span>
                        <a href="/">{__('podmienky spracovania osobních údajov')}</a>
                    </div>
                </div>
                <div className="newsletter__right">
                    <Input
                        value={email}
                        onChange={value => this.onChangeEmail(value)}
                        type="email"
                    />
                    <ButtonBase
                        onClick={() => this.save()}
                        className="newsletter__right__button"
                    >{loading ? this.renderButtonLoading() : __('Prihlásiť sa k odberu')}</ButtonBase>
                </div>
                {this.renderSnackbar()}
            </div>
        );
    }
}

export { Newsletter };
