import _ from 'lodash';
import { FETCH_ESHOP, CHANGE_USER_DATA } from './types';
import { request } from '../functions';

/**
 * Nacitanie eshopu.
 *
 * @param {Screen} screen
 */
export const fetchEshop = screen => {
    return dispatch => request('/eshops/get').then(({ data }) => {
        global.translations = _.has(data.data, 'translations') ? data.data.translations : {};

        dispatch({ type: FETCH_ESHOP, payload: data.data });
    });
};

/**
 * Zmena user data.
 *
 * @param {Object} data
 */
export const changeUserData = data => dispatch => dispatch({ type: CHANGE_USER_DATA, payload: data });
