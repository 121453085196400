import React from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import _ from 'lodash';

const containerStyle = {
    width: '100%',
    height: '100%'
};

const center = {
    lat: 50.509396,
    lng: 18.825162
};

function Map({ apiKey, points, selected, onSelect }) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: apiKey,
    })

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={selected ? selected : center}
            zoom={selected !== null ? 12 : 7}
        >
            {_.map(points, (point, key) => <Marker
                onClick={event => onSelect(event.latLng.lat(), event.latLng.lng())}
                title={point.label}
                position={{ lat: point.lat, lng: point.lng }}
                key={key}
            />)}
        </GoogleMap>
    ) : <></>
}

export default Map;
