import { toNumber } from './toNumber';

/**
 * Vratime sumu.
 *
 * @param {string|number} amount
 *
 * @return {number}
 */
export const getPrice = amount => toNumber(amount) * global.rate;
