import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Screen, Sections, Select, DeliveryPayment } from '../components';
import { __, getText } from '../functions';
import { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists } from '../actions';
import '../assets/styles/delivery.css';

/**
 * Delivery.
 */
class DeliveryScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Doprava a platba');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        country: 'sk',
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return {Promise<void>}
     */
    async componentDidMount() {
        await super.componentDidMount();

        const { eshop } = this.props;

        if (!_.isEmpty(eshop.user)) {
            this.setState({ country: eshop.user.settings.country });
            return;
        }

        if (_.has(eshop.countries, eshop.country)) {
            this.setState({ country: eshop.country });
        }
    }

    /**
     * Event po zmene krajiny.
     *
     * @param {string} country
     */
    onChangeCountry(country) {
        this.setState({ country });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    renderScreen() {
        const { eshop } = this.props;
        const { country } = this.state;

        return (
            <div>
                <Sections links={{
                    '/': __('Domáca stránka'),
                    '': __('Doprava a platba'),
                }} />
                <div className="delivery">
                    <h1 className="delivery__title">{__('Doprava a platba')}</h1>
                    <div className="delivery__text">{__('Informácie o doprave a cene dopravy')}</div>
                    <Select
                        label={__('Krajina')}
                        options={eshop.countries}
                        value={country}
                        onChange={country => this.onChangeCountry(country)}
                        allowEmpty={false}
                        countries
                    />
                    <DeliveryPayment eshop={eshop} country={country} />
                </div>
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        return super.render();
    }
}

const stateToProps = ({ eshop, settings }) => ({ eshop, settings });

export default withCookies(connect(stateToProps, { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists })(DeliveryScreen));
