import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { Input } from './Input';

function withMyHook(Component) {
    return function WrappedComponent(props) {
        const { ref } = usePlacesWidget({
            apiKey: props.placeApiKey,
            onPlaceSelected: props.onPlaceSelected,
            options: {
                types: 'cities',
                fields: ['address_components', 'geometry.location', 'place_id', 'formatted_address'],
            },
        })

        return <Component {...props} inputRef={ref} />;
    }
}

export default withMyHook(Input);
