import _ from 'lodash';
import {
    FETCH_FAVORITES,
    CLEAN_FAVORITES,
    ADD_FAVORITES_PRODUCT,
    DELETE_FAVORITES_PRODUCT,
    CHANGE_FAVORITES_PRODUCT_COUNT,
} from './types';
import { COOKIE_FAVORITES_NAME, COOKIE_SETTINGS } from '../config';

/**
 * Nacitanie oblubenych.
 *
 * @param {Screen} screen
 */
export const fetchFavorites = (screen) => {
    let cart = screen.props.cookies.get(COOKIE_FAVORITES_NAME, COOKIE_SETTINGS);
    cart = !_.isUndefined(cart) ? cart : {};

    return dispatch => dispatch({ type: FETCH_FAVORITES, payload: cart });
};

/**
 * Vycistenie kosika.
 */
export const cleanFavorites = () => dispatch => dispatch({ type: CLEAN_FAVORITES });

/**
 * Pridame produkt do oblubenych.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {number} count
 * @param {Object} settings
 */
export const addFavoritesProduct = (screen, id, count, settings = {}) => {
    global.screen = screen;

    return dispatch => dispatch({ type: ADD_FAVORITES_PRODUCT, payload: { id, count, settings } });
};

/**
 * Odstranime produkt z oblubenych.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteFavoritesProduct = (screen, id) => {
    global.screen = screen;

    return dispatch => dispatch({ type: DELETE_FAVORITES_PRODUCT, payload: id });
};

/**
 * Upravime pocet produktu v oblubenych.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {number} count
 */
export const changeFavoritesProductCount = (screen, id, count) => {
    global.screen = screen;

    return dispatch => dispatch({ type: CHANGE_FAVORITES_PRODUCT_COUNT, payload: { id, count } });
};
