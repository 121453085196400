import _ from 'lodash';
import {
    FETCH_COMPARES,
    CLEAN_COMPARES,
    ADD_COMPARES_PRODUCT,
    DELETE_COMPARES_PRODUCT,
    CHANGE_COMPARES_PRODUCT_COUNT,
} from './types';
import { COOKIE_COMPARES_NAME, COOKIE_SETTINGS } from '../config';

/**
 * Nacitanie oblubenych.
 *
 * @param {Screen} screen
 */
export const fetchCompares = (screen) => {
    let cart = screen.props.cookies.get(COOKIE_COMPARES_NAME, COOKIE_SETTINGS);
    cart = !_.isUndefined(cart) ? cart : {};

    return dispatch => dispatch({ type: FETCH_COMPARES, payload: cart });
};

/**
 * Vycistenie kosika.
 */
export const cleanCompares = () => dispatch => dispatch({ type: CLEAN_COMPARES });

/**
 * Pridame produkt do oblubenych.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {number} count
 * @param {Object} settings
 */
export const addComparesProduct = (screen, id, count, settings = {}) => {
    global.screen = screen;

    return dispatch => dispatch({ type: ADD_COMPARES_PRODUCT, payload: { id, count, settings } });
};

/**
 * Odstranime produkt z oblubenych.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteComparesProduct = (screen, id) => {
    global.screen = screen;

    return dispatch => dispatch({ type: DELETE_COMPARES_PRODUCT, payload: id });
};

/**
 * Upravime pocet produktu v oblubenych.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {number} count
 */
export const changeComparesProductCount = (screen, id, count) => {
    global.screen = screen;

    return dispatch => dispatch({ type: CHANGE_COMPARES_PRODUCT_COUNT, payload: { id, count } });
};
