import { __ } from './__';
import { getText } from './getText';

/**
 * Vratime text na sklade produktu.
 *
 * @param {string} status
 * @param {boolean} minus
 * @param {string} minusText
 *
 * @return {string}
 */
export const getProductStockTitle = (status, minus, minusText) => {
    switch (status) {
        case 'instock':
        default:
            // Skladem
            return __('Skladom');

        case 'order':
            // Na objednavku
            minusText = getText(minusText);

            return minus && minusText !== '' ? minusText : __('Na objednávku');

        case 'unavailable':
            // Nedostupne
            return __('Vypredané');
    }
};
