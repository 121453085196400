import React from 'react';
import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import { Navigate } from './Navigate';
import { __, getUrlPath } from '../functions';
import { COOKIE_TOKEN_NAME, COOKIE_SETTINGS } from '../config';

/**
 * Screen komponenta.
 */
class Screen extends Navigate {
    /**
     * Title.
     *
     * @type {string}
     */
    title = '';

    /**
     * Komponenta bola pripojena.
     */
    async componentDidMount() {
        this.scrollToTop();

        const { cookies } = this.props;

        if (this.getPath() === '/logout') {
            // Chceme odhlasit
            // Zmazeme token
            cookies.remove(COOKIE_TOKEN_NAME, COOKIE_SETTINGS);

            window.location = getUrlPath('/obchod');
            return false;
        }

        // Nasetujeme title
        this.setTitle(this.title);

        // Nastavime token
        this.setToken();

        // Nacitame data
        await this.fetch();

        // Nastavime meta tagy
        this.setMeta();
    }

    /**
     * Nastavime token.
     */
    setToken() {
        const { cookies } = this.props;

        // Pozrieme sa ci mame ulozeny token
        const token = cookies.get(COOKIE_TOKEN_NAME, COOKIE_SETTINGS);

        global.token = !_.isUndefined(token) ? token : '';
    }

    /**
     * Prescrolujeme navrch.
     */
    scrollToTop() {
        window.scrollTo(0, 0);
    }

    /**
     * Nacitanie dat.
     */
    async fetch() {
        if (!_.has(this.props, 'fetchEshop') || !_.has(this.props, 'eshop')) {
            return;
        }

        const { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists } = this.props;

        if (!_.isEmpty(this.props.eshop)) {
            // Uz mame nacitane data
            return;
        }

        // Nacitame nastavenia
        await fetchSettings(this);

        // Nacitame kosik
        await fetchCart(this);

        // Nacitame oblubene
        await fetchFavorites(this);

        // Nacitame zoznamy
        await fetchLists(this);

        // Nacitame porovnania
        await fetchCompares(this);

        // Nacitame data
        await fetchEshop(this);

        const { eshop, settings } = this.props;

        // Mena a krajina
        const currency = _.has(eshop.currencies, settings.currency)
            ? eshop.currencies[settings.currency]
            : this.getDefaultCurrency();
        const language = _.has(eshop.languages, settings.language)
            ? eshop.languages[settings.language]
            : this.getDefaultLanguage();

        if (!_.isEmpty(currency)) {
            // Nastavime globalne
            global.currency = currency.name;
            global.currency_code = currency.code;
            global.rate = currency.rate;
        }

        if (!_.isEmpty(language)) {
            // Nastavime globalne
            global.language = language.code;

            document.documentElement.setAttribute('lang', global.language === 'sk' ? 'sk' : 'hu');
        }
    }

    /**
     * Nasetujeme title.
     *
     * @param {string} title
     */
    setTitle(title) {
        const domain_name = __('Primework');

        // Nasetujeme title
        document.title = !_.isEmpty(title) ? `${title} - ${domain_name}` : domain_name;
    }

    /**
     * Nastavime meta tady
     */
    setMeta() {
        // Zmazeme vsetky
        Array.prototype.slice.call(document.getElementsByTagName('meta')).forEach(function(item) { item.remove(); });

        const tags = [ ...[
            { charset: 'utf-8' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' }
        ], ...this.getMeta() ];

        _.forEach(tags, tag => {
            let meta = document.createElement('meta');

            _.each(tag, (value, key) => {
                meta.setAttribute(key, value);
            });

            document.getElementsByTagName('head')[0].appendChild(meta);
        });
    }

    /**
     * Vratime meta tagy.
     *
     * @return {*[]}
     */
    getMeta() {
        return [];
    }

    /**
     * Rendrovanie screenu.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        return null;
    }

    /**
     * Rendrovanie loadingu.
     *
     * @return {JSX.Element}
     */
    renderLoading() {
        return (
            <div id="screen-loading">
                <CircularProgress color="primary" size={50} />
            </div>
        );
    }

    /**
     * Hlavny render.
     *
     * @return {JSX.Element}
     */
    render() {
        if (_.has(this.props, 'eshop') && !_.isEmpty(this.props.eshop)) {
            // Su nacitane data
            return this.renderScreen();
        }

        return this.renderLoading();
    }
}

export { Screen };
