import _ from 'lodash';

/**
 * Formatovanie tel. cisla.
 *
 * @param {string} phone
 *
 * @return {string}
 */
export const formatPhone = phone => {
    phone = phone.replace(/ /g, '');

    let phoneData = [];
    let phonePart = '';

    for (let i = 0; i < phone.length; i++) {
        phonePart = `${phonePart}${phone[i]}`

        if (phonePart.length === 3) {
            phoneData = [ ...phoneData, phonePart ];
            phonePart = '';
        }
    }

    if (phonePart !== '') {
        phoneData = [ ...phoneData, phonePart ];
    }

    return _.join(phoneData, ' ');
};
