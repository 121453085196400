import _ from 'lodash';
import { toNumber } from './toNumber';

/**
 * Vratime sumu.
 *
 * @param {number} price
 * @param {number} quantity
 * @param {Object} prices
 *
 * @return {number}
 */
export const getProductPrice = (price, quantity, prices) => {
    _.each(prices, (p, q) => {
        if (quantity >= toNumber(q)) {
            price = p;
        }
    });

    return price;
};
