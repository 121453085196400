import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import { ButtonBase } from '@mui/material';
import _ from 'lodash';
import CheckIcon from '@mui/icons-material/Done';
import { Screen, Input, Checkbox } from '../components';
import { __, getText, getUrlPath, isEmptyString, isValidEmail, request } from '../functions';
import { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists } from '../actions';
import { IMAGES, COOKIE_SETTINGS, COOKIE_TOKEN_NAME } from '../config';
import '../assets/styles/register.css';

/**
 * Register.
 */
class RegisterScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Založenie účtu');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        loading: false,
        name: '',
        email: '',
        password: '',
        passwordRepeat: '',
        vop: false,
        customer: 'client',
        lightbox: {
            popup: false,
        },
    };

    /**
     * Komponenta bola pripojena.
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            if (global.token !== '') {
                window.location = getUrlPath('/obchod');
            }
        }
    }

    /**
     * Event po zmene nazvu.
     *
     * @param {string} name
     */
    onChangeName(name) {
        this.setState({ name });
    }

    /**
     * Event po zmene emailu.
     *
     * @param {string} email
     */
    onChangeEmail(email) {
        this.setState({ email });
    }

    /**
     * Event po zmene hesla.
     *
     * @param {string} password
     */
    onChangePassword(password) {
        this.setState({ password });
    }

    /**
     * Event po zmene hesla.
     *
     * @param {string} passwordRepeat
     */
    onChangePasswordRepeat(passwordRepeat) {
        this.setState({ passwordRepeat });
    }

    /**
     * Event po zmene VOP.
     *
     * @param {boolean} vop
     */
    onChangeVop(vop) {
        this.setState({ vop });
    }

    /**
     * Registracia
     */
    register() {
        const { name, email, password, passwordRepeat, vop } = this.state;

        let error = null;

        if (isEmptyString(name)) {
            // Nie je vyplneny nazov
            error = __('Meno musí byť vyplnené');
        } else if (!isValidEmail(email)) {
            // Neplatny email
            error = __('Toto nie je email');
        } else if (password.length < 8) {
            // Kratke heslo
            error = __('Heslo musí mať minimálne 8 znakov');
        } else if (password !== passwordRepeat) {
            // Hesla sa nerovnaju
            error = __('Hesla nie sú rovnaké');
        } else if (!vop) {
            // VOP
            error = __('Musíte súhlasiť s obchodnými podmienkami');
        }

        if (error !== null) {
            // Mame error
            this.showSnackbar('error', error);
            return;
        }

        this.setState({ loading: true });

        request('/users/register', { name, email, password }, 'POST').then(response => {
            const { status, data } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                // Prihlasenie sa nepodarilo
                this.showSnackbar('error', __('Email už niekto používa'));
                return;
            }

            const { cookies } = this.props;

            // Ulozime token do cookie
            cookies.set(COOKIE_TOKEN_NAME, data.token, COOKIE_SETTINGS);

            window.location = getUrlPath('/moj-ucet');
        });
    }

    /**
     * Zobrazime popup.
     *
     * @param {string} name
     */
    showPopup(name) {
        const { eshop } = this.props;

        this.showLightbox('popup', { data: getText(eshop.popups[name].text) });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    renderScreen() {
        const { loading, name, email, password, passwordRepeat, vop, lightbox, customer } = this.state;

        return (
            <div className="register">
                <div className="register__panel">
                    <div className="register__panel__up">
                        <h1 className="register__panel__up__title">{__('Založenie účtu')}</h1>
                        <div className="register__panel__up__text">{__('Získajte ako prvý prístup ku všetkým dôležitým informáciam. Máme výhody pre všetkých, či už ste bežný zákazník, firma, alebo sa plánujete stať našim B2B partnerom.')}</div>
                        <div className="register__panel__up__buttons">
                            <ButtonBase
                                onClick={() => this.setState({ customer: 'client' })}
                                className={`register__panel__up__buttons__button ${customer === 'client' ? 'active' : ''}`}
                            >{__('Zákazník')}</ButtonBase>
                            <ButtonBase
                                onClick={() => this.setState({ customer: 'company' })}
                                className={`register__panel__up__buttons__button ${customer === 'company' ? 'active' : ''}`}
                            >{__('Firma')}</ButtonBase>
                        </div>
                        <Input
                            label={customer === 'company' ? __('Názov firmy') : __('Celé meno')}
                            value={name}
                            onChange={value => this.onChangeName(value)}
                        />
                        <Input
                            label={__('Prihlasovací email')}
                            value={email}
                            onChange={value => this.onChangeEmail(value)}
                            type="email"
                        />
                        <Input
                            label={__('Heslo')}
                            value={password}
                            onChange={value => this.onChangePassword(value)}
                            type="password"
                        />
                        <Input
                            label={__('Zopakovať heslo')}
                            value={passwordRepeat}
                            onChange={value => this.onChangePasswordRepeat(value)}
                            type="password"
                        />
                        <Checkbox
                            label={<span>
                                <span>{__('Prečítal som si a súhlasím s')}</span>
                                <ButtonBase onClick={() => this.showPopup('vop')}>{__('obchodnými podmienkami')}</ButtonBase>
                                <span>{__('a')}</span>
                                <ButtonBase onClick={() => this.showPopup('privacy')}>{__('spracovaním osobních údajov')}</ButtonBase>
                            </span>}
                            value={vop}
                            onChange={value => this.onChangeVop(value)}
                        />
                    </div>
                    <div className="register__panel__down">
                        <div className="register__panel__down__login">
                            {__('Už máte svoj účet?')}
                            <ButtonBase onClick={() => this.redirect('/prihlasenie')}>{__('Prihláste sa')}</ButtonBase>
                        </div>
                        <ButtonBase
                            onClick={() => this.register()}
                            className="register__panel__down__button"
                        >{loading ? this.renderButtonLoading() : __('Vytvoriť účet')}</ButtonBase>
                    </div>
                </div>
                <div className="register__right">
                    <div className="register__right__photo">
                        <img src={IMAGES['login.png']} alt="prihlaseni" />
                    </div>
                    <h2 className="register__right__title">{__('Výhody registrácie')}</h2>
                    <div className="register__right__panel">
                        <div className="register__right__panel__item">
                            <CheckIcon />
                            <span>{__('Rýchlejší nákup vďaka uloženým údajom')}</span>
                        </div>
                        <div className="register__right__panel__item">
                            <CheckIcon />
                            <span>{__('Detailné informácie o vašich objednávkach')}</span>
                        </div>
                        <div className="register__right__panel__item">
                            <CheckIcon />
                            <span>{__('Možnosť sledovania stavu vašej objednávky')}</span>
                        </div>
                        <div className="register__right__panel__item">
                            <CheckIcon />
                            <span>{__('Prístup k vašim daňovým dokladov')}</span>
                        </div>
                        <div className="register__right__panel__item">
                            <CheckIcon />
                            <span>{__('Recenzie produktov')}</span>
                        </div>
                    </div>
                </div>
                {this.renderLightbox('popup', '', !_.isEmpty(lightbox.popup)
                    ? <div dangerouslySetInnerHTML={{ __html: lightbox.popup.data }} />
                    : null, '', __('Zrušiť'))}
                {this.renderSnackbar()}
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        return super.render();
    }
}

const stateToProps = ({ eshop, settings }) => ({ eshop, settings });

export default withCookies(connect(stateToProps, { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists })(RegisterScreen));
