import React from 'react';
import _ from 'lodash';
import SizeIcon from '@mui/icons-material/Straighten';
import { Navigate } from './Navigate';
import { __, getText } from '../functions';
import '../assets/styles/sizes.css';

/**
 * Sizes.
 */
class Sizes extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        eshop: {},
        type: '',
    };

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element|null}
     */
    render() {
        const { eshop, type } = this.props;

        if (!_.has(eshop.charts, type)) {
            return null;
        }

        // Tabulka
        const chart = eshop.charts[type];

        return (
            <div className="sizes">
                <div className="sizes__left">
                    <div className="sizes__left__photo">
                        <SizeIcon />
                    </div>
                    <div className="sizes__left__content">
                        <div className="sizes__left__content__title">{getText(chart.name)}</div>
                        <div className="sizes__left__content__text">{getText(chart.text)}</div>
                    </div>
                </div>
                <div className="sizes__right">
                    <div className="sizes__right__row">
                        <div className="sizes__right__row__cell">{__('Veľkosť')}</div>
                        {_.map(getText(chart.columns).split('|'), column => <div
                            className="sizes__right__row__cell"
                            dangerouslySetInnerHTML={{ __html: column }}
                            key={column}
                        />)}
                    </div>
                    {_.map(chart.variants, id => {
                        if (!_.has(eshop.variants, id)) {
                            return null;
                        }

                        const variant = eshop.variants[id];

                        return (
                            <div className="sizes__right__row" key={id}>
                                <div className="sizes__right__row__cell">{getText(variant.name)}</div>
                                {_.map(getText(variant.text).split('|'), column => <div className="sizes__right__row__cell" key={column}>{column}</div>)}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export { Sizes };
