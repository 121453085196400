import React, { Component } from 'react';
import PasswordOpenIcon from '@mui/icons-material/Visibility';
import PasswordClosedIcon from '@mui/icons-material/VisibilityOff';
import ErrorIcon from '@mui/icons-material/Error';
import { IconButton, TextField } from '@mui/material';
import '../assets/styles/input.css';

/**
 * Input komponenta.
 */
class Input extends Component {
    /**
     * Default props.
     *
     * @type {{
     *     type: string,
     *     label: string,
     *     value: string,
     *     maxLength: number,
     *     required: boolean,
     *     multiline: boolean,
     *     focus: boolean,
     *     disabled: boolean,
     *     error: boolean,
     *     inputRef: ref,
     *     content: null,
     *     rows: number,
     *     placeholder: string,
     *     onChange: function,
     *     icon: JSX.Element|null,
     *     variant: string,
     * }}
     */
    static defaultProps = {
        type: 'text',
        label: '',
        value: '',
        maxLength: null,
        required: false,
        multiline: false,
        focus: false,
        disabled: false,
        error: '',
        inputRef: null,
        content: null,
        rows: 0,
        minRows: null,
        placeholder: '',
        onChange: () => {},
        onKeyPress: () => {},
        onFocus: () => {},
        onBlur: () => {},
        variant: 'filled',
        right: null,
        left: null,
    };

    /**
     * Default state.
     *
     * @type {{
     *     value: string
     * }}
     */
    state = {
        value: '',
        showPassword: false,
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { value } = this.props;

        // Nasetujeme value do state z props
        this.setState({ value });
    }

    /**
     * Komponenta bola zmenena.
     *
     * @param {Object} prevProps
     * @param {Object} prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.value !== this.props.value) {
            // Nesedi hodnota v state a props, aktualizujeme
            this.setState({ value: this.props.value });
        }
    }

    /**
     * Event po zmene.
     *
     * @param {string} value
     */
    onChange(value) {
        this.setState({ value });

        this.props.onChange(value);
    }

    /**
     * Event po stlaceni klavesa.
     *
     * @param {string} value
     */
    onKeyPress(value) {
        this.props.onKeyPress(value);
    }

    /**
     * Zobrazime/schovame heslo.
     *
     * @param {boolean} showPassword
     */
    showPassword(showPassword) {
        this.setState({ showPassword: !showPassword });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const { type, label, maxLength, required, multiline, rows, focus, disabled, inputRef, placeholder, error, variant, onBlur, onFocus, minRows, left } = this.props;
        let { right } = this.props;
        const { value, showPassword } = this.state;

        let inputProps = {};

        if (maxLength !== null) {
            inputProps = { ...inputProps, maxLength };
        }

        let content = this.props.content !== null ? this.props.content : <TextField
            type={(type === 'password' && showPassword ? 'text' : type)}
            onChange={event => this.onChange(event.target.value)}
            onKeyPress={event => this.onKeyPress(event.key)}
            value={value}
            variant={variant}
            inputProps={inputProps}
            required={required}
            multiline={multiline}
            rows={rows}
            minRows={minRows}
            autoFocus={focus}
            disabled={disabled}
            inputRef={inputRef}
            placeholder={placeholder}
            fullWidth
            error={error !== ''}
            onBlur={onBlur}
            onFocus={onFocus}
        />;

        if (error !== '') {
            // Mame error
            right = <div className="input__right error-icon"><ErrorIcon /></div>
        } else if (type === 'password') {
            right = <div className="input__right-password">
                <IconButton
                    onClick={() => this.showPassword(showPassword)}
                    className="input__right__icon"
                >
                    {showPassword ? <PasswordOpenIcon /> : <PasswordClosedIcon />}
                </IconButton>
            </div>;
        }

        return (
            <div className={`input ${disabled ? 'disabled' : ''} ${left !== null ? 'left' : ''} ${right !== null ? 'right' : ''} ${error !== '' ? 'with-error' : ''}`}>
                {label !== ''
                    ? <div className="input__label">{label}</div>
                    : null}
                {left !== null ? <div className="input__left">{left}</div> : null}
                {content}
                {right !== null ? right : null}
                {error !== '' ? <div className="input__error">{error}</div> : null}
            </div>
        );
    }
}

export { Input };
