import React from 'react';
import _ from 'lodash';
import ValidIcon from '@mui/icons-material/CheckCircle';
import CardIcon from '@mui/icons-material/CreditCard';
import TransferIcon from '@mui/icons-material/AccountBalance';
import CodIcon from '@mui/icons-material/LocalShipping';
import { ButtonBase, CircularProgress } from '@mui/material';
import { Navigate } from './Navigate';
import { __, formatAmount, getImageUrl, getPrice, getText, request, toNumber } from '../functions';
import '../assets/styles/change-delivery.css';

/**
 * Change delivery.
 */
class ChangeDelivery extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        eshop: {},
        country: 'sk',
        orderId: 0,
        orderToken: '',
        paymentId: 0,
        callback: () => {},
    };

    /**
     * Default state.
     *
     * @type {{loading: boolean}}
     */
    state = {
        loading: false,
    };

    /**
     * Zmena platby.
     *
     * @param {number} id
     */
    change(id) {
        const { orderId, orderToken, callback } = this.props;

        this.setState({ loading: true });

        request(`/orders/changePayment/${orderId}/${orderToken}/${id}`).then(response => {
            const { status, data } = response.data;

            // Zavolame callback
            callback(status, data);
        });
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { country, paymentId } = this.props;
        const { loading } = this.state;

        if (loading) {
            return <div className="change-delivery"><CircularProgress color="primary" size={40} /></div>;
        }

        return (
            <div className="change-delivery">
                {_.map(this.getPayments(country), ({ name, image, price, setting }, id) => {
                    name = getText(name);

                    const active = toNumber(id) === toNumber(paymentId);

                    let svgIcon = null;

                    if (image === '') {
                        if (setting === 'cod') {
                            svgIcon = <CodIcon strokeWidth={1} />;
                        } else if (setting === 'transfer') {
                            svgIcon = <TransferIcon strokeWidth={1} />;
                        } else {
                            svgIcon = <CardIcon strokeWidth={1} />;
                        }
                    }

                    return (
                        <ButtonBase
                            onClick={!active ? () => this.change(id) : () => {}}
                            className={`change-delivery__button ${active ? 'active' : ''}`}
                            key={id}
                        >
                            {image !== ''
                                ? <img src={getImageUrl(image)} alt={name} />
                                : <div className="change-delivery__button__icon">{svgIcon}<span>{name}</span></div>}
                            <div className="change-delivery__button__price">
                                {toNumber(price) === 0 ? __('zdarma') : formatAmount(getPrice(price))}
                            </div>
                            {active ? <ValidIcon /> : null}
                        </ButtonBase>
                    );
                })}
            </div>
        );
    }
}

export { ChangeDelivery };
