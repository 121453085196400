import { FETCH_BLOG } from './types';
import { request } from '../functions';

/**
 * Nacitanie blogu.
 *
 * @param {Screen} screen
 */
export const fetchBlog = screen => {
    return dispatch => request('/blogs/get').then(({ data }) => {
        dispatch({ type: FETCH_BLOG, payload: data.data });
    });
};
