import {
    ADD_COMPARES_PRODUCT,
    DELETE_COMPARES_PRODUCT,
    CHANGE_COMPARES_PRODUCT_COUNT,
    FETCH_COMPARES,
    CLEAN_COMPARES,
} from '../actions/types';
import _ from 'lodash';
import { toNumber } from '../functions';
import { COOKIE_COMPARES_NAME, COOKIE_SETTINGS } from '../config';

const COMPARES_DEFAULT_STATE = {
    items: {},
    id: Math.floor(Math.random() * (10000000 - 100000 + 1) + 100000)
};

/**
 * Reducer pre oblubene.
 */
const ComparesReducer = (state = COMPARES_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_COMPARES:
            // Nacitame oblubene
            return { ...COMPARES_DEFAULT_STATE, ...payload };

        case CLEAN_COMPARES:
            // Vycistime oblubene
            return COMPARES_DEFAULT_STATE;

        case ADD_COMPARES_PRODUCT:
            // Pridame produkt do oblubene
            state = { ...state, items: { ...state.items, [Math.random()]: payload } };

            // Ulozime do cookie
            global.screen.props.cookies.set(COOKIE_COMPARES_NAME, JSON.stringify(state), COOKIE_SETTINGS);

            return state;

        case DELETE_COMPARES_PRODUCT:
            // Zmazeme produkt z oblubene
            state = { ...state, items: _.reduce(state.items, (result, item, id) => (toNumber(id) === toNumber(payload)
                    ? result
                    : { ...result, [id]: item  }), {}) };

            // Ulozime do cookie
            global.screen.props.cookies.set(COOKIE_COMPARES_NAME, JSON.stringify(state), COOKIE_SETTINGS);

            return state;

        case CHANGE_COMPARES_PRODUCT_COUNT:
            // Zmenime pocet produktu v oblubene
            state = { ...state, items: { ...state.items, [payload.id]: {
                        ...state.items[payload.id],
                        count: payload.count,
                    } } };

            // Ulozime do cookie
            global.screen.props.cookies.set(COOKIE_COMPARES_NAME, JSON.stringify(state), COOKIE_SETTINGS);

            return state;

        default:
            return state;
    }
};

export default ComparesReducer;
