import _ from 'lodash';

/**
 * Je string prazdny?
 *
 * @param {string} string
 *
 * @returns {boolean}
 */
export const isEmptyString = string => _.isEmpty(_.toString(string).trim());
