import _ from 'lodash';

/**
 * Nahradime diakritiku.
 *
 * @param {string} text
 *
 * @return {string}
 */
export const replaceDia = text => {
    const chars = ['ľ', 'š', 'č', 'ť', 'ž', 'ý', 'á', 'í', 'é', 'ú', 'ó', 'ĺ', 'ŕ', 'ň', 'ô', 'ä', 'ř', 'ě', 'ů'];
    const replacements = ['l', 's', 'c', 't', 'z', 'y', 'a', 'i', 'e', 'u', 'o', 'l', 'r', 'n', 'o', 'a', 'r', 'e', 'u'];

    _.each(chars, (char, key) => {
        const regex = new RegExp(char, 'g');

        text = text.replace(regex, replacements[key]);
    });

    return text;
};
