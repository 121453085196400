import React from 'react';
import _ from 'lodash';
import { ButtonBase, Slider } from '@mui/material';
import ArrowIcon from '@mui/icons-material/KeyboardArrowRight';
import { Navigate, Checkbox, Input, PopularProducts } from '../components';
import { __, formatAmount, getPrice, getText, toNumber } from '../functions';
import '../assets/styles/filter.css';

/**
 * Filter.
 */
class Filter extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        eshop: {},
        navigate: {},
        filter: {},
        onChangeFilter: () => {},
        showSection: () => {},
        showFilterStorage: false,
        showFilterCategories: false,
        showFilterVariants: false,
        showFilterPrice: false,
        categories: [],
        subcategories: [],
        onChangeCategory: () => {},
        variants: [],
        variables: [],
        subvariables: [],
        hiddenSections: {},
        minPrice: 0,
        maxPrice: 0,
        filterFromPrice: 0,
        filterToPrice: 0,
        sortContent: null,
        resetFilter: () => {},
        closeFilter: () => {},
    };

    /**
     * Reset filtru.
     */
    resetFilter() {
        const { closeFilter, resetFilter } = this.props;

        closeFilter();
        resetFilter();
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const {
            eshop,
            navigate,
            filter,
            onChangeFilter,
            showSection,
            showFilterStorage,
            showFilterCategories,
            showFilterVariants,
            showFilterPrice,
            categories,
            subcategories,
            onChangeCategory,
            variants,
            variables,
            subvariables,
            hiddenSections,
            minPrice,
            maxPrice,
            filterFromPrice,
            filterToPrice,
            sortContent,
            closeFilter,
        } = this.props;

        return (
            <div className="filter">
                {sortContent}
                <ButtonBase
                    onClick={() => showSection('categories')}
                    className={`filter__section-title ${!showFilterCategories ? 'filter__section-title-closed' : ''}`}
                    disableRipple
                >
                    <div className="filter__section-title__name">{__('Kategórie')}</div>
                    <ArrowIcon />
                </ButtonBase>
                {showFilterCategories ? <div className="filter__section">
                    <div className="filter__section__categories">
                        {_.map(categories, ({ id, name }) => {
                            // Je aktivna?
                            const active = id === filter.category;

                            return (
                                <div key={id}>
                                    <ButtonBase
                                        onClick={() => onChangeCategory(id)}
                                        className={`filter__section__categories__category ${!active ? 'filter__section__categories__category-closed' : ''} ${active && filter.subcategory === 0 ? 'active' : ''}`}
                                        disableRipple
                                    >
                                        <div className="filter__section__categories__category__name">
                                            {getText(name)}
                                        </div>
                                        <ArrowIcon />
                                    </ButtonBase>
                                    {active ? _.map(subcategories, ({ name }, subcategoryId) => {
                                        subcategoryId = toNumber(subcategoryId);

                                        // Je aktivna?
                                        const active = subcategoryId === filter.subcategory;

                                        return (
                                            <ButtonBase
                                                onClick={() => onChangeFilter('subcategory', subcategoryId)}
                                                className={`filter__section__categories__subcategory ${active ? 'active' : ''}`}
                                                key={subcategoryId}
                                                disableRipple
                                            >
                                                {getText(name)}
                                            </ButtonBase>
                                        );
                                    }) : null}
                                </div>
                            );
                        })}
                    </div>
                </div> : null}
                <ButtonBase
                    onClick={() => showSection('storage')}
                    className={`filter__section-title ${!showFilterStorage ? 'filter__section-title-closed' : ''}`}
                    disableRipple
                >
                    <div className="filter__section-title__name">{__('Vlastnosti')}</div>
                    <ArrowIcon />
                </ButtonBase>
                {showFilterStorage ? <div className="filter__section">
                    <div className="filter__section__storage">
                        <Checkbox
                            label={__('Produkty skladom')}
                            onChange={value => onChangeFilter('storage', value)}
                            value={filter.storage}
                        />
                        <Checkbox
                            label={__('Akciové produkty')}
                            onChange={() => {}}
                            value={false}
                        />
                        <Checkbox
                            label={__('Výpredajové produkty')}
                            onChange={() => {}}
                            value={false}
                        />
                        <Checkbox
                            label={__('Novinky')}
                            onChange={() => {}}
                            value={false}
                        />
                    </div>
                </div> : null}
                <ButtonBase
                    onClick={() => showSection('variants')}
                    className={`filter__section-title ${!showFilterVariants ? 'filter__section-title-closed' : ''}`}
                    disableRipple
                >
                    <div className="filter__section-title__name">{__('Veľkosť')}</div>
                    <ArrowIcon />
                </ButtonBase>
                {showFilterVariants ? <div className="filter__section">
                    <div className="filter__section__variants">
                        {_.map(variants, (count, variantId) => {
                            if (!_.has(eshop.variants, variantId)) {
                                return null;
                            }

                            return (
                                <Checkbox
                                    label={<div>{getText(eshop.variants[variantId].name)} <span className="count">({count})</span></div>}
                                    onChange={() => onChangeFilter('variants', { [variantId]: toNumber(variantId) })}
                                    value={_.has(filter.variants, variantId.toString())}
                                    key={variantId}
                                />
                            );
                        })}
                    </div>
                </div> : null}
                {_.map(variables, ({ id, name, sub }) => {
                    // Vypocitame pocet subvariant
                    const total = _.reduce(
                        sub,
                        (result, subId) => (result + (_.has(subvariables, subId) ? subvariables[subId] : 0)),
                        0
                    );

                    if (total === 0) {
                        // Nemame, nezobrazujeme
                        return null;
                    }

                    const showFilterVariable = !_.has(hiddenSections, `variable-${id}`);

                    return (
                        <div key={id}>
                            <ButtonBase
                                onClick={() => showSection(`variable-${id}`)}
                                className={`filter__section-title ${!showFilterVariable ? 'filter__section-title-closed' : ''}`}
                                disableRipple
                            >
                                <div className="filter__section-title__name">{getText(name)}</div>
                                <ArrowIcon />
                            </ButtonBase>
                            {showFilterVariable ? <div className="filter__section">
                                <div className="filter__section__variables">
                                    {_.map(sub, subId => {
                                        if (!_.has(subvariables, subId)) {
                                            // Nemame, nezobrazujeme
                                            return null;
                                        }

                                        if (!_.has(eshop.variables, subId)) {
                                            return null;
                                        }

                                        return (
                                            <Checkbox
                                                label={<div>{getText(eshop.variables[subId].name)} <span className="count">({subvariables[subId]})</span></div>}
                                                onChange={() => onChangeFilter('variables', { [id]: toNumber(subId) })}
                                                value={_.has(filter.variables, id.toString()) && filter.variables[id].toString() === subId.toString()}
                                                key={subId}
                                            />
                                        );
                                    })}
                                </div>
                            </div> : null}
                        </div>
                    );
                })}
                <ButtonBase
                    onClick={() => showSection('price')}
                    className={`filter__section-title ${!showFilterPrice ? 'filter__section-title-closed' : ''}`}
                    disableRipple
                >
                    <div className="filter__section-title__name">{__('Cena')}</div>
                    <ArrowIcon />
                </ButtonBase>
                {showFilterPrice && maxPrice > minPrice ? <div className="filter__section">
                    <Slider
                        value={filter.price}
                        onChange={event => onChangeFilter('price', event.target.value)}
                    />
                    <div className="filter__section__inputs">
                        <Input
                            value={formatAmount(getPrice(filterFromPrice), 2, ',', false)}
                            variant="filled"
                        />
                        <Input
                            value={formatAmount(getPrice(filterToPrice), 2, ',', false)}
                            variant="filled"
                        />
                        <div className="filter__section__inputs__currency">({global.currency})</div>
                    </div>
                </div> : null}
                <div className="filter__buyed">
                    <PopularProducts eshop={eshop} navigate={navigate} />
                </div>
                <div className="filter__down">
                    <div className="products__content__sort">
                        <ButtonBase
                            onClick={() => this.resetFilter()}
                        >{__('Zmazať filtre')}</ButtonBase>
                        <ButtonBase
                            onClick={() => closeFilter()}
                        >{__('Potvrdiť')}</ButtonBase>
                    </div>
                </div>
            </div>
        );
    }
}

export { Filter };
