import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import SearchIcon from '@mui/icons-material/Search';
import { ButtonBase } from '@mui/material';
import { ProductsList, Screen, Sections, Blog, Input } from '../components';
import { __, getText, toNumber } from '../functions';
import { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists } from '../actions';
import '../assets/styles/results.css';

/**
 * Vysledky vyhladavania.
 */
class ResultsScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Výsledky vyhľadávania');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        section: 'products',
        productsPage: 1,
        blogsPage: 1,
        filterCategory: 0,
        search: '',
    };

    async componentDidMount() {
        await super.componentDidMount();

        const { params } = this.props;

        this.setState({ search: params.query, section: params.type });
    }

    /**
     * Zobrazime sekciu.
     *
     * @param {string} section
     */
    showSection(section) {
        this.setState({ section });
    }

    /**
     * Event po zmene strankovania produktov.
     *
     * @param {number} page
     */
    onChangeProductsPage(page) {
        this.setState({ productsPage: page + 1 });
    }

    /**
     * Event po zmene strankovania blogob.
     *
     * @param {number} page
     */
    onChangeBlogsPage(page) {
        this.setState({ blogsPage: page + 1 });
    }

    /**
     * Event po zmene filter category.
     *
     * @param {number} filterCategory
     */
    onChangeFilterCategory(filterCategory) {
        this.scrollToTop();

        this.setState({ filterCategory, productsPage: 1, blogsPage: 1 });
    }

    /**
     * Event po zmene search.
     *
     * @param {string} search
     */
    onChangeSearch(search) {
        this.setState({ search, filterCategory: 0, productsPage: 1, blogsPage: 1 });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    renderScreen() {
        const { eshop, params, navigate } = this.props;
        const { section, productsPage, blogsPage, filterCategory, search } = this.state;

        let productsCategories = {};
        let blogsCategories = {};
        let totalProducts = 0;
        let totalBlogs = 0;

        // Vytiahneme vyhladavane produkty
        const searchedProducts = _.reduce(eshop.products, (result, p) => {
            let valid = false;

            if (getText(p.name).toLowerCase().indexOf(search) !== -1) {
                // Vyhovuje vyhladavaniu
                valid = true;
            }

            if (!valid) {
                // Nevyhovuje
                return result;
            }

            totalProducts++;

            _.forEach(p.categories, categoryId => {
                if (!_.has(productsCategories, categoryId)) {
                    productsCategories = { ...productsCategories, [categoryId]: 0 };
                }

                productsCategories = { ...productsCategories, [categoryId]: productsCategories[categoryId] + 1 };
            });

            if (filterCategory > 0 && !_.includes(p.categories, filterCategory)) {
                // Filtrujeme podla kategorie a kategoria nevyhovuje
                return result;
            }

            return [ ...result, p ];
        }, []);

        // Vytiahneme vyhladavane clanky
        const searchedBlogs = _.reduce(eshop.blogs, (result, b) => {
            let valid = false;

            if (getText(b.name).toLowerCase().indexOf(search) !== -1) {
                // Vyhovuje vyhladavaniu
                valid = true;
            }

            if (!valid) {
                // Nevyhovuje
                return result;
            }

            totalBlogs++;

            _.forEach(b.categories, categoryId => {
                if (!_.has(blogsCategories, categoryId)) {
                    blogsCategories = { ...blogsCategories, [categoryId]: 0 };
                }

                blogsCategories = { ...blogsCategories, [categoryId]: blogsCategories[categoryId] + 1 };
            });

            return [ ...result, b ];
        }, []);

        const menuContent = (
            <div className="results__menu">
                <ButtonBase
                    onClick={() => this.onChangeFilterCategory(0)}
                    className={filterCategory === 0 ? 'active' : ''}
                >
                    <span>{__('Všetko')}</span>
                    <span className="count">({section === 'products' ? totalProducts : totalBlogs})</span>
                </ButtonBase>
                {section === 'products' ? _.map(productsCategories, (count, categoryId) => {
                    if (!_.has(eshop.categories, categoryId)) {
                        return null;
                    }

                    categoryId = toNumber(categoryId);

                    return (
                        <ButtonBase
                            onClick={() => this.onChangeFilterCategory(categoryId)}
                            className={filterCategory === categoryId ? 'active' : ''}
                            key={categoryId}
                        >
                            <span>{getText(eshop.categories[categoryId].name)}</span>
                            <span className="count">({count})</span>
                        </ButtonBase>
                    );
                }) : _.map(blogsCategories, (count, categoryId) => {
                    if (!_.has(eshop.blog_categories, categoryId)) {
                        return null;
                    }

                    categoryId = toNumber(categoryId);

                    return (
                        <ButtonBase
                            onClick={() => this.onChangeFilterCategory(categoryId)}
                            className={filterCategory === categoryId ? 'active' : ''}
                            key={categoryId}
                        >
                            <span>{getText(eshop.blog_categories[categoryId].name)}</span>
                            <span className="count">({count})</span>
                        </ButtonBase>
                    );
                })}
            </div>
        );

        return (
            <div>
                <Sections links={{
                    '/': __('Domáca stránka'),
                    '': __('Výsledky vyhľadávania'),
                }} />
                <div className="results">
                    {!this.isMobile() ? menuContent : null}
                    <div className="results__content">
                        <div className="results__content__input">
                            <Input
                                label={__('Hľadať')}
                                value={search}
                                onChange={search => this.onChangeSearch(search)}
                            />
                            <SearchIcon strokeWidth={1} />
                        </div>
                        <h1 className="results__content__title">
                            {section === 'products' ? __('Nájdene produkty') :  __('Nájdené články')} <span>({section === 'products' ? totalProducts : totalBlogs})</span>
                        </h1>
                        <div className="results__content__buttons">
                            <ButtonBase
                                onClick={() => this.showSection('products')}
                                className={section === 'products' ? 'active' : ''}
                            >
                                {__('Produkty')}
                            </ButtonBase>
                            <ButtonBase
                                onClick={() => this.showSection('articles')}
                                className={section === 'articles' ? 'active' : ''}
                            >
                                {__('Články')}
                            </ButtonBase>
                        </div>
                        {this.isMobile() ? menuContent : null}
                        <div className="results__content__data">
                            {section === 'products' ? <ProductsList
                                navigate={navigate}
                                eshop={eshop}
                                items={searchedProducts}
                                page={productsPage}
                                perPage={12}
                                onNextPage={() => this.onChangeProductsPage()}
                            /> : <Blog
                                navigate={navigate}
                                eshop={eshop}
                                categories={eshop.blog_categories}
                                items={searchedBlogs}
                                page={blogsPage}
                                perPage={12}
                                onNextPage={() => this.onChangeProductsPage()}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        return super.render();
    }
}

const stateToProps = ({ eshop, settings }) => ({ eshop, settings });

export default withCookies(connect(stateToProps, { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists })(ResultsScreen));
