import { FETCH_ESHOP, CHANGE_USER_DATA } from '../actions/types';

const ESHOP_DEFAULT_STATE = [];

/**
 * Reducer pre eshop.
 */
const EshopReducer = (state = ESHOP_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_ESHOP:
            // Nacitanie eshopu
            return payload;

        case CHANGE_USER_DATA:
            // Zmena user data
            return { ...state, user: payload };

        default:
            return state;
    }
};

export default EshopReducer;
