/**
 * Vratime url path.
 *
 * @return {string}
 */
export const getUrlPath = uri => {
    if (global.language === 'hu') {
        switch (uri) {
            case '/moj-ucet/zabudnute-heslo':
                return '/my-account/lost-password';

            case '/registracia':
                return '/register';

            case '/prihlasenie':
                return '/login';

            case '/moj-ucet':
                return '/my-account';

            case '/doprava-platba':
                return '/delivery-payment';

            case '/kontakt':
                return '/contact';

            case '/predajne':
                return '/shops';

            case '/pokladna':
                return '/checkout';

            case '/obchod':
                return '/shop';

            default:
                if (uri.indexOf('moj-ucet/zabudnute-heslo') !== -1) {
                    return uri.replace('moj-ucet/zabudnute-heslo', 'my-account/lost-password');
                } else if (uri.indexOf('vysledky-vyhladavania') !== -1) {
                    return uri.replace('vysledky-vyhladavania', 'search-results');
                } else if (uri.indexOf('dakujeme') !== -1) {
                    return uri.replace('dakujeme', 'thanks');
                } else if (uri.indexOf('obchod') !== -1) {
                    return uri.replace('obchod', 'shop');
                } else if (uri.indexOf('kategoria') !== -1) {
                    return uri.replace('kategoria', 'category');
                }

                return uri;
        }
    }

    return uri;
};
