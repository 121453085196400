import React, { Component } from 'react';
import _ from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import '../assets/styles/drawer.css';

/**
 * Right drawer komponenta.
 */
class Drawer extends Component {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        title: null,
        content: null,
        contentProps: {},
        name: null,
        onClose: () => {},
        closeCallback: () => {},
    };

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        title: '',
    };

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    componentDidMount() {
        window.addEventListener('keyup', this.keyUp);
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        // Zmazeme listener
        window.removeEventListener('keyup', this.keyUp);
    }

    /**
     * Pustenie klavesi.
     *
     * @param {Object} event
     */
    keyUp = ({ key }) => {
        if (key === 'Escape') {
            // Zavrieme
            this.close();
        }
    }

    /**
     * Nastavime title.
     *
     * @param {string} title
     */
    setTitle(title) {
        this.setState({ title });
    }

    /**
     * Zavrieme.
     */
    close() {
        const { onClose, closeCallback } = this.props;

        onClose();
        closeCallback();
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { content, contentProps, name, onClose } = this.props;

        // Vytiahneme title
        const title = !_.isEmpty(this.state.title) ? this.state.title : this.props.title;

        return (
            <div>
                <div onClick={() => this.close()} className="drawer-close" />
                <div className={`drawer ${name}`}>
                    <div className="drawer__header">
                        <div className="drawer__header__title">{title}</div>
                        <IconButton
                            onClick={() => this.close()}
                            className="drawer__header__button"
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div className="drawer__content">{content({
                        ...contentProps,
                        onClose,
                        setTitle: title => this.setTitle(title),
                    })}</div>
                </div>
            </div>
        );
    }
}

export { Drawer };
