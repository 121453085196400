import _ from 'lodash';
import { isEmptyString } from './isEmptyString';
import { __ } from './__';
import { isValidEmail } from './isValidEmail';
import { isValidZip } from './isValidZip';

/**
 * Vratime checkout errors.
 *
 * @param {Object} data
 *
 * @return {Object}
 */
export const getCheckoutErrors = data => {
    let errors = {};

    if (data.company && isEmptyString(data.company_name)) {
        // Je zadana firma a je prazdny nazov firmy
        errors = { ...errors, company_name: __('Toto pole musí byť vyplnené') };
    }

    if (!data.company && isEmptyString(data.name)) {
        // Nie je zadana firma a je prazdne meno
        errors = { ...errors, name: __('Toto pole musí byť vyplnené') };
    }

    if (_.has(data, 'email') && !isValidEmail(data.email)) {
        // Neplatny email
        errors = { ...errors, email: __('Neplatný email') };
    }

    if (isEmptyString(data.address)) {
        // Prazdna adresa
        errors = { ...errors, address: __('Toto pole musí byť vyplnené') };
    }

    if (isEmptyString(data.city)) {
        // Prazdne mesto
        errors = { ...errors, city: __('Toto pole musí byť vyplnené') };
    }

    if (!isValidZip(data.zip)) {
        // Neplatne psc
        errors = { ...errors, zip: __('Neplatné PSČ') };
    }

    if (data.delivery) {
        if (data.company && isEmptyString(data.delivery_company_name)) {
            // Je zadana firma a je prazdny dodaci nazov firmy
            errors = { ...errors, delivery_company_name: __('Toto pole musí byť vyplnené') };
        }

        if (!data.company && isEmptyString(data.delivery_name)) {
            // Nie je zadana firma a je prazdne dodacie meno
            errors = { ...errors, delivery_name: __('Toto pole musí byť vyplnené') };
        }

        if (isEmptyString(data.delivery_address)) {
            // Prazdna dodacia adresa
            errors = { ...errors, delivery_address: __('Toto pole musí byť vyplnené') };
        }

        if (isEmptyString(data.delivery_city)) {
            // Prazdne mesto
            errors = { ...errors, delivery_city: __('Toto pole musí byť vyplnené') };
        }

        if (!isValidZip(data.delivery_zip)) {
            // Neplatne psc
            errors = { ...errors, delivery_zip: __('Neplatné PSČ') };
        }
    }

    return errors;
};
