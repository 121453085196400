import {
    ADD_LISTS_PRODUCT,
    DELETE_LISTS_PRODUCT,
    CHANGE_LISTS_PRODUCT_COUNT,
    FETCH_LISTS,
    CLEAN_LISTS,
    ADD_LISTS_NAME,
    DELETE_LISTS_NAME,
    RENAME_LISTS_NAME,
} from '../actions/types';
import _ from 'lodash';
import { toNumber } from '../functions';
import { COOKIE_LISTS_NAME, COOKIE_SETTINGS } from '../config';

const LISTS_DEFAULT_STATE = {
    items: {},
    names: {},
    id: Math.floor(Math.random() * (10000000 - 100000 + 1) + 100000)
};

/**
 * Reducer pre oblubene.
 */
const ListsReducer = (state = LISTS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_LISTS:
            // Nacitame oblubene
            return { ...LISTS_DEFAULT_STATE, ...payload };

        case CLEAN_LISTS:
            // Vycistime oblubene
            return LISTS_DEFAULT_STATE;

        case ADD_LISTS_NAME:
            // Pridame zoznam
            state = { ...state, names: { ...state.names, [payload.id]: payload.name } };

            // Ulozime do cookie
            global.screen.props.cookies.set(COOKIE_LISTS_NAME, JSON.stringify(state), COOKIE_SETTINGS);

            return state;

        case DELETE_LISTS_NAME:
            // Zmazeme zoznam
            state = { ...state, names: _.reduce(state.names, (result, item, id) => (id.toString() === payload.toString()
                    ? result
                    : { ...result, [id]: item  }), {}) };

            state = { ...state, items: _.reduce(state.items, (result, item, id) => (item.listId.toString() === payload.toString()
                    ? result
                    : { ...result, [id]: item  }), {}) };

            // Ulozime do cookie
            global.screen.props.cookies.set(COOKIE_LISTS_NAME, JSON.stringify(state), COOKIE_SETTINGS);

            return state;

        case RENAME_LISTS_NAME:
            // Premenujeme zoznam
            state = { ...state, names: _.reduce(state.names, (result, item, id) => {
                if (id.toString() === payload.id.toString()) {
                    // Editujeme
                    item = payload.name;
                }

                return { ...result, [id]: item };
            }, {}) };

            // Ulozime do cookie
            global.screen.props.cookies.set(COOKIE_LISTS_NAME, JSON.stringify(state), COOKIE_SETTINGS);

            return state;

        case ADD_LISTS_PRODUCT:
            // Pridame produkt do oblubene
            state = { ...state, items: { ...state.items, [Math.random()]: payload } };

            // Ulozime do cookie
            global.screen.props.cookies.set(COOKIE_LISTS_NAME, JSON.stringify(state), COOKIE_SETTINGS);

            return state;

        case DELETE_LISTS_PRODUCT:
            // Zmazeme produkt z oblubene
            state = { ...state, items: _.reduce(state.items, (result, item, id) => (toNumber(id) === toNumber(payload)
                    ? result
                    : { ...result, [id]: item  }), {}) };

            // Ulozime do cookie
            global.screen.props.cookies.set(COOKIE_LISTS_NAME, JSON.stringify(state), COOKIE_SETTINGS);

            return state;

        case CHANGE_LISTS_PRODUCT_COUNT:
            // Zmenime pocet produktu v oblubene
            state = { ...state, items: { ...state.items, [payload.id]: {
                        ...state.items[payload.id],
                        count: payload.count,
                    } } };

            // Ulozime do cookie
            global.screen.props.cookies.set(COOKIE_LISTS_NAME, JSON.stringify(state), COOKIE_SETTINGS);

            return state;

        default:
            return state;
    }
};

export default ListsReducer;
