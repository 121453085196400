import React from 'react';
import _ from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import { ButtonBase } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Navigate, Input } from '../components';
import { __, toNumber, request, formatDate, getImageUrl, isEmptyString, isValidEmail } from '../functions';
import '../assets/styles/comments.css';

/**
 * Comments.
 */
class Comments extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        title: '',
        parentType: '',
        parentId: 0,
    };

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        items: null,
        page: 1,
        perPage: 4,
        text: '',
        email: '',
        loadingAdd: false,
    };

    /**
     * Recatcha ref.
     *
     * @type {null}
     */
    recaptchaRef = null;

    /**
     * Kontruktor.
     */
    constructor() {
        super();

        this.recaptchaRef = React.createRef();
    }

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        // Nacitame komentare
        this.fetch();
    }

    /**
     * Nacitame komentare.
     */
    fetch() {
        const { parentType, parentId } = this.props;

        request(`/comments/get/${parentType}/${parentId}`).then(response => {
            const { status, data } = response.data;
            let items = [];

            if (status === 'success') {
                items = data;
            }

            this.setState({ items });
        });
    }

    /**
     * Vycistime zoznam.
     */
    clean() {
        this.setState({ items: null });
    }

    /**
     * Dalsia stranka.
     */
    nextPage() {
        const { page } = this.state;

        this.setState({ page: page + 1 });
    }

    /**
     * Event po zmene emailu.
     *
     * @param {string} email
     */
    onChangeEmail(email) {
        this.setState({ email });
    }

    /**
     * Event po zmene textu.
     *
     * @param {string} text
     */
    onChangeText(text) {
        this.setState({ text });
    }

    /**
     * Pridame komentar.
     */
    async add() {
        const { parentType, parentId } = this.props;
        const { text, email } = this.state;

        if (global.token === '' && !isValidEmail(email)) {
            // Nie je zadany email
            this.showSnackbar('error', __('Toto nie je email'));
            return;
        }

        if (isEmptyString(text)) {
            // Nie je zadany text
            this.showSnackbar('error', __('Text musí byť vyplnený'));
            return;
        }

        // Vytiahneme token
        const recaptcha = await this.recaptchaRef.current.executeAsync();

        this.setState({ loadingAdd: true });

        // Ulozime
        request(`/comments/create/${parentType}/${parentId}`, { text, email, recaptcha }, 'POST').then(async response => {
            const { status } = response.data;

            this.setState({ loadingAdd: false, email: '', text: '' });

            if (status !== 'success') {
                this.showSnackbar('error', __('Nastala chyba'));
                return;
            }

            this.showSnackbar('success', __('Komentár bol pridaný'));

            // Nacitame nanovo
            this.fetch();
        });
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { eshop, title } = this.props;
        const { items, page, perPage, email, text, loadingAdd } = this.state;

        if (items === null) {
            return <div className="comments"><CircularProgress className="comments__loading" color="primary" size={30} /></div>;
        }

        // Rozdelime komentare na strankovanie
        const chunks = _.chunk(items, perPage);

        return (
            <div className="comments">
                <div className="comments__content">
                    <h2 className="comments__content__title">{items.length} {__('komentárov k')} "{title}"</h2>
                    {_.map(chunks, (chunk, key) => {
                        if ((key + 1) > page) {
                            // Dalsia stranka
                            return null;
                        }

                        return _.map(chunk, ({ id, user_id, data, image, created }) => {
                            const userName = _.has(eshop.users, user_id) ? eshop.users[user_id].name : 'Anonym';

                            return (
                                <div className="comments__content__comment" key={id}>
                                    <div className="comments__content__comment__photo">
                                        {_.has(eshop.users, user_id)
                                            ? <img src={getImageUrl(eshop.users[user_id].image)} alt={userName} />
                                            : null}
                                    </div>
                                    <div className="comments__content__comment__data">
                                        <div className="comments__content__comment__data__header">
                                            <div className="comments__content__comment__data__header__author"><span>{userName}</span> {__('napísal')}:</div>
                                            <div className="comments__content__comment__data__header__date">{formatDate(created)}</div>
                                        </div>
                                        <div className="comments__content__comment__data__text">{data.text}</div>
                                    </div>
                                </div>
                            );
                        });
                    })}
                    {page < chunks.length ? <ButtonBase
                        onClick={() => this.nextPage()}
                        className="comments__content__button"
                    >{__('Viac')}</ButtonBase> : null}
                </div>
                <div className="comments__create">
                    <h2 className="comments__create__title">{__('Napísať komentár')}</h2>
                    <div className="comments__create__text">{__('Vaša emailová adresa nebude zverejnená.')}</div>
                    <Input
                        label={__('Komentár')}
                        value={text}
                        onChange={value => this.onChangeText(value)}
                        multiline
                        rows={3}
                    />
                    <div className="comments__create__send">
                        {global.token === '' ? <Input
                            label={__('Email')}
                            value={email}
                            onChange={value => this.onChangeEmail(value)}
                            type="email"
                        /> : null}
                        <ReCAPTCHA
                            ref={this.recaptchaRef}
                            sitekey={eshop.settings.recaptcha_apikey}
                            size="invisible"
                        />
                        <ButtonBase
                            onClick={() => this.add()}
                            className="comments__create__send__button"
                        >{loadingAdd ? this.renderButtonLoading() : __('Odoslať komentár')}</ButtonBase>
                    </div>
                </div>
                {this.renderSnackbar()}
            </div>
        );
    }
}

export { Comments };
