/**
 * Vratime status na sklade produktu.
 *
 * @param {number} stock
 * @param {boolean} storage
 * @param {boolean} minus
 *
 * @return {string}
 */
export const getProductStockStatus = (stock, storage, minus) => {
    if (stock > 0) {
        // Na sklade
        return 'instock';
    }

    if (stock === 0) {
        // Nie je na sklade
        if (!storage) {
            // Neriadi sa skladom
            return 'instock';
        }

        if (!minus) {
            // Nie je povolene do minusu
            return 'unavailable';
        }

        return 'order';
    }

    return 'instock';
};
