import _ from 'lodash';
import { isEmptyString } from './isEmptyString';

/**
 * Vratime text.
 *
 * @param {string|Object} text
 *
 * @return {string}
 */
export const getText = text => {
    if (_.isObject(text)) {
        if (_.has(text, global.language)) {
            if (isEmptyString(text[global.language].replace(/<\/?[^>]+(>|$)/g, ''))) {
                // Je prazdny preklad, vyberieme default
                return text.sk;
            }

            return text[global.language];
        }

        return text.sk;
    }

    return text;
};
