import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { ButtonBase, Chip, Rating, Tooltip } from '@mui/material';
import ArrowIcon from '@mui/icons-material/KeyboardArrowRight';
import CartIcon from '@mui/icons-material/AddShoppingCart';
import SizeIcon from '@mui/icons-material/Straighten';
import MinusIcon from '@mui/icons-material/Remove';
import PlusIcon from '@mui/icons-material/Add';
import CompareIcon from '@mui/icons-material/CompareArrows';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ListsIcon from '@mui/icons-material/PlaylistAdd';
import DogIcon from '@mui/icons-material/Pets';
import {Screen, Sections, ProductsList, Checkbox, Input, DeliveryPayment, Sizes, Select} from '../components';
import {
    __,
    getText,
    getPrice,
    formatAmount,
    getProductPrice,
    round,
    getProductStockTitle,
    toNumber,
    getImageUrl,
    isEmptyString,
    request,
    plural,
    formatDate,
    getProductStockStatus,
} from '../functions';
import {
    fetchEshop,
    fetchSettings,
    fetchCart,
    addCartProduct,
    changeSetting,
    fetchFavorites,
    fetchCompares,
    fetchLists,
    addFavoritesProduct,
    addListsProduct,
    addComparesProduct,
    addListsName,
} from '../actions';
import '../assets/styles/product.css';

/**
 * Produkt.
 */
class ProductScreen extends Screen {
    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        product: {},
        variant: 0,
        variantStatus: '',
        variantTitle: '',
        variantMax: 0,
        extensions: [],
        extensions_settings: {},
        count: 1,
        content: 'description',
        images: [],
        slide: 0,
        relatedPage: 1,
        relatedPerPage: 4,
        reviews: [],
        showAllReviews: false,
        loadingReview: false,
        lightbox: {
            review: false,
            sizes: false,
            list: false,
        },
        showDownButton: true,
    };

    /**
     * Slideshow interval.
     *
     * @type {null}
     */
    slideshow_interval = null;

    /**
     * Down button ref.
     *
     * @type {null}
     */
    downButtonRef = null;

    product = null;

    /**
     * Konstruktor.
     *
     * @param {Object} props
     */
    constructor(props) {
        super(props);

        this.downButtonRef = React.createRef();
    }

    /**
     * Komponenta bola pripojena.
     */
    async componentDidMount() {
        await super.componentDidMount();

        const { eshop, params } = this.props;

        // Najdeme produkt
        const product = _.find(eshop.products, ({ url }) => getText(url) === params.url);

        if (_.isEmpty(product)) {
            // Produkt neexistuje
            this.redirect('/obchod');
            return;
        }

        // Nacitame recenzie
        request(`/comments/get/product/${product.id}`).then(response => {
            const { status, data } = response.data;
            let items = [];

            if (status === 'success') {
                items = data;
            }

            this.setState({ reviews: items });
        });

        this.product = product;

        // Nasetujeme title
        this.setTitle(getText(product.name));

        // Nastavime meta
        this.setMeta();

        let variantStatus = '';
        let variantTitle = '';
        let variantMax = 0;

        const allvariants = _.reduce(product.variants, (result, { id }) => { return result + id; }, 0);

        if (allvariants === 0 && !_.isEmpty(product.variants)) {
            // Nemame varianty
            const variant = _.values(product.variants)[0];

            variantStatus = getProductStockStatus(variant.stock, variant.storage, variant.minus);
            variantTitle = getProductStockTitle(variantStatus, variant.minus, variant.minus_text);
            variantMax = !variant.minus ? variant.stock : 0;
        }

        // Vytiahneme obrazky
        const images = _.has(eshop.product_images, product.id) ? _.values(eshop.product_images[product.id]) : [];

        this.setState({
            product,
            variantStatus,
            variantTitle,
            variantMax,
            images: [ ...(product.image !== '' ? [product.image] : []), ...images ],
            content: _.has(params, 'review') ? 'reviews' : 'description',
        });

        if (_.has(params, 'review')) {
            setTimeout(() => this.showLightbox('review', {
                rating: 0,
                text: '',
                file: {},
                vop: false,
            }), 500);
        }

        // Pridame listener
        window.addEventListener('scroll', this.handleScroll);
    }

    /**
     * Komponenta bude odpojena.
     */
    componentWillUnmount() {
        // Odstranime listener
        window.removeEventListener('scroll', this.handleScroll);
    }

    /**
     * Handlujeme scrolovanie.
     *
     * @param {Object} event
     */
    handleScroll = event => {
        const { showDownButton } = this.state;

        const limitTop = window.scrollY;

        if (
            showDownButton
            && limitTop >= this.downButtonRef.current.offsetTop
            && limitTop <= this.downButtonRef.current.offsetTop + window.innerHeight
        ) {
            this.setState({ showDownButton: false });
        } else if (
            !showDownButton
            && (
                limitTop < this.downButtonRef.current.offsetTop
                || limitTop > this.downButtonRef.current.offsetTop + window.innerHeight
            )
        ) {
            this.setState({ showDownButton: true });
        }
    }

    /**
     * Skrolovanie po vyber varianty.
     */
    scrollToSelectVariant() {
        window.scrollTo({ top: this.downButtonRef.current.offsetTop + 10, left: 0, behavior: 'smooth' });
    }

    /**
     * Predosli obrazok.
     */
    prevImage() {
        const { slide, images } = this.state;

        let nextSlide = slide - 1;

        // Ak sme na konci tak ideme na prvy obrazok
        nextSlide = nextSlide === -1 ? (images.length - 1) : nextSlide;

        this.setState({ slide: nextSlide });
    }

    /**
     * Dalsi obrazok.
     */
    nextImage() {
        const { slide, images } = this.state;

        let nextSlide = slide + 1;

        // Ak sme na konci tak ideme na prvy obrazok
        nextSlide = nextSlide === images.length ? 0 : nextSlide;

        this.setState({ slide: nextSlide });
    }

    /**
     * Vyberieme obrazok.
     *
     * @param {number} slide
     */
    selectImage(slide) {
        this.setState({ slide });
    }

    /**
     * Nastavime pocet.
     *
     * @param {number} count
     */
    changeCount(count) {
        const { variantMax } = this.state;

        if (variantMax > 0) {
            // Obmedzene maximum
            count = Math.min(count, variantMax);
        }

        this.setState({ count: Math.max(count, 1) });
    }

    /**
     * Zmenime obsah.
     *
     * @param {string} type
     */
    changeContent(type) {
        this.setState({ content: type });
    }

    /**
     * Vyberieme variant.
     *
     * @param {number} id
     * @param {string} status
     * @param {string} title
     * @param {number} max
     */
    selectVariant(id, status, title, max) {
        const { variant } = this.state;

        this.setState({
            variant: variant !== id ? id : 0,
            variantStatus: status,
            variantTitle: title,
            variantMax: max,
        });
    }

    /**
     * Vyberieme rozsirenie.
     *
     * @param {number} id
     * @param {boolean} checked
     * @param {Object} settings
     */
    selectExtension(id, checked, settings = {}) {
        let { extensions, extensions_settings } = this.state;

        if (checked) {
            // Zaskrtavame
            extensions = [ ...extensions, id ];
            extensions_settings = { ...extensions_settings, [id]: settings };
        } else {
            // Odskrtavame
            extensions = _.without(extensions, id);
            extensions_settings = _.unset(extensions_settings, id);
        }

        this.setState({ extensions, extensions_settings });
    }

    /**
     * Dalsia stranka suvisiacich produktov.
     */
    nextRelatedPage() {
        const { relatedPage } = this.state;

        this.setState({ relatedPage: relatedPage + 1 });
    }

    /**
     * Event po zmene dat recenzie.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeReviewData(type, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, review: { ...lightbox.review, [type]: value } } });
    }

    /**
     * Pridame produkt do kosiku.
     */
    addToCart() {
        const { product, count, variant, extensions, extensions_settings } = this.state;
        const { addCartProduct, changeSetting } = this.props;

        addCartProduct(this, product.id, count, { variant, extensions, extensions_settings });

        // Zobrazime kosik
        changeSetting(this, 'showCart', true);
    }

    /**
     * Pridame produkt do oblubenych.
     */
    addToFavorites() {
        const { product, count, variant, extensions, extensions_settings } = this.state;
        const { addFavoritesProduct, changeSetting } = this.props;

        addFavoritesProduct(this, product.id, count, { variant, extensions, extensions_settings });

        // Zobrazime oblubene
        changeSetting(this, 'showFavorites', true);
    }

    /**
     * Pridame produkt do zoznamu.
     *
     * @param {number} listId
     */
    addToLists(listId) {
        const { product, count, variant, extensions, extensions_settings } = this.state;
        const { addListsProduct, changeSetting, addListsName } = this.props;

        if (listId === 'empty') {
            // Pridavame novy zoznam
            listId = Math.random();

            // Pridame zoznam
            addListsName(this, listId, __('Nový zoznam'));
        }

        addListsProduct(this, product.id, listId, count, { variant, extensions, extensions_settings });

        // Zavrieme lightbox
        this.closeLightbox('list');

        // Zobrazime zoznamy
        changeSetting(this, 'showLists', true);
    }

    /**
     * Pridame produkt do porovnania.
     */
    addToCompares() {
        const { product, count, variant, extensions, extensions_settings } = this.state;
        const { addComparesProduct, changeSetting } = this.props;

        addComparesProduct(this, product.id, count, { variant, extensions, extensions_settings });

        // Zobrazime porovnania
        changeSetting(this, 'showCompares', true);
    }

    /**
     * Event po uploade.
     *
     * @param {Object} file
     */
    onUploadFile(file) {
        let error = '';

        if (!_.includes(['image/png', 'image/jpeg'], file.type)) {
            // Neplatny typ
            error = __('Obrázok musí byť jpg, png.');
        } else if ((file.size / 1024 / 1024) > 5) {
            // Neplatny typ
            error = __('Maximálna veľkosť 5MB');
        }

        if (error !== '') {
            this.showSnackbar('error', error);
            return;
        }

        this.onChangeReviewData('file', file);
    }

    /**
     * Vratime rating.
     *
     * @return {number}
     */
    getRating() {
        const { product, reviews } = this.state;

        if (!_.isEmpty(reviews)) {
            // Mame konkretne reviews
            return _.reduce(reviews, (result, { data }) => {
                return result + data.review;
            }, 0) / reviews.length;
        }

        return product.rating;
    }

    /**
     * Ulozenie recenzie.
     */
    saveReview() {
        const { product, lightbox } = this.state;

        let error = '';

        if (isEmptyString(lightbox.review.text)) {
            // Nie je zadany text
            error = __('Text recenzie musí byť vyplnený');
        } else if (lightbox.review.rating === 0) {
            // Nie je zvolene hodnotenie
            error = __('Nezvolili ste hodnotenie');
        } else if (!lightbox.review.vop) {
            // Nesuhlas s VOP
            error = __('Musíte súhlasiť s obchodnými podmienkami');
        }

        if (error !== '') {
            // Mame error
            this.showSnackbar('error', error);
            return;
        }

        this.setState({ loadingReview: true });

        // Ulozime
        request(`/comments/create/product/${product.id}`, {
            text: lightbox.review.text,
            review: lightbox.review.rating,
        }, 'POST').then(async response => {
            const { status, data } = response.data;

            if (status !== 'success') {
                this.showSnackbar('error', __('Nastala chyba'));
                return;
            }

            if (!_.isEmpty(lightbox.review.file.name)) {
                // Uploadneme subor
                await request(`/comments/upload/${data.id}`, lightbox.review.file, 'FILE').then(response => {});
            }

            this.setState({ reviews: data.items })

            this.showSnackbar('success', __('Recenzia bola pridaná'));
            this.closeLightbox('review');
        });
    }

    /**
     * Rendrujeme popis.
     *
     * @return {JSX.Element}
     */
    renderContent() {
        const { eshop } = this.props;
        const { product, content, reviews, showAllReviews } = this.state;

        switch (content) {
            case 'care':
                // Pece
                return null;

            case 'delivery':
                // Doprava a platba
                let country = 'sk';

                if (!_.isEmpty(eshop.user)) {
                    country = eshop.user.settings.country;
                } else if (_.has(eshop.countries, eshop.country)) {
                    country = eshop.country;
                }

                return <DeliveryPayment eshop={eshop} country={country} />;

            case 'reviews':
                // Hodnotenia
                const displayReviews = showAllReviews || reviews.length <= 8 ? reviews : _.slice(reviews, 0, 8);

                // Vytiahneme statistiky
                const reviewsStats = _.reduce([5,4,3,2,1], (result, rating) => {
                    // Vytiahneme pocet recenzii s danym ratingom
                    const count = _.reduce(reviews, (result, { data }) => {
                        if (data.review === rating) {
                            return result + 1;
                        }

                        return result;
                    }, 0);

                    return { ...result, [`rating-${rating}`]: count };
                }, {});

                // Vytiahneme max count
                const maxCount = _.max(_.values(reviewsStats));

                // Povolime recenziu?
                const allowReview = _.includes(eshop.buyed_products, toNumber(product.id));

                // Vratime rating
                const rating = this.getRating();

                return (
                    <div className="product__left__reviews">
                        <h2 className="product__left__reviews__title">{__('Recenzie')}</h2>
                        <div className="product__left__reviews__stats">
                            <div className="product__left__reviews__stats__left">
                                {_.map(reviewsStats, (count, rating) => {
                                    rating = toNumber(rating.replace('rating-', ''));

                                    return (
                                        <div
                                            className="product__left__reviews__stats__left__line"
                                            key={rating}
                                        >
                                            <Rating value={rating} readOnly />
                                            <div className="product__left__reviews__stats__left__line__loader">
                                                <div
                                                    className="product__left__reviews__stats__left__line__loader__in"
                                                    style={{ width: `${round(count / maxCount * 100, 0)}%` }}
                                                />
                                            </div>
                                            <div className="product__left__reviews__stats__left__line__value">{count}</div>
                                        </div>
                                    );
                                })}
                                <div className="product__left__reviews__stats__left__text">
                                    {reviews.length} {plural(reviews.length, ['recenze', 'recenze', 'recenzí'])} {getText(product.name)}
                                </div>
                            </div>
                            <div className="product__left__reviews__stats__right">
                                <div className="product__left__reviews__stats__right__value">
                                    {formatAmount(rating, 2, ',', false)}
                                </div>
                                <Rating value={round(rating, 0)} readOnly />
                                <Tooltip title={!allowReview ? __('Najprv si musíte produkt kúpiť') : ''}>
                                    <span>
                                        <ButtonBase
                                            onClick={() => this.showLightbox('review', {
                                                rating: 0,
                                                text: '',
                                                file: {},
                                                vop: false,
                                            })}
                                            disabled={!allowReview}
                                        >{__('Pridať recenziu')}</ButtonBase>
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="product__left__reviews__items">
                            <div className="product__left__reviews__items__panel">
                                {_.map(displayReviews, (item, key) => {
                                    if (key % 2 !== 0) {
                                        // Parne nezobrazujeme
                                        return null;
                                    }

                                    return this.renderReview(item);
                                })}
                            </div>
                            <div className="product__left__reviews__items__panel">
                                {_.map(displayReviews, (item, key) => {
                                    if (key % 2 === 0) {
                                        // Neparne nezobrazujeme
                                        return null;
                                    }

                                    return this.renderReview(item);
                                })}
                            </div>
                        </div>
                        {!showAllReviews && reviews.length > 8 ? <ButtonBase
                            onClick={() => this.setState({ showAllReviews: true })}
                        >{__('Všetky recenzie')}</ButtonBase> : null}
                    </div>
                );

            case 'description':
            default:
                // Popis
                return <div
                    className="product__left__description"
                    dangerouslySetInnerHTML={{ __html: getText(product.text) }}
                />;
        }
    }

    /**
     * Rendrujeme review.
     *
     * @param {Object} item
     *
     * @return {JSX.Element}
     */
    renderReview(item) {
        const { eshop } = this.props;
        const { id, user_id, image, data, created } = item;

        const userName = _.has(eshop.users, user_id) ? eshop.users[user_id].name : '';

        return (
            <div
                className="product__left__reviews__items__panel__item"
                key={id}
            >
                <div className="product__left__reviews__items__panel__item__header">
                    <div className="product__left__reviews__items__panel__item__header__name">{userName}</div>
                    <div className="product__left__reviews__items__panel__item__header__date">{formatDate(created, 'dd.mm.yyyy')}</div>
                </div>
                <Rating value={data.review} readOnly />
                <div className="product__left__reviews__items__panel__item__text">{data.text}</div>
                {image !== '' ? <img src={getImageUrl(image)} alt="recenze" /> : null}
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    renderScreen() {
        const { eshop, navigate, lists } = this.props;
        const {
            product,
            count,
            content,
            variant,
            variantStatus,
            variantTitle,
            extensions,
            images,
            slide,
            relatedPage,
            relatedPerPage,
            lightbox,
            loadingReview,
            reviews,
            showDownButton,
        } = this.state;

        if (_.isEmpty(product)) {
            // Nie su nacitane data
            return this.renderLoading();
        }

        // Vytiahneme podobne produkty
        const relatedProducts = _.reduce(eshop.products, (result, p) => {
            let valid = false;

            _.each(product.categories, category => {
                if (_.includes(p.categories, toNumber(category))) {
                    // Vyhovuje kategoria
                    valid = true;
                }
            });

            if (!valid) {
                // Nevyhovuje
                return result;
            }

            return [ ...result, p ];
        }, []);

        const allvariants = _.reduce(product.variants, (result, { id }) => { return result + id; }, 0);

        // Celkova suma za rozsirenia
        const extensionsPrice = _.reduce(product.extensions, (result, id) => {
            if (!_.has(eshop.extensions, id)) {
                return null;
            }

            // Aktivne?
            const active = _.includes(extensions, toNumber(id));

            if (active) {
                return result + eshop.extensions[id].price;
            }

            return result;
        }, 0);

        // Celkova suma
        const totalPrice = getPrice(getProductPrice(product.price, count, product.prices) + extensionsPrice) * count;

        let links = {
            '/': __('Domáca stránka'),
            '/obchod': __('Obchod'),
        };

        _.each(eshop.categories, c => {
            const { id, parent_id } = c;

            if (_.includes(product.categories, id)) {
                // Kategoria vyhovuje
                if (parent_id === 0) {
                    // Je to parent
                    links = { ...links, [`/${getText(c.url)}`]: getText(c.name) };
                } else {
                    links = { ...links, [`/${getText(c.url)}`]: getText(c.name) };
                }
            }
        });

        const contentSections = (
            <div className="product__left__sections">
                <ButtonBase
                    onClick={() => this.changeContent('reviews')}
                    className={content === 'reviews' ? 'active' : ''}
                >{__('Recenzie')}</ButtonBase>
                <ButtonBase
                    onClick={() => this.changeContent('description')}
                    className={content === 'description' ? 'active' : ''}
                >{__('Popis produktu')}</ButtonBase>
                <ButtonBase
                    onClick={() => this.changeContent('delivery')}
                    className={content === 'delivery' ? 'active' : ''}
                >{__('Doprava')}</ButtonBase>
            </div>
        );

        const contentData = this.renderContent();
        const buyDisabled = (variant === 0 && allvariants > 0) || (allvariants === 0 && variantStatus === 'unavailable');

        let buyContent = (
            <div className="product__right__buy">
                <div className="product__right__buy__counter">
                    <ButtonBase
                        onClick={() => this.changeCount(count - 1)}
                        className="product__right__buy__counter__button"
                    >
                        <MinusIcon />
                    </ButtonBase>
                    <div className="product__right__buy__counter__value">{count}</div>
                    <ButtonBase
                        onClick={() => this.changeCount(count + 1)}
                        className="product__right__buy__counter__button"
                    >
                        <PlusIcon />
                    </ButtonBase>
                </div>
                <ButtonBase
                    onClick={buyDisabled && allvariants > 0 ? () => this.scrollToSelectVariant() : () => this.addToCart()}
                    className="product__right__buy__button"
                    disabled={buyDisabled && !this.isMobile()}
                >
                    {variant > 0 || allvariants === 0
                        ? <span><CartIcon />{!this.isMobile() ? `${__('Do košíku')} - ` : ''}{formatAmount(totalPrice)}</span>
                        : <span>{__('Vyberte variantu')}</span>}
                </ButtonBase>
            </div>
        );

        return (
            <div>
                <Sections links={{ ...links, ['']: getText(product.name) }} />
                <div className="product">
                    <div className="product__left">
                        <div className="product__left__slideshow">
                            <div className="product__left__slideshow__photos">
                                {_.map(images, (image, key) => {
                                    return (
                                        <ButtonBase
                                            onClick={() => this.selectImage(key)}
                                            className="product__left__slideshow__photos__photo"
                                            key={key}
                                        >
                                            <img
                                                src={getImageUrl(image)}
                                                alt={getText(product.name)}
                                                key={key}
                                            />
                                        </ButtonBase>
                                    );
                                })}
                            </div>
                            <div className="product__left__slideshow__photo">
                                {!_.isEmpty(images) ? <img src={getImageUrl(images[slide])} alt={getText(product.name)} /> : null}
                                {images.length > 1 ? <ButtonBase
                                    onClick={() => this.prevImage()}
                                    className="prev"
                                ><ArrowIcon strokeWidth={1} /></ButtonBase> : null}
                                {images.length > 1 ? <ButtonBase
                                    onClick={() => this.nextImage()}
                                    className="next"
                                ><ArrowIcon strokeWidth={1} /></ButtonBase> : null}
                            </div>
                        </div>
                        {!this.isMobile() ? contentSections : null}
                        {!this.isMobile() ? contentData : null}
                    </div>
                    <div className="product__right">
                        <h1 className="product__right__title">{getText(product.name)}</h1>
                        <div className="product__right__price">{formatAmount(totalPrice)}</div>
                        <div className="product__right__info">
                            <div className="product__right__info__variables">
                                {_.map(product.variables, variable => {
                                    if (!_.has(eshop.variables, variable)) {
                                        return null;
                                    }

                                    return <Chip
                                        label={getText(eshop.variables[variable].name)}
                                        key={variable}
                                    />;
                                })}
                            </div>
                            <div className="product__right__info__rating">
                                <Rating value={round(this.getRating(), 0)} readOnly />
                                <div className="product__right__info__rating__text">
                                    ({reviews.length } {plural(reviews.length, ['recenze', 'recenze', 'recenzí'])})
                                </div>
                            </div>
                        </div>
                        <div className="product__right__variants">
                            <div className={`product__right__variants__title ${variant > 0 || allvariants === 0 ? variantStatus : ''}`}>
                                {variant > 0 || allvariants === 0 ? variantTitle : __('Vyberte variantu')}
                            </div>
                            {allvariants > 0 ? <div className="product__right__variants__items">
                                {_.map(product.variants, ({ id, stock, storage, minus, minus_text }) => {
                                    if (!_.has(eshop.variants, id)) {
                                        return null;
                                    }

                                    const name = getText(eshop.variants[id].name);

                                    // Vytiahneme status
                                    const status = getProductStockStatus(stock, storage, minus);
                                    const title = `${__('Veľkosť')} ${name} - ${getProductStockTitle(status, minus, minus_text).toLowerCase()}`;

                                    return (
                                        <Tooltip
                                            title={title}
                                            key={id}
                                        >
                                            <span>
                                                <ButtonBase
                                                    onClick={() => this.selectVariant(id, status, title, !minus ? stock : 0)}
                                                    className={`product__right__variants__items__variant ${status} ${variant === id ? 'active' : ''}`}
                                                    disabled={status === 'unavailable'}
                                                >{name}</ButtonBase>
                                            </span>
                                        </Tooltip>
                                    );
                                })}
                            </div> : null}
                        </div>
                        {allvariants > 0 && product.chart > 0 ? <ButtonBase
                            onClick={() => this.showLightbox('sizes', { type: product.chart })}
                            className="product__right__table-size"
                        >
                            <SizeIcon />
                            <span>{__('Tabuľka veľkostí')}</span>
                        </ButtonBase> : null}
                        {!_.isEmpty(product.extensions) ? <div className="product__right__extensions">
                            {_.map(product.extensions, id => {
                                if (!_.has(eshop.extensions, id)) {
                                    return null;
                                }

                                // Aktivne?
                                const active = _.includes(extensions, toNumber(id));

                                return (
                                    <div className="product__right__extensions__item" key={id}>
                                        <Checkbox
                                            label={getText(eshop.extensions[id].name)}
                                            onChange={value => this.selectExtension(toNumber(id), value)}
                                            value={active}
                                        />
                                        <div className="product__right__extensions__item__price">
                                            (+{formatAmount(getPrice(eshop.extensions[id].price))})
                                        </div>
                                    </div>
                                );
                            })}
                        </div> : null}
                        <div ref={this.downButtonRef}>{buyContent}</div>
                        {showDownButton ? <div className="product__right__buy-fixed">
                            {buyContent}
                        </div> : null}
                        <div className="product__right__buttons">
                            <ButtonBase
                                onClick={buyDisabled ? () => {} : () => this.showLightbox('list')}
                            >
                                <ListsIcon />
                                <span>{__('Pridať do môjho zoznamu')}</span>
                            </ButtonBase>
                            <ButtonBase
                                onClick={buyDisabled ? () => {} : () => this.addToFavorites()}
                            >
                                <FavoriteIcon />
                                <span>{__('Pridať medzi obľúbené')}</span>
                            </ButtonBase>
                            <ButtonBase
                                onClick={buyDisabled ? () => {} : () => this.addToCompares()}
                            >
                                <CompareIcon />
                                <span>{__('Pridať do porovnania')}</span>
                            </ButtonBase>
                            <ButtonBase
                                onClick={buyDisabled ? () => {} : () => {}}
                            >
                                <DogIcon />
                                <span>{__('Strážny pes')}</span>
                            </ButtonBase>
                        </div>
                        <div
                            className="product__right__short-description"
                            dangerouslySetInnerHTML={{ __html: getText(product.short_text) }}
                        />
                    </div>
                    {this.isMobile() ? contentSections : null}
                    {this.isMobile() ? contentData : null}
                    {!_.isEmpty(relatedProducts) ? <div className="product__related">
                        <h2 className="product__related__title">{__('Podobné produkty')}</h2>
                        <ProductsList
                            navigate={navigate}
                            eshop={eshop}
                            items={relatedProducts}
                            page={relatedPage}
                            perPage={relatedPerPage}
                            onNextPage={() => this.nextRelatedPage()}
                            swiper={this.isMobile()}
                        />
                    </div> : null}
                </div>
                {this.renderLightbox(
                    'review',
                    __('Pridať recenziu'),
                    !_.isEmpty(lightbox.review) ? <div className="review-lightbox">
                        <div className="review-lightbox__text">
                            {__('Vaša e-mailová adresa nebude zverejnená. Vyžadované informácie sú označené.')}
                            <span>*</span>
                        </div>
                        <div className="review-lightbox__review-text">{__('Vaše hodnotenie')}</div>
                        <Rating
                            value={lightbox.review.rating}
                            onChange={(event, value) => this.onChangeReviewData('rating', toNumber(value))}
                            className="review-lightbox__review-rating"
                        />
                        <Input
                            label={<div>{__('Vaša recenzia')}<span>*</span></div>}
                            value={lightbox.review.text}
                            onChange={value => this.onChangeReviewData('text', value)}
                            rows={3}
                            multiline
                        />
                        <div className="review-lightbox__upload">
                            <div className="review-lightbox__upload__label">{__('Vyberte fotku (max veľkosť: 5 MB)')}</div>
                            <div className="review-lightbox__upload__button">
                                <ButtonBase
                                    onClick={() => {}}
                                >{__('Zvoliť súbor')}</ButtonBase>
                                <div className="review-lightbox__upload__button__text">
                                    {!_.isEmpty(lightbox.review.file.name) ? lightbox.review.file.name : __('Súbor nebol vybraný')}
                                </div>
                                <input type="file" onChange={event => this.onUploadFile(event.target.files[0])} />
                            </div>
                        </div>
                        {global.token === '' ? <div className="review-lightbox__inputs">
                            <Input
                                label={__('Meno')}
                            />
                            <Input
                                label={__('Email')}
                            />
                        </div> : null}
                        <div className="review-lightbox__buttons">
                            <Checkbox
                                label={<span
                                    dangerouslySetInnerHTML={{ __html: __('Súhlasim so <a href="{LINK}">spracovaním osobních údajov</a>').replace('{LINK}', '/') }}
                                />}
                                value={lightbox.review.vop}
                                onChange={value => this.onChangeReviewData('vop', value)}
                            />
                            <ButtonBase
                                onClick={() => this.saveReview()}
                            >{loadingReview ? this.renderButtonLoading() : __('Odoslať')}</ButtonBase>
                        </div>
                    </div> : null
                )}
                {this.renderLightbox(
                    'sizes',
                    '',
                    !_.isEmpty(lightbox.sizes) ? <Sizes eshop={eshop} type={lightbox.sizes.type} /> : null
                )}
                {this.renderLightbox(
                    'list',
                    __('Pridať do zoznamu'),
                    <div className="product-add-to-list-lightbox">
                        <Select
                            label={__('Moje zoznamy')}
                            options={{ ...lists.names, empty: __('Nový zoznam') }}
                            onChange={value => this.addToLists(value)}
                            allowEmpty={false}
                        />
                    </div>
                )}
                {this.renderSnackbar()}
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        return super.render();
    }
}

const stateToProps = ({ eshop, settings, cart, lists }) => ({ eshop, settings, cart, lists });

export default withCookies(connect(stateToProps, {
    fetchEshop,
    fetchSettings,
    fetchCart,
    addCartProduct,
    changeSetting,
    fetchFavorites,
    fetchCompares,
    fetchLists,
    addFavoritesProduct,
    addListsProduct,
    addComparesProduct,
    addListsName,
})(ProductScreen));
