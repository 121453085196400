import React from 'react';
import _ from 'lodash';
import { ButtonBase } from '@mui/material';
import { Navigate } from './Navigate';
import { __, formatAmount, getImageUrl, getPrice, getText } from '../functions';
import '../assets/styles/popular-products.css';

/**
 * PopularProducts.
 */
class PopularProducts extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        eshop: {},
    };

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { eshop } = this.props;

        // Vytiahneme 4 najoblubenejsich produktov
        const popularProducts = _.slice(_.orderBy(_.values(eshop.products), [product => product.ordered], ['desc']), 0, 4);

        return (
            <div className="popular-products">
                <div className="popular-products__title">{__('Najpredávanejšie')}</div>
                {_.map(popularProducts, ({ id, name, url, image, price }) => {
                    name = getText(name);

                    return (
                        <ButtonBase
                            onClick={() => this.redirect(`/obchod/${getText(url)}`)}
                            className="popular-products__product"
                            key={id}
                        >
                            <div className="popular-products__product__photo">
                                {image !== '' ? <img src={getImageUrl(image)} alt={name} /> : null}
                            </div>
                            <div className="popular-products__product__info">
                                <div className="popular-products__product__info__name">{name}</div>
                                <div className="popular-products__product__info__price">
                                    {formatAmount(getPrice(price))}
                                </div>
                            </div>
                        </ButtonBase>
                    );
                })}
            </div>
        );
    }
}

export { PopularProducts };
