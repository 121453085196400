import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { ButtonBase, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/PriorityHigh';
import ExtensionsInfoIcon from '@mui/icons-material/InfoOutlined';
import ErrorIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Done';
import CopyIcon from '@mui/icons-material/ContentCopy';
import { Screen, Input, Newsletter, ChangeDelivery } from '../components';
import {
    __,
    request,
    formatAmount,
    formatDate,
    getText,
    getImageUrl,
    getProductStockTitle,
    toFixed,
    toNumber,
    formatPhone,
    getProductStockStatus,
    getUrlPath,
} from '../functions';
import { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists } from '../actions';
import { COOKIE_SETTINGS, COOKIE_TOKEN_NAME } from '../config';
import '../assets/styles/thanks.css';

/**
 * Dakujeme.
 */
class ThanksScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Ďakujeme!');

    /**
     * Default state.
     *
     * @type {{
     *     data: Object,
     * }}
     */
    state = {
        data: {},
        password: '',
        loading: false,
        lightbox: {
            delivery: false,
        },
    };

    /**
     * Komponenta bola pripojena.
     */
    async componentDidMount() {
        await super.componentDidMount();

        const { params } = this.props;

        request(`/orders/getData/${params.id}/${params.token}`).then(response => {
            const { status, data } = response.data;

            if (status !== 'success') {
                // Objednavka neexistuje
                window.location = getUrlPath('/obchod');
                return;
            }

            this.setState({ data });
        }).catch(error => window.location = getUrlPath('/obchod'));
    }

    /**
     * Event po zmene hesla.
     *
     * @param {string} password
     */
    onChangePassword(password) {
        this.setState({ password });
    }

    /**
     * Vytvorime ucet.
     */
    createAccount() {
        const { data, password } = this.state;

        if (password.length < 8) {
            // Kratke heslo
            this.showSnackbar('error', __('Heslo musí mať minimálne 8 znakov'));
            return;
        }

        this.setState({ loading: true });

        request('/users/register', {
            name: data.order.address.name,
            email: data.order.address.email,
            password,
        }, 'POST').then(response => {
            const { status, data } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                // Prihlasenie sa nepodarilo
                this.showSnackbar('error', __('Email už niekto používa'));
                return;
            }

            const { cookies } = this.props;

            // Ulozime token do cookie
            cookies.set(COOKIE_TOKEN_NAME, data.token, COOKIE_SETTINGS);

            window.location.reload();
        });
    }

    /**
     * Skopirovanie do schranky.
     *
     * @param {string} text
     */
    copy(text) {
        // Nastavime cislo do clipboardu
        navigator.clipboard.writeText(text);

        this.showSnackbar('success', __('Skopírované do schránky'));
    }

    /**
     * Zmena platobnej metody.
     *
     * @param {string} status
     * @param {Object} order
     */
    changePayment(status, order) {
        this.closeLightbox('delivery');

        if (status === 'error') {
            this.showSnackbar('error', __('Nastala chyba'));
            return;
        }

        const { data } = this.state;

        this.setState({ data: { ...data, order: order.data } });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    renderScreen() {
        const { eshop, params } = this.props;
        const { data, password, loading, lightbox } = this.state;

        if (_.isEmpty(data)) {
            // Nie su nacitane data
            return this.renderLoading();
        }

        // Platba nastavenie
        const paymentSetting = _.has(eshop.payments, data.order.payment_id)
            ? eshop.payments[data.order.payment_id].setting
            : '';

        // Je uhradena?
        const isPaid = data.order.payment_status === 'yes';

        // Je dobierka?
        const isCod = paymentSetting === 'cod';

        // Zobrazujeme prevod?
        const showTransfer = !isPaid && paymentSetting === 'transfer';

        // Celkova suma
        let totalProducts = 0;

        return (
            <div className="thanks">
                <h1 className="thanks__title">{__('Ďakujeme!')}</h1>
                <div className="thanks__text1"
                     dangerouslySetInnerHTML={{ __html: __('Vaše objednávka číslo <b>{NUMBER}</b> byla {STATE}.')
                             .replace('{NUMBER}', data.order.number)
                             .replace('{STATE}', getText(eshop.states[data.order.state].name).toLowerCase())
                     }}
                />
                <div className="thanks__text2">
                    <span>{__('Informácie o Vašej objednávke sme odoslali aj na email')}</span>
                    <span>{data.order.address.email}</span>.
                </div>
                <div className="thanks__subtext">{__('(Pokiaľ e–mail neprišiel, prosím skontrolujte zložku Spam.)')}</div>
                <div className="thanks__order">
                    {isCod && !isPaid ? <div className="thanks__order__payment-state">
                        <div className="thanks__order__payment-state__text">{__('Vaša objednávka čaká na spracovanie. O odoslaní budete informovaní e-mailem. Platbu dobierky vykonajte pri prevzatí balíka.')}</div>
                    </div> : null}
                    {isPaid ? <div className="thanks__order__payment-state success">
                        <div className="thanks__order__payment-state__icon">
                            <CheckIcon />
                        </div>
                        <div className="thanks__order__payment-state__text">{__('Vaša objednávka je zaplatená.')}</div>
                    </div> : null}
                    {!isPaid && !showTransfer && !isCod ? <div className="thanks__order__payment-state error">
                        <div className="thanks__order__payment-state__icon">
                            <ErrorIcon />
                        </div>
                        <div className="thanks__order__payment-state__text">{__('Pozor, platba sa nepodarila.')}</div>
                        <div className="thanks__order__payment-state__button">
                            <ButtonBase
                                onClick={() => {}}
                            >{__('Zaplatiť znova')}</ButtonBase>
                            <ButtonBase
                                onClick={() => this.showLightbox('delivery', {
                                    country: data.order.delivery_address.country,
                                    order_id: params.id,
                                    order_token: params.token,
                                    payment_id: data.order.payment_id,
                                })}
                            >{__('Zaplatiť iným spôsobom')}</ButtonBase>
                        </div>
                    </div> : null}
                    {showTransfer ? <div className="thanks__order__payment-state waiting">
                        <div className="thanks__order__payment-state__icon">
                            <InfoIcon />
                        </div>
                        <div className="thanks__order__payment-state__text">{__('Vaša objednávka čaká na úhradu')}</div>
                    </div> : null}
                    {showTransfer ? <div className="thanks__order__transfer">
                        <div className="thanks__order__transfer__qrcode">
                            <div className="thanks__order__transfer__qrcode__title">{__('Zaplaťte QR kódom')}</div>
                            <img
                                src={`https://api.qrfgen.cz/generator/image?ID=${data.order.number}&DD=${formatDate(data.order.date, 'yyyymmdd')}&AM=${data.order.amount}&VS=${data.order.number}&ACC=${eshop.settings.iban}`}
                                alt="qrplatba"
                            />
                        </div>
                        <div className="thanks__order__transfer__info">
                            <div className="thanks__order__transfer__info__title">{__('Zaplaťte na náš bankový účet v EUR')}</div>
                            <div className="thanks__order__transfer__info__item">
                                <div className="thanks__order__transfer__info__item__name">{__('Číslo účtu')}:</div>
                                <div className="thanks__order__transfer__info__item__value">
                                    <span>{eshop.settings.account}</span>
                                    <CopyIcon onClick={() => this.copy(eshop.settings.account)} />
                                </div>
                            </div>
                            <div className="thanks__order__transfer__info__item">
                                <div className="thanks__order__transfer__info__item__name">{__('Var. symbol')}:</div>
                                <div className="thanks__order__transfer__info__item__value">
                                    <span>{data.order.number}</span>
                                    <CopyIcon onClick={() => this.copy(data.order.number)} />
                                </div>
                            </div>
                            <div className="thanks__order__transfer__info__item">
                                <div className="thanks__order__transfer__info__item__name">{__('Suma')}:</div>
                                <div className="thanks__order__transfer__info__item__value">
                                    <span>{formatAmount(data.order.amount)}</span>
                                    <CopyIcon onClick={() => this.copy(formatAmount(data.order.amount))} />
                                </div>
                            </div>
                            <div className="thanks__order__transfer__info__item">
                                <div className="thanks__order__transfer__info__item__name">{__('IBAN')}:</div>
                                <div className="thanks__order__transfer__info__item__value">
                                    <span>{eshop.settings.iban}</span>
                                    <CopyIcon onClick={() => this.copy(eshop.settings.iban)} />
                                </div>
                            </div>
                            <div className="thanks__order__transfer__info__item">
                                <div className="thanks__order__transfer__info__item__name">{__('BIC')}:</div>
                                <div className="thanks__order__transfer__info__item__value">
                                    <span>{eshop.settings.bic}</span>
                                    <CopyIcon onClick={() => this.copy(eshop.settings.bic)} />
                                </div>
                            </div>
                        </div>
                    </div> : null}
                    {global.token === '' ? <div className="thanks__order__account">
                        <div className="thanks__order__account__title">{__('Vytvorte si účet a získajte:')}</div>
                        <div className="thanks__order__account__panels">
                            <div className="thanks__order__account__panels__panel">
                                <div className="thanks__order__account__panels__panel__item">
                                    <CheckIcon />
                                    <span>{__('Rýchlejší nákup')}</span>
                                </div>
                                <div className="thanks__order__account__panels__panel__item">
                                    <CheckIcon />
                                    <span>{__('Detailné informácie o vašich objednávkach')}</span>
                                </div>
                                <div className="thanks__order__account__panels__panel__item">
                                    <CheckIcon />
                                    <span>{__('Recenzie produktov')}</span>
                                </div>
                            </div>
                            <div className="thanks__order__account__panels__panel">
                                <Input
                                    label={__('Heslo pre váš účet')}
                                    value={password}
                                    onChange={password => this.onChangePassword(password)}
                                    type="password"
                                />
                                <div className="thanks__order__account__panels__panel__register-text"
                                     dangerouslySetInnerHTML={{ __html: __('Vytvorením účtu súhlasíte s <a href="{LINK1}">obchodnými podmienkami</a> a <a href="{LINK2}">spracovaním osobných údajov</a>').replace('{LINK1}', '/').replace('{LINK2}', '/') }}
                                />
                                <div className="thanks__order__account__panels__panel__buttons">
                                    <ButtonBase
                                        onClick={() => this.createAccount()}
                                        className="thanks__order__account__panels__panel__buttons__button"
                                    >{loading ? this.renderButtonLoading() : __('Vytvoriť účet')}</ButtonBase>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                    <div className="thanks__order__data">
                        <h2 className="thanks__order__data__title">{__('Detaily objednávky')}</h2>
                        <div className="thanks__order__data__info">
                            <div className="thanks__order__data__info__panel">
                                <div className="thanks__order__data__info__panel__item">
                                    <div className="thanks__order__data__info__panel__item__name">{__('Číslo objednávky')}:</div>
                                    <div className="thanks__order__data__info__panel__item__value">{data.order.number}</div>
                                </div>
                                <div className="thanks__order__data__info__panel__item">
                                    <div className="thanks__order__data__info__panel__item__name">{__('Dátum objednávky')}:</div>
                                    <div className="thanks__order__data__info__panel__item__value">{data.order.date !== '' ? formatDate(data.order.date) : ''}</div>
                                </div>
                                <div className="thanks__order__data__info__panel__item">
                                    <div className="thanks__order__data__info__panel__item__name">{__('Email')}:</div>
                                    <div className="thanks__order__data__info__panel__item__value">{data.order.address.email}</div>
                                </div>
                                <div className="thanks__order__data__info__panel__item">
                                    <div className="thanks__order__data__info__panel__item__name">{__('Telefón')}:</div>
                                    <div className="thanks__order__data__info__panel__item__value">{formatPhone(data.order.address.phone)}</div>
                                </div>
                                <div className="thanks__order__data__info__panel__item">
                                    <div className="thanks__order__data__info__panel__item__name">{__('Doprava')}:</div>
                                    <div className="thanks__order__data__info__panel__item__value">{_.has(data.deliveries, data.order.delivery_id)
                                        ? getText(data.deliveries[data.order.delivery_id])
                                        : ''}</div>
                                </div>
                                <div className="thanks__order__data__info__panel__item">
                                    <div className="thanks__order__data__info__panel__item__name">{__('Platba')}:</div>
                                    <div className="thanks__order__data__info__panel__item__value">{_.has(data.payments, data.order.payment_id)
                                        ? getText(data.payments[data.order.payment_id])
                                        : ''}</div>
                                </div>
                            </div>
                            <div className="thanks__order__data__info__panel">
                                <div className="thanks__order__data__info__panel__address">
                                    <div className="thanks__order__data__info__panel__address__title">{__('Fakturačná adresa')}</div>
                                    <div className="thanks__order__data__info__panel__address__line">
                                        {data.order.address.company_name !== '' ? data.order.address.company_name : data.order.address.name}<br />
                                        {data.order.address.address}<br />
                                        {data.order.address.zip} {data.order.address.city}<br />
                                        {eshop.countries[data.order.address.country]}
                                    </div>
                                </div>
                            </div>
                            <div className="thanks__order__data__info__panel">
                                <div className="thanks__order__data__info__panel__address">
                                    <div className="thanks__order__data__info__panel__address__title">{__('Dodacia adresa')}</div>
                                    <div className="thanks__order__data__info__panel__address__line">
                                        {data.order.delivery_address.company_name !== '' ? data.order.delivery_address.company_name : data.order.delivery_address.name}<br />
                                        {data.order.delivery_address.address}<br />
                                        {data.order.delivery_address.zip} {data.order.delivery_address.city}<br />
                                        {eshop.countries[data.order.delivery_address.country]}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="thanks__order__data__down">
                            <div className="thanks__order__data__down__products">
                                {_.map(data.order.products, ({ id, count, price, settings }) => {
                                    if (!_.has(eshop.products, id)) {
                                        return null;
                                    }

                                    // Vytiahneme produkt
                                    const product = eshop.products[id];
                                    const name = getText(product.name);

                                    // Ma extensions?
                                    const hasExtensions = !_.isEmpty(settings.extensions);

                                    let variant = null;
                                    let variantStockStatus = null;
                                    let variantData = null;

                                    if (_.has(settings, 'variant') && settings.variant > 0 && _.has(eshop.variants, settings.variant)) {
                                        variant = eshop.variants[settings.variant];

                                        // Najdeme variant
                                        const productVariant = _.find(product.variants, ({ id }) => id === settings.variant);

                                        if (!_.isUndefined(productVariant)) {
                                            variantData = productVariant;
                                            variantStockStatus = getProductStockStatus(productVariant.stock, productVariant.storage, productVariant.minus);
                                        }
                                    }

                                    totalProducts += price * count;

                                    return (
                                        <div className="thanks__order__data__down__products__product" key={id}>
                                            <div className={`thanks__order__data__down__products__product__up ${hasExtensions ? 'with-extensions' : ''}`}>
                                                <div className="thanks__order__data__down__products__product__up__left">
                                                    <div className="thanks__order__data__down__products__product__up__left__photo">
                                                        {product.image !== '' ? <img src={getImageUrl(product.image)} alt={getText(product.name)} /> : null}
                                                    </div>
                                                </div>
                                                <div className="thanks__order__data__down__products__product__up__info">
                                                    <div className="thanks__order__data__down__products__product__up__info__name">
                                                        {`${name}${variant !== null ? ` - ${getText(variant.name)}` : ''}`}
                                                    </div>
                                                </div>
                                                <div className={`thanks__order__data__down__products__product__up__stock ${variantStockStatus}`}>{variantStockStatus !== null && variantData !== null
                                                    ? getProductStockTitle(variantStockStatus, variantData.minus, variantData.minus_text)
                                                    : null}</div>
                                                <div className="thanks__order__data__down__products__product__up__count">{count}x</div>
                                                <div className="thanks__order__data__down__products__product__up__unitprice">{formatAmount(price)}</div>
                                                <div className="thanks__order__data__down__products__product__up__price">{formatAmount(price * count)}</div>
                                            </div>
                                            {hasExtensions ? <div className="thanks__order__data__down__products__product__extensions">
                                                {_.map(settings.extensions, id => {
                                                    if (!_.has(eshop.extensions, id)) {
                                                        return null;
                                                    }

                                                    let title = '';

                                                    return (
                                                        <div className="thanks__order__data__down__products__product__extensions__extension" key={id}>
                                                            <div className="thanks__order__data__down__products__product__extensions__extension__name">
                                                                {getText(eshop.extensions[id].name)}
                                                                {title !== ''
                                                                    ? <Tooltip title={title}><ExtensionsInfoIcon /></Tooltip>
                                                                    : null}
                                                            </div>
                                                            <div className="thanks__order__data__down__products__product__extensions__extension__price">
                                                                {formatAmount(eshop.extensions[id].price)}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div> : null}
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="thanks__order__data__down__summary">
                                <div className="thanks__order__data__down__summary__amounts">
                                    <div className="thanks__order__data__down__summary__amounts__item">
                                        <div className="thanks__order__data__down__summary__amounts__item__name">{__('Celkom za tovar')}</div>
                                        <div className="thanks__order__data__down__summary__amounts__item__value">
                                            {formatAmount(totalProducts)}
                                        </div>
                                    </div>
                                    {data.order.discount_amount > 0 ? <div className="thanks__order__data__down__summary__amounts__item">
                                        <div className="thanks__order__data__down__summary__amounts__item__name">
                                            {data.order.coupon === 'credit' ? __('Kredit') : `${__('Kupón')} (${data.order.coupon})`}
                                        </div>
                                        <div className="thanks__order__data__down__summary__amounts__item__value">
                                            -{formatAmount(data.order.discount_amount)}
                                        </div>
                                    </div> : null}
                                    {data.order.delivery_amount > 0 ? <div className="thanks__order__data__down__summary__amounts__item">
                                        <div className="thanks__order__data__down__summary__amounts__item__name">{__('Doprava')}</div>
                                        <div className="thanks__order__data__down__summary__amounts__item__value">
                                            {formatAmount(data.order.delivery_amount)}
                                        </div>
                                    </div> : null}
                                    {data.order.payment_amount > 0 ? <div className="thanks__order__data__down__summary__amounts__item">
                                        <div className="thanks__order__data__down__summary__amounts__item__name">{__('Poplatok za dobierku')}</div>
                                        <div className="thanks__order__data__down__summary__amounts__item__value">
                                            {formatAmount(data.order.payment_amount)}
                                        </div>
                                    </div> : null}
                                </div>
                                <div className="thanks__order__data__down__summary__amount">
                                    <div className="thanks__order__data__down__summary__amount__name">{__('Celkom s DPH')}</div>
                                    <div className="thanks__order__data__down__summary__amount__value">
                                        {formatAmount(data.order.amount)}
                                    </div>
                                </div>
                                <div className="thanks__order__data__down__summary__vat">
                                    <div className="thanks__order__data__down__summary__vat__item">
                                        <div className="thanks__order__data__down__summary__vat__item__name">{__('DPH')} {data.order.tax}%</div>
                                        <div className="thanks__order__data__down__summary__vat__item__value">
                                            {formatAmount(data.order.amount - toFixed(data.order.amount * (100 / (100 + toNumber(data.order.tax)))))}
                                        </div>
                                    </div>
                                    <div className="thanks__order__data__down__summary__vat__item">
                                        <div className="thanks__order__data__down__summary__vat__item__name">{__('Cena bez DPH')}</div>
                                        <div className="thanks__order__data__down__summary__vat__item__value">
                                            {formatAmount(data.order.amount * (100 / (100 + toNumber(data.order.tax))))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Newsletter />
                {this.renderLightbox('delivery', __('Zmena spôsobu platby'), !_.isEmpty(lightbox.delivery)
                    ? <ChangeDelivery
                        eshop={eshop}
                        country={lightbox.delivery.country}
                        orderId={lightbox.delivery.order_id}
                        orderToken={lightbox.delivery.order_token}
                        paymentId={lightbox.delivery.payment_id}
                        callback={(status, data) => this.changePayment(status, data)}
                    />
                    : null, '', __('Zrušiť'))}
                {this.renderSnackbar()}
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        return super.render();
    }
}

const stateToProps = ({ eshop, settings }) => ({ eshop, settings });

export default withCookies(connect(stateToProps, { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists })(ThanksScreen));
