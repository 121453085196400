import _ from 'lodash';
import { toNumber } from './toNumber';
import { toFixed } from './toFixed';

/**
 * Zformatujeme sumu na x pocet desatinnych miest s ciarkou.
 *
 * @param {number} amount
 * @param {number} decimals
 * @param {string} separator
 * @param {boolean} currency
 *
 * @returns {string}
 */
export const formatAmount = (amount, decimals = 2, separator = ',', currency = true) => {
    // Upravime na nastavene desatinne miesta a vytiahneme regexom
    amount = /(-|)(.*)\.(.*)/.exec(toFixed(toNumber(amount), decimals));

    // Otocime sumu naopak, kazde 3 znaky pridame medzeru a otocime naspat to nam zabezpeci format 10 000 000...
    amount[2] = _.join(_.reverse(_.split(_.replace(_.join(_.reverse(_.split(amount[2], '')), ''), /(\d{3})(?=\d)/g, '$1 '), '')), '');

    return `${amount[1]}${amount[2]}${amount[3] === '' ? '' : separator}${amount[3]}${currency ? ` ${global.currency}` : ''}`;
};
