import _ from 'lodash';
import {
    FETCH_LISTS,
    CLEAN_LISTS,
    ADD_LISTS_PRODUCT,
    DELETE_LISTS_PRODUCT,
    CHANGE_LISTS_PRODUCT_COUNT,
    ADD_LISTS_NAME,
    DELETE_LISTS_NAME,
    RENAME_LISTS_NAME,
} from './types';
import { COOKIE_LISTS_NAME, COOKIE_SETTINGS } from '../config';

/**
 * Nacitanie zoznamov.
 *
 * @param {Screen} screen
 */
export const fetchLists = (screen) => {
    let cart = screen.props.cookies.get(COOKIE_LISTS_NAME, COOKIE_SETTINGS);
    cart = !_.isUndefined(cart) ? cart : {};

    return dispatch => dispatch({ type: FETCH_LISTS, payload: cart });
};

/**
 * Vycistenie kosika.
 */
export const cleanLists = () => dispatch => dispatch({ type: CLEAN_LISTS });

/**
 * Pridame produkt do oblubenych.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {number} listId
 * @param {number} count
 * @param {Object} settings
 */
export const addListsProduct = (screen, id, listId, count, settings = {}) => {
    global.screen = screen;

    return dispatch => dispatch({ type: ADD_LISTS_PRODUCT, payload: { id, listId, count, settings } });
};

/**
 * Odstranime produkt z oblubenych.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteListsProduct = (screen, id) => {
    global.screen = screen;

    return dispatch => dispatch({ type: DELETE_LISTS_PRODUCT, payload: id });
};

/**
 * Upravime pocet produktu v oblubenych.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {number} count
 */
export const changeListsProductCount = (screen, id, count) => {
    global.screen = screen;

    return dispatch => dispatch({ type: CHANGE_LISTS_PRODUCT_COUNT, payload: { id, count } });
};

/**
 * Pridame zoznam.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {string} name
 */
export const addListsName = (screen, id, name) => {
    global.screen = screen;

    return dispatch => dispatch({ type: ADD_LISTS_NAME, payload: { id, name } });
};

/**
 * Odstranime zoznam.
 *
 * @param {Screen} screen
 * @param {number} id
 */
export const deleteListsName = (screen, id) => {
    global.screen = screen;

    return dispatch => dispatch({ type: DELETE_LISTS_NAME, payload: id });
};

/**
 * Premenujeme zoznam.
 *
 * @param {Screen} screen
 * @param {number} id
 * @param {string} name
 */
export const renameListsName = (screen, id, name) => {
    global.screen = screen;

    return dispatch => dispatch({ type: RENAME_LISTS_NAME, payload: { id, name } });
};
