import { getAssets } from './functions/getAssets';
import { __ } from './functions';

/**
 * Zoznam obrazkov.
 *
 * @var object
 */
export const IMAGES = getAssets(require.context('./assets/img/', false, /\.(png|jpe?g|svg|gif)$/));

/**
 * Nazov token cookie.
 *
 * @type {string}
 */
export const COOKIE_TOKEN_NAME = 'prime-new-token';

/**
 * Nazov cart cookie.
 *
 * @type {string}
 */
export const COOKIE_CART_NAME = 'prime-new-cart-v1';

/**
 * Nazov oblubene cookie.
 *
 * @type {string}
 */
export const COOKIE_FAVORITES_NAME = 'prime-new-favorites-v1';

/**
 * Nazov zoznamy cookie.
 *
 * @type {string}
 */
export const COOKIE_LISTS_NAME = 'prime-new-lists-v2';

/**
 * Nazov porovnania cookie.
 *
 * @type {string}
 */
export const COOKIE_COMPARES_NAME = 'prime-new-compares-v1';

/**
 * Nazov settings cookie.
 *
 * @type {string}
 */
export const COOKIE_SETTINGS_NAME = 'prime-new-settings-v3';

/**
 * Nastavenia cookie.
 *
 * @type {Object}
 */
export const COOKIE_SETTINGS = process.env.NODE_ENV && process.env.NODE_ENV === 'production'
    ? { path: '/', domain: window.location.host.replace('www.', '') }
    : { path: '/' };

/**
 * Development url.
 *
 * @type {string}
 */
export const DEVELOPMENT_URL = 'http://prime.loc:80';

/**
 * Production url.
 *
 * @type {string}
 */
export const PRODUCTION_URL = 'https://prime-api.goodeshop.sk';
