import React from 'react';
import ReactDOM from 'react-dom/client';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import ReduxThunk from 'redux-thunk';
import { CookiesProvider } from 'react-cookie';
import { reducers } from './reducers';
import App from './App';
import reportWebVitals from './reportWebVitals';

const store = createStore(combineReducers(reducers), {}, applyMiddleware(ReduxThunk));

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

if (rootElement.hasChildNodes()) {
    root.hydrate(
        <React.StrictMode>
            <CookiesProvider>
                <App store={store} />
            </CookiesProvider>
        </React.StrictMode>
    );
} else {
    root.render(
        <React.StrictMode>
            <CookiesProvider>
                <App store={store} />
            </CookiesProvider>
        </React.StrictMode>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
