import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { ButtonBase } from '@mui/material';
import { Screen, Input } from '../components';
import { __, getUrlPath, isValidEmail, request } from '../functions';
import { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists } from '../actions';
import { IMAGES } from '../config';
import '../assets/styles/lost-password.css';

/**
 * Stratene heslo.
 */
class LostPasswordScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Obnova hesla');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        loading: false,
        email: '',
        password: '',
        passwordRepeat: '',
    };

    /**
     * Komponenta bola pripojena.
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            if (global.token !== '') {
                window.location = getUrlPath('/obchod');
            }
        }
    }

    /**
     * Event po zmene emailu.
     *
     * @param {string} email
     */
    onChangeEmail(email) {
        this.setState({ email });
    }

    /**
     * Event po zmene hesla.
     *
     * @param {string} password
     */
    onChangePassword(password) {
        this.setState({ password });
    }

    /**
     * Event po zmene hesla znovu.
     *
     * @param {string} passwordRepeat
     */
    onChangePasswordRepeat(passwordRepeat) {
        this.setState({ passwordRepeat });
    }

    /**
     * Stratene heslo.
     */
    lostPassword() {
        const { email } = this.state;

        if (!isValidEmail(email)) {
            this.showSnackbar('error', __('Neplatný email'));
            return;
        }

        this.setState({ loading: true });

        request(`/users/lost-password/${email}`).then(response => {
            this.setState({ loading: false, email: '' });
            this.showSnackbar('success', __('Email bol odoslaný'));
        });
    }

    /**
     * Zmena hesla.
     */
    changePassword() {
        const { params } = this.props;
        const { password, passwordRepeat } = this.state;

        let error = null;

        if (password.length < 8) {
            // Kratke heslo
            error = __('Heslo musí mať minimálne 8 znakov');
        } else if (password !== passwordRepeat) {
            // Hesla sa nerovnaju
            error = __('Hesla nie sú rovnaké');
        }

        if (error !== null) {
            // Mame error
            this.showSnackbar('error', error);
            return;
        }

        this.setState({ loading: true });

        global.token = params.token;

        request(`/users/change-password`, { password }, 'POST').then(response => {
            this.setState({ loading: false, password: '', passwordRepeat: '' });
            this.showSnackbar('success', __('Heslo bylo zmenené'));

            setTimeout(() => { window.location = getUrlPath('/moj-ucet'); }, 500);
        });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    renderScreen() {
        const { params } = this.props;
        const { loading, email, password, passwordRepeat } = this.state;

        return (
            <div className="lost-password">
                <div className="lost-password__panel">
                    {!_.has(params, 'token') ? <div className="lost-password__panel__up">
                        <h1 className="lost-password__panel__up__title">{__('Obnova hesla')}</h1>
                        <div className="lost-password__panel__up__text">{__('Prosím vyplňte svoj registračný email a my vám na neho pošleme link k obnove hesla')}.</div>
                        <Input
                            label={__('Prihlasovací email')}
                            value={email}
                            onChange={value => this.onChangeEmail(value)}
                            type="email"
                        />
                        <div className="lost-password__panel__up__buttons">
                            <ButtonBase
                                onClick={() => this.lostPassword()}
                                className="lost-password__panel__up__buttons__button"
                            >{loading ? this.renderButtonLoading() : __('Obnoviť heslo')}</ButtonBase>
                        </div>
                    </div> : <div className="lost-password__panel__up">
                        <h1 className="lost-password__panel__up__title">{__('Obnova hesla')}</h1>
                        <Input
                            label={__('Heslo')}
                            value={password}
                            onChange={value => this.onChangePassword(value)}
                            type="password"
                        />
                        <Input
                            label={__('Zopakovať heslo')}
                            value={passwordRepeat}
                            onChange={value => this.onChangePasswordRepeat(value)}
                            type="password"
                        />
                        <div className="lost-password__panel__up__buttons">
                            <ButtonBase
                                onClick={() => this.changePassword()}
                                className="lost-password__panel__up__buttons__button"
                            >{loading ? this.renderButtonLoading() : __('Zmeniť heslo')}</ButtonBase>
                        </div>
                    </div>}
                    <div className="lost-password__panel__down">
                        <ButtonBase onClick={() => this.redirect('/prihlasenie')}>{__('Späť k prihláseniu')}</ButtonBase>
                    </div>
                </div>
                <div className="lost-password__photo">
                    <img src={IMAGES['login.png']} alt="prihlaseni" />
                </div>
                {this.renderSnackbar()}
            </div>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        return super.render();
    }
}

const stateToProps = ({ eshop, settings }) => ({ eshop, settings });

export default withCookies(connect(stateToProps, { fetchEshop, fetchSettings, fetchCart, fetchFavorites, fetchCompares, fetchLists })(LostPasswordScreen));
