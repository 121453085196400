import _ from 'lodash';
import { toNumber } from './toNumber';

/**
 * Zaokruhlime cislo na x desatinnych miest.
 *
 * @param {string|number} number
 * @param {number} decimals
 * @returns {number}
 */
export const ceil = (number, decimals = 2) => toNumber(_.ceil(toNumber(number), decimals));
